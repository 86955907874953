import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./admin-config.css";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import InputContext from "../../../Components/InputContext";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faX } from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";
const Api_Host = process.env.REACT_APP_HOST_API_URL;

const AdminConfig = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const { showToast } = Toast();
  const [adminConfigData, setAdminConfgigData] = useState({
    SHIPROCKET_API_USER_EMAIL: "",
    SHIPROCKET_CHANNEL_ID: "",
    SHIPROCKET_API_KEY: "",
    RAZORPAY_KEY: "",
    RAZORPAY_WEBHOOK_SECRET: "",
    MSG91_WIDGET_ID: "",
    MSG91_AUTH_TOKEN: "",
    MSG91_AUTH_KEY: "",
    MSG91_SEND_OTP_TEMPLATE_ID: "",
    OTP_LENGTH: "",
    OTP_EXPIRATION: "",
    MAIL_MAILER: "",
    MAIL_HOST: "",
    MAIL_PORT: "",
    MAIL_USERNAME: "",
    MAIL_PASSWORD: "",
    MAIL_ENCRYPTION: "",
    MAIL_FROM_ADDRESS: "",
    MAIL_FROM_NAME: "",
    APP_URL: "",
    BUYER_APP_URL: "",
    SELLER_APP_URL: "",
    ADMIN_APP_URL: "",
    ALLOWED_DOMAINS: "",
  });
  const [isShiprocketConfiguration, setisShiprocketConfiguration] =
    useState(false);
  const [israzorpayConfigurationLoading, setisrazorpayConfigurationLoading] =
    useState(false);
  const [isMS91ConfigurationLoading, setisMS91ConfigurationLoading] =
    useState(false);
  const [shiprocketConfiguration, showShiprocketConfiguration] =
    useState(false);
  const [isSmptConfigurationLoading, setisSmptConfigurationLoading] =
    useState(false);
  const [
    isapplicationUrlConfigurationLoading,
    setisapplicationUrlConfigurationLoading,
  ] = useState(false);
  const [
    isallowDomainsConfigurationLoading,
    setisallowDomainsConfigurationLoading,
  ] = useState(false);
  const [isshowShiprocketpasswordLoading, setisshowShiprocketpasswordLoading] =
    useState(false);
  const [isshowRazorpaypasswordLoading, setisshowRazorpaypasswordLoading] =
    useState(false);
  const [isshowSmtpMailPasswordLoading, setisshowSmtpMailPasswordLoading] =
    useState(false);
  const [razorpayConfiguration, setRazorpayConfiguration] = useState(false);
  const [MS91Configuration, setMS91Configuration] = useState(false);
  const [SmptConfiguration, setSmptConfiguration] = useState(false);
  const [applicationUrlConfiguration, setApplicationUrlConfiguration] =
    useState(false);
  const [allowDomainsConfiguration, setAllowDomainsConfiguration] =
    useState(false);
  const [mailmaillererror, setmailmaillererror] = useState("");
  const [mailhosterror, setmailhosterror] = useState("");
  const [mailporterror, setmailporterror] = useState("");
  const [mailusernameerror, setmailusernameerror] = useState("");
  const [mailpassworderror, setmailpassworderror] = useState("");
  const [mailencryptionerror, setmailencryptionerror] = useState("");
  const [mailfromaddresserror, setmailfromaddresserror] = useState("");
  const [mailfromnameerror, setmailfromnameerror] = useState("");

  const [shiprocketemailerror, setshiprocketemailerror] = useState("");
  const [shiprocketpassworderror, setshiprocketpassworderror] = useState("");
  const [shiprocketCancelediderror, setshiprocketCancelediderror] =
    useState("");
  const [shipRocketApiKeyError, setshipRocketApiKeyError] = useState("");
  const [showShiprocketpassword, setshowShiprocketpassword] = useState(false);
  const [showRazorpaypassword, setShowRazorpaypassword] = useState(false);
  const [showSmtpMailPassword, setShowSmtpMailPassword] = useState(false);
  const [razorpaykeyError, setrazorpaykeyError] = useState("");
  const [razorpay_webhook_secret_error, set_razorpay_webhook_secret_error] =
    useState("");
  const [MSG91WiggetIderror, setMSG91WiggetIderror] = useState("");
  const [MSG91AuthTokenError, setMSG91AuthTokenError] = useState("");
  const [MSG91AuthKeyError, setMSG91AuthKeyError] = useState("");
  const [RegisterOTPTemplateIdError, setRegisterOTPTemplateIdError] =
    useState("");

  const [OTPLengthError, setOTPLengthError] = useState("");
  const [OTPExpirationError, setOTPExpirationError] = useState("");

  const [BackendAppUrlError, setBackendAppUrlError] = useState("");
  const [BuyerAppUrlError, setBuyerAppUrlError] = useState("");
  const [SellerAppUrlError, setSellerAppUrlError] = useState("");
  const [AdminAppUrlError, setAdminAppUrlError] = useState("");

  const [allowDomainsError, setAllowDomainsError] = useState("");
  const [shiprocketApiUserPasswordError, setShiprocketApiUserPasswordError] =
    useState("");
  const [razorpayPasswordError, setRazorpayPasswordError] = useState("");
  const [smtpPasswordError, setSmtpPasswordError] = useState("");
  const [shiprocketConfigurationData, setShiprocketConfigurationData] =
    useState({
      shiprocket_api_user_email: "",
      shiprocket_api_user_password: "",
      shiprocket_channel_id: "",
      shiprocket_api_key: "",
    });
  const [razorpayConfigurationData, setRazorpayConfigurationData] = useState({
    razorpay_key: "",
    razorpay_secret: "",
    razorpay_webhook_secret: "",
  });
  const [MS91ConfigurationData, setMS91ConfigurationData] = useState({
    msg91_widget_id: "",
    msg91_auth_token: "",
    msg91_auth_key: "",
    msg91_otp_template_id: "",
    otp_expiration: "",
    otp_length: "",
  });
  const [SmptConfigurationData, setSmptConfigurationData] = useState({
    mail_mailer: "",
    mail_host: "",
    mail_port: "",
    mail_username: "",
    mail_password: "",
    mail_encryption: "",
    mail_from_address: "",
    mail_from_name: "",
  });
  const [applicationUrlConfigurationData, setApplicationUrlConfigurationData] =
    useState({
      backend_app_url: "",
      buyer_app_url: "",
      seller_app_url: "",
      admin_app_url: "",
    });
  const [AllowDomainsConfigurationData, setAllowDomainsConfigurationData] =
    useState({
      allow_domains: "",
    });
  const [shiprocketPasswordConfiguration, setShiprocketPasswordConfiguration] =
    useState({
      shiprocket_api_user_password: "",
    });
  const [razorpaysecretvalueData, setRazorpaysecretvalueData] = useState({
    razorpay_secret: "",
  });
  const [smtpMailPasswordValueData, setSmtpMailPasswordValueData] = useState({
    smtp_mail_password: "",
  });
  const GetAdminConfigurationData = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    fetch(`${Api_Host}/${APIEndpoint.getAdminEnv}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.data) {
          const data = res.data;
          setAdminConfgigData((prev) => ({
            ...prev,
            SHIPROCKET_API_USER_EMAIL: data.SHIPROCKET_API_USER_EMAIL,
            SHIPROCKET_CHANNEL_ID: data.SHIPROCKET_CHANNEL_ID,
            SHIPROCKET_API_KEY: data.SHIPROCKET_API_KEY,
            SELLER_APP_URL: data.SELLER_APP_URL,
            RAZORPAY_KEY: data.RAZORPAY_KEY,
            RAZORPAY_WEBHOOK_SECRET: data.RAZORPAY_WEBHOOK_SECRET,
            MSG91_WIDGET_ID: data.MSG91_WIDGET_ID,
            MSG91_AUTH_TOKEN: data.MSG91_AUTH_TOKEN,
            MSG91_AUTH_KEY: data.MSG91_AUTH_KEY,
            MSG91_SEND_OTP_TEMPLATE_ID: data.MSG91_SEND_OTP_TEMPLATE_ID,
            OTP_LENGTH: data.OTP_LENGTH,
            OTP_EXPIRATION: data.OTP_EXPIRATION,
            MAIL_MAILER: data.MAIL_MAILER,
            MAIL_HOST: data.MAIL_HOST,
            MAIL_PORT: data.MAIL_PORT,
            MAIL_USERNAME: data.MAIL_USERNAME,
            MAIL_PASSWORD: data.MAIL_PASSWORD,
            MAIL_ENCRYPTION: data.MAIL_ENCRYPTION,
            MAIL_FROM_ADDRESS: data.MAIL_FROM_ADDRESS,
            MAIL_FROM_NAME: data.MAIL_FROM_NAME,
            APP_URL: data.APP_URL,
            BUYER_APP_URL: data.BUYER_APP_URL,
            SELLER_APP_URL: data.SELLER_APP_URL,
            ADMIN_APP_URL: data.ADMIN_APP_URL,
            ALLOWED_DOMAINS: data.ALLOWED_DOMAINS,
          }));
        } else if (res.status === 404) {
          console.error("bad Request", res.status);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetAdminConfigurationData();
  }, []);
  const handleShiprocketEditButton = () => {
    setShiprocketConfigurationData((prevData) => ({
      ...prevData,
      shiprocket_api_user_email: adminConfigData.SHIPROCKET_API_USER_EMAIL,
      shiprocket_channel_id: adminConfigData.SHIPROCKET_CHANNEL_ID,
      shiprocket_api_key: adminConfigData.SHIPROCKET_API_KEY,
    }));
    showShiprocketConfiguration(true);
  };
  const handleRazorpayEditButton = () => {
    setRazorpayConfigurationData((prevData) => ({
      ...prevData,
      razorpay_key: adminConfigData.RAZORPAY_KEY,
      razorpay_webhook_secret: adminConfigData.RAZORPAY_WEBHOOK_SECRET,
    }));
    setRazorpayConfiguration(true);
  };
  const handleMS91EditButton = () => {
    setMS91ConfigurationData((prevData) => ({
      ...prevData,
      msg91_widget_id: adminConfigData.MSG91_WIDGET_ID,
      msg91_auth_token: adminConfigData.MSG91_AUTH_TOKEN,
      msg91_auth_key: adminConfigData.MSG91_AUTH_KEY,
      msg91_otp_template_id: adminConfigData.MSG91_SEND_OTP_TEMPLATE_ID,
      otp_expiration: adminConfigData.OTP_EXPIRATION,
      otp_length: adminConfigData.OTP_LENGTH,
    }));
    setMS91Configuration(true);
  };
  const handleSmptEditButton = () => {
    setSmptConfigurationData((prevData) => ({
      ...prevData,
      mail_mailer: adminConfigData.MAIL_MAILER,
      mail_host: adminConfigData.MAIL_HOST,
      mail_port: adminConfigData.MAIL_PORT,
      mail_username: adminConfigData.MAIL_USERNAME,
      mail_password: adminConfigData.MAIL_PASSWORD,
      mail_encryption: adminConfigData.MAIL_ENCRYPTION,
      mail_from_address: adminConfigData.MAIL_FROM_ADDRESS,
      mail_from_name: adminConfigData.MAIL_FROM_NAME,
    }));
    setSmptConfiguration(true);
  };
  const handleApplicationUrlEditButton = () => {
    setApplicationUrlConfigurationData((prevData) => ({
      ...prevData,
      backend_app_url: adminConfigData.APP_URL,
      buyer_app_url: adminConfigData.BUYER_APP_URL,
      seller_app_url: adminConfigData.SELLER_APP_URL,
      admin_app_url: adminConfigData.ADMIN_APP_URL,
    }));
    setApplicationUrlConfiguration(true);
  };
  const handleDomainsEditButton = () => {
    setAllowDomainsConfigurationData((prevData) => ({
      ...prevData,
      allow_domains: adminConfigData.ALLOWED_DOMAINS,
    }));
    setAllowDomainsConfiguration(true);
  };
  const handleShiprocketChange = (event) => {
    let { name, value } = event.target;
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setShiprocketConfigurationData((prevData) => ({
        ...prevData,
        [name]: value.trim(),
      }));
    }
    setshiprocketemailerror("");
    setshiprocketpassworderror("");
    setshiprocketCancelediderror("");
    setshipRocketApiKeyError("");
  };

  const UpdateShiprocketConfiguration = async () => {
    setisShiprocketConfiguration(true);
    let errorMessageShow = false;
    // Validation for shiprocket email
    if (
      shiprocketConfigurationData.shiprocket_api_user_email === "" ||
      shiprocketConfigurationData.shiprocket_api_user_email === null
    ) {
      setshiprocketemailerror("Shiprocket email is  required!");
      errorMessageShow = true;
    } else {
      setshiprocketemailerror("");
    }
    // Validation for shiprocket Canceledid
    if (
      shiprocketConfigurationData.shiprocket_channel_id === "" ||
      shiprocketConfigurationData.shiprocket_channel_id === null
    ) {
      setshiprocketCancelediderror("Shiprocket channel Id is  required!");
      errorMessageShow = true;
    } else {
      setshiprocketCancelediderror("");
    }
    // Validation for shiprocket API Key
    if (
      shiprocketConfigurationData.shiprocket_api_key === "" ||
      shiprocketConfigurationData.shiprocket_api_key === null
    ) {
      setshipRocketApiKeyError("Shiprocket API Key is  required!");
      errorMessageShow = true;
    } else {
      setshipRocketApiKeyError("");
    }

    if (errorMessageShow) {
      setisShiprocketConfiguration(false);
      return;
    }
    const Payload = {};
    Payload.shiprocket_config = "1";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.shiprocket_api_user_email =
      shiprocketConfigurationData.shiprocket_api_user_email;
    Payload.shiprocket_channel_id =
      shiprocketConfigurationData.shiprocket_channel_id;
    Payload.shiprocket_api_key = shiprocketConfigurationData.shiprocket_api_key;

    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisShiprocketConfiguration(false);
        if (data.status === 200) {
          showToast("success", "Shiprocket Details Update successfully.");
          showShiprocketConfiguration(false);
          setshiprocketemailerror("");
          setshiprocketpassworderror("");
          setshiprocketCancelediderror("");
          setshipRocketApiKeyError("");
          GetAdminConfigurationData();
        } else if (data.status === 400) {
          if (data.errors.shiprocket_api_user_email) {
            setshiprocketemailerror(data.errors.shiprocket_api_user_email[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        }
      })
      .catch((err) => console.error(err));
  };
  const handleRazorpayChange = (event) => {
    let { name, value } = event.target;
    setrazorpaykeyError("");
    set_razorpay_webhook_secret_error("");
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setRazorpayConfigurationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const UpdateRazorpayConfiguration = async () => {
    setisrazorpayConfigurationLoading(true);
    let errorMessageShow = false;
    // Validation for shiprocket email
    if (
      razorpayConfigurationData.razorpay_key === "" ||
      razorpayConfigurationData.razorpay_key === null
    ) {
      setrazorpaykeyError("Razorpay key is  required!");
      errorMessageShow = true;
    } else {
      setrazorpaykeyError("");
    }
    // Validation for shiprocket password
    if (
      razorpayConfigurationData.razorpay_webhook_secret === "" ||
      razorpayConfigurationData.razorpay_webhook_secret === null
    ) {
      set_razorpay_webhook_secret_error(
        "Razorpay webhook secret is  required!"
      );
      errorMessageShow = true;
    } else {
      set_razorpay_webhook_secret_error("");
    }
    if (errorMessageShow == true) {
      setisrazorpayConfigurationLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "1";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.razorpay_key = razorpayConfigurationData.razorpay_key;
    Payload.razorpay_webhook_secret =
      razorpayConfigurationData.razorpay_webhook_secret;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisrazorpayConfigurationLoading(false);
        if (data.status === 200) {
          showToast("success", "Razorpay Details Update successfully.");
          setRazorpayConfiguration(false);
          setrazorpaykeyError("");
          set_razorpay_webhook_secret_error("");
          GetAdminConfigurationData();
        }
        if (data.status === 401 && data.message === "You are not authorized.") {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        }
      })
      .catch((err) => console.error(err));
  };

  const handleMS91change = (event) => {
    let { name, value } = event.target;

    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setMS91ConfigurationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setMSG91WiggetIderror("");
    setMSG91AuthTokenError("");
    setMSG91AuthKeyError("");
    setRegisterOTPTemplateIdError("");
    setOTPLengthError("");
    setOTPExpirationError("");
  };

  const UpdateMS91Configuration = async () => {
    setisMS91ConfigurationLoading(true);
    let errorMessageShow = false;
    //  Validation for MSG91 WIDGET ID
    if (
      MS91ConfigurationData.msg91_widget_id === "" ||
      MS91ConfigurationData.msg91_widget_id === null
    ) {
      setMSG91WiggetIderror("MSG91 Widget Id is  required!");
      errorMessageShow = true;
    } else {
      setMSG91WiggetIderror("");
    }

    // Validation for MSG91 WIDGET ID
    if (
      MS91ConfigurationData.msg91_auth_token === "" ||
      MS91ConfigurationData.msg91_auth_token === null
    ) {
      setMSG91AuthTokenError("MSG91 Auth Token is  required!");
      errorMessageShow = true;
    } else {
      setMSG91AuthTokenError("");
    }

    // Validation for MSG91 AUTH KEY
    if (
      MS91ConfigurationData.msg91_otp_template_id === "" ||
      MS91ConfigurationData.msg91_otp_template_id === null
    ) {
      setRegisterOTPTemplateIdError("MSG91 otp template id is  required!");
      errorMessageShow = true;
    } else {
      setRegisterOTPTemplateIdError("");
    }

    // Validation for MSG91 AUTH KEY
    if (
      MS91ConfigurationData.msg91_auth_key === "" ||
      MS91ConfigurationData.msg91_auth_key === null
    ) {
      setMSG91AuthKeyError("MSG91 Auth Key is  required!");
      errorMessageShow = true;
    } else {
      setMSG91AuthKeyError("");
    }

    // Validation for OTP LENGTH
    if (
      MS91ConfigurationData.otp_length === "" ||
      MS91ConfigurationData.otp_length === null
    ) {
      setOTPLengthError("OTP length is  required!");
      errorMessageShow = true;
    } else {
      setOTPLengthError("");
    }

    // Validation for OTP Expiration
    if (
      MS91ConfigurationData.otp_expiration === "" ||
      MS91ConfigurationData.otp_expiration === null
    ) {
      setOTPExpirationError("OTP Expiration is  required!");
      errorMessageShow = true;
    } else {
      setOTPExpirationError("");
    }
    if (errorMessageShow) {
      setisMS91ConfigurationLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "1";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.msg91_widget_id = MS91ConfigurationData.msg91_widget_id;
    Payload.msg91_auth_token = MS91ConfigurationData.msg91_auth_token;
    Payload.msg91_auth_key = MS91ConfigurationData.msg91_auth_key;
    if (
      MS91ConfigurationData.msg91_otp_template_id !== "" &&
      MS91ConfigurationData.msg91_otp_template_id != null
    ) {
      Payload.msg91_otp_template_id =
        MS91ConfigurationData.msg91_otp_template_id;
    }
    Payload.otp_expiration = MS91ConfigurationData.otp_expiration;
    Payload.otp_length = MS91ConfigurationData.otp_length;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        setisMS91ConfigurationLoading(false);
        if (data.status === 200) {
          setAdminConfgigData((prevData) => ({
            ...prevData,
            MSG91_WIDGET_ID: MS91ConfigurationData.msg91_widget_id,
            MSG91_AUTH_TOKEN: MS91ConfigurationData.msg91_auth_token,
            MSG91_AUTH_KEY: MS91ConfigurationData.msg91_auth_key,
            OTP_EXPIRATION: MS91ConfigurationData.otp_expiration,
            OTP_LENGTH: MS91ConfigurationData.otp_length,
          }));
          if (
            MS91ConfigurationData.msg91_otp_template_id !== "" &&
            MS91ConfigurationData.msg91_otp_template_id != null
          ) {
            Payload.msg91_otp_template_id =
              MS91ConfigurationData.msg91_otp_template_id;
            setAdminConfgigData((prevData) => ({
              ...prevData,
              MSG91_SEND_OTP_TEMPLATE_ID:
                MS91ConfigurationData.msg91_otp_template_id,
            }));
          }

          showToast("success", "MSG91 Details Update successfully.");
          setMS91Configuration(false);
          setMSG91WiggetIderror("");
          setMSG91AuthTokenError("");
          setMSG91AuthKeyError("");
          setRegisterOTPTemplateIdError("");
          setOTPLengthError("");
          setOTPExpirationError("");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleSmptChange = (event) => {
    let { name, value } = event.target;
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setSmptConfigurationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setmailmaillererror("");
    setmailporterror("");
    setmailhosterror("");
    setmailusernameerror("");
    setmailpassworderror("");
    setmailencryptionerror("");
    setmailfromaddresserror("");
    setmailfromnameerror("");
  };
  const UpdateSmptConfiguration = async () => {
    setisSmptConfigurationLoading(true);
    let errorMessageShow = false;
    // Validation for Mail Mailler
    if (
      SmptConfigurationData.mail_mailer === "" ||
      SmptConfigurationData.mail_mailer === null
    ) {
      setmailmaillererror("Mail Mailer is  required!");
      errorMessageShow = true;
    } else {
      setmailmaillererror("");
    }

    // Validation for Mail Host
    if (
      SmptConfigurationData.mail_host === "" ||
      SmptConfigurationData.mail_host === null
    ) {
      setmailhosterror("Mail Host is  required!");
      errorMessageShow = true;
    } else {
      setmailhosterror("");
    }

    // Validation for Mail Port
    if (
      SmptConfigurationData.mail_port === "" ||
      SmptConfigurationData.mail_port === null
    ) {
      setmailporterror("Mail Port is  required!");
      errorMessageShow = true;
    } else {
      setmailporterror("");
    }

    // Validation For Mail Username
    if (
      SmptConfigurationData.mail_username === "" ||
      SmptConfigurationData.mail_username === null
    ) {
      setmailusernameerror("Mail Username is  required!");
      errorMessageShow = true;
    } else {
      setmailusernameerror("");
    }

    // // Validation For Mail Password
    // if (
    //   SmptConfigurationData.mail_password === "" ||
    //   SmptConfigurationData.mail_password === null
    // ) {
    //   setmailpassworderror("Mail Password is  required!");
    //   errorMessageShow = true;
    // } else {
    //   setmailpassworderror("");
    // }

    // Validation For Mail Encryption
    if (
      SmptConfigurationData.mail_encryption === "" ||
      SmptConfigurationData.mail_encryption === null
    ) {
      setmailencryptionerror("Mail Encryption is  required!");
      errorMessageShow = true;
    } else {
      setmailencryptionerror("");
    }

    // Validation For Mail from address
    if (
      SmptConfigurationData.mail_from_address === "" ||
      SmptConfigurationData.mail_from_address === null
    ) {
      setmailfromaddresserror("Mail from address is  required!");
      errorMessageShow = true;
    } else {
      setmailfromaddresserror("");
    }

    // Validation For Mail from name
    if (
      SmptConfigurationData.mail_from_name === "" ||
      SmptConfigurationData.mail_from_name === null
    ) {
      setmailfromnameerror("Mail from name is  required!");
      errorMessageShow = true;
    } else {
      setmailfromnameerror("");
    }

    if (errorMessageShow) {
      setisSmptConfigurationLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "1";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.mail_mailer = SmptConfigurationData.mail_mailer;
    Payload.mail_host = SmptConfigurationData.mail_host;
    Payload.mail_port = SmptConfigurationData.mail_port;
    Payload.mail_username = SmptConfigurationData.mail_username;
    Payload.mail_encryption = SmptConfigurationData.mail_encryption;
    Payload.mail_from_address = SmptConfigurationData.mail_from_address;
    Payload.mail_from_name = SmptConfigurationData.mail_from_name;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisSmptConfigurationLoading(false);
        if (data.status === 200) {
          setAdminConfgigData((prevData) => ({
            ...prevData,
            MAIL_MAILER: SmptConfigurationData.mail_mailer,
            MAIL_HOST: SmptConfigurationData.mail_host,
            MAIL_PORT: SmptConfigurationData.mail_port,
            MAIL_USERNAME: SmptConfigurationData.mail_username,
            MAIL_ENCRYPTION: SmptConfigurationData.mail_encryption,
            MAIL_FROM_ADDRESS: SmptConfigurationData.mail_from_address,
            MAIL_FROM_NAME: SmptConfigurationData.mail_from_name,
          }));
          showToast("success", "SMPT Details Update successfully.");
          setSmptConfiguration(false);
          setmailmaillererror("");
          setmailporterror("");
          setmailhosterror("");
          setmailusernameerror("");
          setmailpassworderror("");
          setmailencryptionerror("");
          setmailfromaddresserror("");
          setmailfromnameerror("");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };
  const handleApplicationUrlChange = (event) => {
    let { name, value } = event.target;
    setBackendAppUrlError("");
    setBuyerAppUrlError("");
    setSellerAppUrlError("");
    setAdminAppUrlError("");
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setApplicationUrlConfigurationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const UpdateApplicationUrlConfiguration = async () => {
    setisapplicationUrlConfigurationLoading(true);
    let errorMessageShow = false;

    if (
      applicationUrlConfigurationData.backend_app_url === "" ||
      applicationUrlConfigurationData.backend_app_url === null
    ) {
      setBackendAppUrlError("Backend App Url is  required!");
      errorMessageShow = true;
    } else {
      setBackendAppUrlError("");
    }

    if (
      applicationUrlConfigurationData.buyer_app_url === "" ||
      applicationUrlConfigurationData.buyer_app_url === null
    ) {
      setBuyerAppUrlError("Buyer App Url is  required!");
      errorMessageShow = true;
    } else {
      setBuyerAppUrlError("");
    }

    if (
      applicationUrlConfigurationData.seller_app_url === "" ||
      applicationUrlConfigurationData.seller_app_url === null
    ) {
      setSellerAppUrlError("Seller App Url is  required!");
      errorMessageShow = true;
    } else {
      setSellerAppUrlError("");
    }

    if (
      applicationUrlConfigurationData.admin_app_url === "" ||
      applicationUrlConfigurationData.admin_app_url === null
    ) {
      setAdminAppUrlError("Admin App Url is  required!");
      errorMessageShow = true;
    } else {
      setAdminAppUrlError("");
    }
    if (errorMessageShow) {
      setisapplicationUrlConfigurationLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "1";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.backend_app_url = applicationUrlConfigurationData.backend_app_url;
    Payload.buyer_app_url = applicationUrlConfigurationData.buyer_app_url;
    Payload.seller_app_url = applicationUrlConfigurationData.seller_app_url;
    Payload.admin_app_url = applicationUrlConfigurationData.admin_app_url;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisapplicationUrlConfigurationLoading(false);
        if (data.status === 200) {
          showToast("success", "Application Url Details Update successfully.");
          setApplicationUrlConfiguration(false);
          setBackendAppUrlError("");
          setBuyerAppUrlError("");
          setSellerAppUrlError("");
          setAdminAppUrlError("");
          GetAdminConfigurationData();
        } else if (data.status === 400) {
          if (data.errors.backend_app_url[0] === "validation.valid_url") {
            setBackendAppUrlError("Backend App Url is Invalid.");
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleDomainsChange = (event) => {
    let { name, value } = event.target;
    setAllowDomainsError("");
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setAllowDomainsConfigurationData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const UpdateAlloweDomainsConfiguration = async () => {
    setisallowDomainsConfigurationLoading(true);
    let errorMessageShow = false;

    // Validation for Allow Domains
    if (
      AllowDomainsConfigurationData.allow_domains === "" ||
      AllowDomainsConfigurationData.allow_domains === null
    ) {
      setAllowDomainsError("Allowed Domain is required!");
      errorMessageShow = true;
    } else {
      setAllowDomainsError("");
    }
    if (errorMessageShow) {
      setisallowDomainsConfigurationLoading(false);
      return;
    }

    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "1";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.allow_domains = AllowDomainsConfigurationData.allow_domains;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisallowDomainsConfigurationLoading(false);
        if (data.status === 200) {
          showToast("success", "Allow Domains Details Update successfully.");
          setAllowDomainsConfiguration(false);
          setAllowDomainsError("");
          GetAdminConfigurationData();
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };
  const handleShiprocketPasswordChange = (event) => {
    let { name, value } = event.target;
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setShiprocketPasswordConfiguration((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const UpdateshiprocketPassword = async () => {
    setisshowShiprocketpasswordLoading(true);
    let errorMessageShow = false;
    if (
      shiprocketPasswordConfiguration.shiprocket_api_user_password === "" ||
      shiprocketPasswordConfiguration.shiprocket_api_user_password === null
    ) {
      setShiprocketApiUserPasswordError(
        "Shiprocket User Password is  required!"
      );
      errorMessageShow = true;
    } else {
      setShiprocketApiUserPasswordError("");
    }
    if (errorMessageShow) {
      setisshowShiprocketpasswordLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "1";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "0";
    Payload.shiprocket_api_user_password =
      shiprocketPasswordConfiguration.shiprocket_api_user_password;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisshowShiprocketpasswordLoading(false);
        if (data.status === 200) {
          showToast("success", "Shiprocket User Password Update successfully.");
          setshowShiprocketpassword(false);
          setShiprocketApiUserPasswordError("");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleRazorpayPasswordChange = (event) => {
    let { name, value } = event.target;
    // Check if the input contains any spaces
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setRazorpaysecretvalueData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const UpdateRazorpayPassword = async () => {
    setisshowRazorpaypasswordLoading(true);
    let errorMessageShow = false;
    if (
      razorpaysecretvalueData.razorpay_secret === "" ||
      razorpaysecretvalueData.razorpay_secret === null
    ) {
      setRazorpayPasswordError("Razorpay Password is  required!");
      errorMessageShow = true;
    } else {
      setRazorpayPasswordError("");
    }
    if (errorMessageShow) {
      setisshowRazorpaypasswordLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "1";
    Payload.smtp_mail_password_value = "0";
    Payload.razorpay_secret = razorpaysecretvalueData.razorpay_secret;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisshowRazorpaypasswordLoading(false);
        if (data.status === 200) {
          showToast("success", "Razorpay Password Update successfully.");
          setShowRazorpaypassword(false);
          setRazorpayPasswordError("");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };
  const handleSmptMailPasswordChange = (event) => {
    let { name, value } = event.target;
    // Check if the input contains any spaces
    setSmtpPasswordError("");
    if (!/\s/.test(value)) {
      // If no spaces, update the state with trimmed value
      setSmtpMailPasswordValueData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const UpdateSmptMailPassword = async () => {
    setisshowSmtpMailPasswordLoading(true);
    let errorMessageShow = false;
    if (
      smtpMailPasswordValueData.smtp_mail_password === "" ||
      smtpMailPasswordValueData.smtp_mail_password === null
    ) {
      setSmtpPasswordError("SMTP Password is  required!");
      errorMessageShow = true;
    } else {
      setSmtpPasswordError("");
    }
    if (errorMessageShow) {
      setisshowSmtpMailPasswordLoading(false);
      return;
    }
    let Payload = {};
    Payload.shiprocket_config = "0";
    Payload.razorpay_config = "0";
    Payload.msg91_config = "0";
    Payload.smtp_config = "0";
    Payload.application_url = "0";
    Payload.allow_domains_config = "0";
    Payload.shiprocket_password_value = "0";
    Payload.razorpay_secret_value = "0";
    Payload.smtp_mail_password_value = "1";
    Payload.smtp_mail_password = smtpMailPasswordValueData.smtp_mail_password;
    const newPayload = JSON.stringify(Payload);

    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(`${Api_Host}/${APIEndpoint.updateAdminEnv}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: newPayload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setisshowSmtpMailPasswordLoading(false);
        if (data.status === 200) {
          showToast("success", "SMTP Password Update successfully.");
          setShowSmtpMailPassword(false);
          setSmtpPasswordError("");
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate("/");
          showToast("info", "Sessions expired! Please login again");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Env Configuration</li>
        </div>
      </div>

      <div className="page-container">
        <div className="env_main_container_seeting">
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">Shiprocket Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleShiprocketEditButton}
              >
                {" "}
                <FontAwesomeIcon icon={faPenToSquare} />
                Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Shiprocket Api User Email"
                  value={adminConfigData.SHIPROCKET_API_USER_EMAIL}
                  disabled
                />
                <span className="Env_Setting_span_tag">
                  {" "}
                  Shiprocket Api User Email
                </span>
              </label>
              <div className="Env_Change_password_container">
                {" "}
                <li
                  className="admin_env_change_password_link"
                  onClick={() => {
                    setshowShiprocketpassword(true);
                  }}
                >
                  Change Password
                </li>
              </div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Shiprocket Channel Id"
                  value={adminConfigData.SHIPROCKET_CHANNEL_ID}
                  disabled
                />
                <span className="Env_Setting_span_tag">
                  {" "}
                  Shiprocket Channel Id
                </span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Shiprocket Api Key"
                  value={adminConfigData.SHIPROCKET_API_KEY}
                  disabled
                />
                <span className="Env_Setting_span_tag">
                  {" "}
                  Shiprocket Api Key
                </span>
              </label>
            </div>
          </div>
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">Razorpay Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleRazorpayEditButton}
              >
                {" "}
                <FontAwesomeIcon icon={faPenToSquare} /> Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Razorpay Key"
                  value={adminConfigData.RAZORPAY_KEY}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Razorpay Key</span>
              </label>
              <div className="Env_Change_password_container">
                {" "}
                <li
                  className="admin_env_change_password_link"
                  onClick={() => {
                    setShowRazorpaypassword(true);
                  }}
                >
                  Change Password
                </li>
              </div>

              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Razorpay Webhook Secret"
                  value={adminConfigData.RAZORPAY_WEBHOOK_SECRET}
                  disabled
                />
                <span className="Env_Setting_span_tag">
                  {" "}
                  Razorpay Webhook Secret
                </span>
              </label>
            </div>
          </div>
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">MS91 Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleMS91EditButton}
              >
                {" "}
                <FontAwesomeIcon icon={faPenToSquare} /> Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="MSG91 Widget Id"
                  value={adminConfigData.MSG91_WIDGET_ID}
                  disabled
                />
                <span className="Env_Setting_span_tag"> MSG91 Widget Id</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="MSG91 Auth Token"
                  value={adminConfigData.MSG91_AUTH_TOKEN}
                  disabled
                />
                <span className="Env_Setting_span_tag"> MSG91 Auth Token</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="MSG91 Auth Key"
                  value={adminConfigData.MSG91_AUTH_KEY}
                  disabled
                />
                <span className="Env_Setting_span_tag"> MSG91 Auth Key</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="MSG91 Otp Template Id"
                  value={adminConfigData.MSG91_SEND_OTP_TEMPLATE_ID}
                  disabled
                />
                <span className="Env_Setting_span_tag">
                  {" "}
                  MSG91 Otp Template Id
                </span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="OTP Expiration"
                  value={adminConfigData.OTP_EXPIRATION}
                  disabled
                />
                <span className="Env_Setting_span_tag"> OTP Expiration</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="OTP Length"
                  value={adminConfigData.OTP_LENGTH}
                  disabled
                />
                <span className="Env_Setting_span_tag"> OTP Length</span>
              </label>
            </div>
          </div>
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">SMTP Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleSmptEditButton}
              >
                {" "}
                <FontAwesomeIcon icon={faPenToSquare} />
                Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Mailer"
                  value={adminConfigData.MAIL_MAILER}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Mailer</span>
              </label>
              <div className="Env_Change_password_container">
                {" "}
                <li
                  className="admin_env_change_password_link"
                  onClick={() => {
                    setShowSmtpMailPassword(true);
                  }}
                >
                  Change Password
                </li>
              </div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Host"
                  value={adminConfigData.MAIL_HOST}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Host</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Port"
                  value={adminConfigData.MAIL_PORT}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Port</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Username"
                  value={adminConfigData.MAIL_USERNAME}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Username</span>
              </label>
              {/* <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Password"
                  value={adminConfigData.MAIL_PASSWORD}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Password</span>
              </label> */}
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail Encryption"
                  value={adminConfigData.MAIL_ENCRYPTION}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail Encryption</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail From Address"
                  value={adminConfigData.MAIL_FROM_ADDRESS}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail From Address</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Mail From Name"
                  value={adminConfigData.MAIL_FROM_NAME}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Mail From Name</span>
              </label>
            </div>
          </div>
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">Application Url Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleApplicationUrlEditButton}
              >
                {" "}
                <FontAwesomeIcon icon={faPenToSquare} />
                Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Backend App Url"
                  value={adminConfigData.APP_URL}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Backend App Url</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Buyer App Url"
                  value={adminConfigData.BUYER_APP_URL}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Buyer App Url</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Seller App Url"
                  value={adminConfigData.SELLER_APP_URL}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Seller App Url</span>
              </label>
              <label className="Env_Setting_lable_tag">
                <input
                  type="text"
                  className="Env_Setting_input_tag"
                  placeholder="Admin App Url"
                  value={adminConfigData.ADMIN_APP_URL}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Admin App Url</span>
              </label>
            </div>
          </div>
          <div className="env_container_c1_box">
            <div className="Env_Heading_and_Button_c1">
              <h2 className="headings_el_tag">Allow Domains Configuration</h2>
              <button
                className="edit_Button_element"
                onClick={handleDomainsEditButton}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
                Edit
              </button>
            </div>
            <div>
              <label className="Env_Setting_lable_tag">
                <textarea
                  type="text"
                  className="Env_Setting_input_tag text_Area_input_admin_config"
                  placeholder="Allow Domains"
                  value={adminConfigData.ALLOWED_DOMAINS}
                  disabled
                />
                <span className="Env_Setting_span_tag"> Allow Domains</span>
              </label>
            </div>
          </div>
        </div>

        {shiprocketConfiguration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">Shiprocket Configuration</h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      showShiprocketConfiguration(false);
                      setshiprocketemailerror("");
                      setshiprocketpassworderror("");
                      setshiprocketCancelediderror("");
                      setshipRocketApiKeyError("");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Shiprocket Api User Email"
                      name="shiprocket_api_user_email"
                      value={
                        shiprocketConfigurationData.shiprocket_api_user_email
                      }
                      onChange={handleShiprocketChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Shiprocket Api User Email
                    </span>
                  </label>
                  <li className="admin-error_line">{shiprocketemailerror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Shiprocket Channel Id"
                      name="shiprocket_channel_id"
                      value={shiprocketConfigurationData.shiprocket_channel_id}
                      onChange={handleShiprocketChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Shiprocket Channel Id
                    </span>
                  </label>
                  <li className="admin-error_line">
                    {shiprocketCancelediderror}
                  </li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      name="shiprocket_api_key"
                      placeholder="Shiprocket Api Key"
                      value={shiprocketConfigurationData.shiprocket_api_key}
                      onChange={handleShiprocketChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Shiprocket Api Key
                    </span>
                  </label>
                  <li className="admin-error_line">{shipRocketApiKeyError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateShiprocketConfiguration}
                  disabled={isShiprocketConfiguration}
                >
                  {isShiprocketConfiguration ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
        {razorpayConfiguration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">Razorpay Configuration</h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setRazorpayConfiguration(false);
                      setrazorpaykeyError("");
                      set_razorpay_webhook_secret_error("");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      name="razorpay_key"
                      className="Env_Setting_input_tag"
                      placeholder="Razorpay Key"
                      value={razorpayConfigurationData.razorpay_key}
                      onChange={handleRazorpayChange}
                    />
                    <span className="Env_Setting_span_tag"> Razorpay Key</span>
                  </label>
                  <li className="admin-error_line">{razorpaykeyError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      name="razorpay_webhook_secret"
                      className="Env_Setting_input_tag"
                      placeholder="Razorpay Webhook Secret"
                      value={razorpayConfigurationData.razorpay_webhook_secret}
                      onChange={handleRazorpayChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Razorpay Webhook Secret
                    </span>
                  </label>
                  <li className="admin-error_line">
                    {razorpay_webhook_secret_error}
                  </li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateRazorpayConfiguration}
                  disabled={israzorpayConfigurationLoading}
                >
                  {israzorpayConfigurationLoading ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
        {MS91Configuration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">MS91 Configuration</h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setMS91Configuration(false);
                      setMSG91WiggetIderror("");
                      setMSG91AuthTokenError("");
                      setMSG91AuthKeyError("");
                      setRegisterOTPTemplateIdError("");
                      setOTPLengthError("");
                      setOTPExpirationError("");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="MSG91 Widget Id"
                      name="msg91_widget_id"
                      value={MS91ConfigurationData.msg91_widget_id}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      MSG91 Widget Id
                    </span>
                  </label>
                  <li className="admin-error_line">{MSG91WiggetIderror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="MSG91 Auth Token"
                      name="msg91_auth_token"
                      value={MS91ConfigurationData.msg91_auth_token}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      MSG91 Auth Token
                    </span>
                  </label>
                  <li className="admin-error_line">{MSG91AuthTokenError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="MSG91 Auth Key"
                      name="msg91_auth_key"
                      value={MS91ConfigurationData.msg91_auth_key}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      MSG91 Auth Key
                    </span>
                  </label>
                  <li className="admin-error_line">{MSG91AuthKeyError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="MSG91 Otp Template Id"
                      name="msg91_otp_template_id"
                      value={MS91ConfigurationData.msg91_otp_template_id}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      MSG91 Otp Template Id
                    </span>
                  </label>
                  <li className="admin-error_line">
                    {RegisterOTPTemplateIdError}
                  </li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      name="otp_expiration"
                      className="Env_Setting_input_tag"
                      placeholder="OTP Expiration"
                      value={MS91ConfigurationData.otp_expiration}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      OTP Expiration
                    </span>
                  </label>
                  <li className="admin-error_line">{OTPExpirationError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="OTP Length"
                      name="otp_length"
                      value={MS91ConfigurationData.otp_length}
                      onChange={handleMS91change}
                    />
                    <span className="Env_Setting_span_tag"> OTP Length</span>
                  </label>
                  <li className="admin-error_line">{OTPLengthError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateMS91Configuration}
                  disabled={isMS91ConfigurationLoading}
                >
                  {isMS91ConfigurationLoading ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
        {SmptConfiguration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              {" "}
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">SMTP Configuration</h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setSmptConfiguration(false);
                      setmailmaillererror("");
                      setmailporterror("");
                      setmailhosterror("");
                      setmailusernameerror("");
                      setmailpassworderror("");
                      setmailencryptionerror("");
                      setmailfromaddresserror("");
                      setmailfromnameerror("");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail Mailer"
                      name="mail_mailer"
                      value={SmptConfigurationData.mail_mailer}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag"> Mail Mailer</span>
                  </label>
                  <li className="admin-error_line">{mailmaillererror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail Host"
                      name="mail_host"
                      value={SmptConfigurationData.mail_host}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag"> Mail Host</span>
                  </label>
                  <li className="admin-error_line">{mailhosterror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail Port"
                      name="mail_port"
                      value={SmptConfigurationData.mail_port}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag"> Mail Port</span>
                  </label>
                  <li className="admin-error_line">{mailporterror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail Username"
                      name="mail_username"
                      value={SmptConfigurationData.mail_username}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag"> Mail Username</span>
                  </label>
                  <li className="admin-error_line">{mailusernameerror}</li>
                  {/* <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      name="mail_password"
                      placeholder="Mail Password"
                      value={SmptConfigurationData.mail_password}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag"> Mail Password</span>
                  </label>
                  <li className="admin-error_line">{mailpassworderror}</li> */}
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail Encryption"
                      name="mail_encryption"
                      value={SmptConfigurationData.mail_encryption}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Mail Encryption
                    </span>
                  </label>
                  <li className="admin-error_line">{mailencryptionerror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail From Address"
                      name="mail_from_address"
                      value={SmptConfigurationData.mail_from_address}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Mail From Address
                    </span>
                  </label>
                  <li className="admin-error_line">{mailfromaddresserror}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Mail From Name"
                      name="mail_from_name"
                      value={SmptConfigurationData.mail_from_name}
                      onChange={handleSmptChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Mail From Name
                    </span>
                  </label>
                  <li className="admin-error_line">{mailfromnameerror}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateSmptConfiguration}
                  disabled={isSmptConfigurationLoading}
                >
                  {isSmptConfigurationLoading ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
        {applicationUrlConfiguration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">
                    Application Url Configuration
                  </h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setApplicationUrlConfiguration(false);
                      setBackendAppUrlError("");
                      setBuyerAppUrlError("");
                      setSellerAppUrlError("");
                      setAdminAppUrlError("");
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Backend App Url"
                      name="backend_app_url"
                      value={applicationUrlConfigurationData.backend_app_url}
                      onChange={handleApplicationUrlChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Backend App Url
                    </span>
                  </label>
                  <li className="admin-error_line">{BackendAppUrlError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Buyer App Url"
                      name="buyer_app_url"
                      value={applicationUrlConfigurationData.buyer_app_url}
                      onChange={handleApplicationUrlChange}
                    />
                    <span className="Env_Setting_span_tag"> Buyer App Url</span>
                  </label>
                  <li className="admin-error_line">{BuyerAppUrlError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Seller App Url"
                      name="seller_app_url"
                      value={applicationUrlConfigurationData.seller_app_url}
                      onChange={handleApplicationUrlChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Seller App Url
                    </span>
                  </label>
                  <li className="admin-error_line">{SellerAppUrlError}</li>
                  <label className="Env_Setting_lable_tag">
                    <input
                      type="text"
                      className="Env_Setting_input_tag"
                      placeholder="Admin App Url"
                      name="admin_app_url"
                      value={applicationUrlConfigurationData.admin_app_url}
                      onChange={handleApplicationUrlChange}
                    />
                    <span className="Env_Setting_span_tag"> Admin App Url</span>
                  </label>
                  <li className="admin-error_line">{AdminAppUrlError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateApplicationUrlConfiguration}
                  disabled={isapplicationUrlConfigurationLoading}
                >
                  {isapplicationUrlConfigurationLoading ? (
                    <LoaderButton />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {allowDomainsConfiguration && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">
                    Allow Domains Configuration
                  </h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setAllowDomainsConfiguration(false);
                      setAllowDomainsError("");
                    }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <textarea
                      type="text"
                      className="Env_Setting_input_tag text_Area_input_admin_config"
                      placeholder="Allow Domains"
                      name="allow_domains"
                      value={AllowDomainsConfigurationData.allow_domains}
                      onChange={handleDomainsChange}
                    />
                    <span className="Env_Setting_span_tag"> Allow Domains</span>
                  </label>
                  <li className="admin-error_line">{allowDomainsError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateAlloweDomainsConfiguration}
                  disabled={isallowDomainsConfigurationLoading}
                >
                  {isallowDomainsConfigurationLoading ? (
                    <LoaderButton />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {showShiprocketpassword && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">
                    Shiprocket Password Configuration
                  </h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setshowShiprocketpassword(false);
                      setShiprocketApiUserPasswordError("");
                    }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <textarea
                      type="text"
                      className="Env_Setting_input_tag text_Area_input_admin_config"
                      placeholder="Shiprocket User Password"
                      name="shiprocket_api_user_password"
                      value={
                        shiprocketPasswordConfiguration.shiprocket_api_user_password
                      }
                      onChange={handleShiprocketPasswordChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Shiprocket User Password
                    </span>
                  </label>
                  <li className="admin-error_line">
                    {shiprocketApiUserPasswordError}
                  </li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateshiprocketPassword}
                  disabled={isshowShiprocketpasswordLoading}
                >
                  {isshowShiprocketpasswordLoading ? (
                    <LoaderButton />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {showRazorpaypassword && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">
                    Razorpay Password Configuration
                  </h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setShowRazorpaypassword(false);
                      setRazorpayPasswordError("");
                    }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <textarea
                      type="text"
                      className="Env_Setting_input_tag text_Area_input_admin_config"
                      placeholder="Razorpay Password"
                      name="razorpay_secret"
                      value={razorpaysecretvalueData.razorpay_secret}
                      onChange={handleRazorpayPasswordChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Razorpay Password
                    </span>
                  </label>
                  <li className="admin-error_line">{razorpayPasswordError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateRazorpayPassword}
                  disabled={isshowRazorpaypasswordLoading}
                >
                  {isshowRazorpaypasswordLoading ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
        {showSmtpMailPassword && (
          <div className="Admin_Confog_edit_Modal_c1">
            <div className="Admin_config_c1_container">
              <div className="env_container_c1_box">
                <div className="Env_Heading_and_Button_c1">
                  <h2 className="headings_el_tag">
                    Smtp Mail Password Configuration
                  </h2>
                  <button
                    className="edit_Cross_Button"
                    onClick={() => {
                      setShowSmtpMailPassword(false);
                      setSmtpPasswordError("");
                    }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </button>
                </div>
                <div>
                  <label className="Env_Setting_lable_tag">
                    <textarea
                      type="text"
                      className="Env_Setting_input_tag text_Area_input_admin_config"
                      placeholder="Smtp Mail Password"
                      name="smtp_mail_password"
                      value={smtpMailPasswordValueData.smtp_mail_password}
                      onChange={handleSmptMailPasswordChange}
                    />
                    <span className="Env_Setting_span_tag">
                      {" "}
                      Smtp Mail Password
                    </span>
                  </label>
                  <li className="admin-error_line">{smtpPasswordError}</li>
                </div>
              </div>
              <div className="update_Button_container">
                <button
                  className="Update_Button"
                  onClick={UpdateSmptMailPassword}
                  disabled={isshowSmtpMailPasswordLoading}
                >
                  {isshowSmtpMailPasswordLoading ? <LoaderButton /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminConfig;
