import { useState, useEffect, useMemo, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import GlobalFilter from "../../../Components/GlobalFilter";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import LoaderButton from "../../../Components/LoaderButton";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { useNavigate, useLocation } from "react-router-dom";
import InputContext from "../../../Components/InputContext";
import AddCategory from "./NewCategory";
import EditCategory from "./EditCategory";
import "./category.css";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Categories = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [totalCount, setTotalCount] = useState("");
  const [loading, setLoading] = useState(true);
  let [categoryData, setCategoryData] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [loadingButtonDelete, setLoadingButtonDelete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowCategoryEditModal] = useState(false);
  const [loadingButtonEdit, setLoadingButtonEdit] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [categoryParentId, setCategoryParentId] = useState("");
  const categorysPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  let [lastPage, setLastPage] = useState();
  const [pagination, setPagination] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const handleCloseAddModel = async () => {
    let data = getDataFromCookies("isCategoryCreated");
    if (data) {
      lastPage = totalCount % categorysPerPage == 0 ? lastPage + 1 : lastPage;
      setShowAddModal(false);
      getCategoryData(
        `${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=${lastPage}`
      );
      removeDataFromCookie("isCategoryCreated");
    } else {
      setShowAddModal(false);
    }
  };

  const handleAddCategories = () => {
    setShowAddModal(true);
  };

  const handleCloseEditModel = () => {
    const data = getDataFromCookies("isCategoryEdit");
    if (data) {
      getCategoryData(
        `${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=${currentPage}`
      );
      setShowCategoryEditModal(false);
      removeDataFromCookie("isCategoryEdit");
    } else {
      setShowCategoryEditModal(false);
    }
    setLoadingButtonEdit(false);
  };

  // Featch Category API TO show List of All Category Element;
  const getAllCategoriesDataArray = async () => {
    await fetch(`${API_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setParentCategories(dataArray);
        } else {
          console.error("Data is not available");
        }
      })

      .catch((err) => console.error(err));
  };

  const getCategoryData = async (url) => {
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    const response = await fetch(`${url}&per_page=${categorysPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const tempAarray = res.data.data;
      const filteredArray = tempAarray.filter((category) => {
        return category.parent_category_id == 0;
      });
      setCategoryData(res.data.data);
      setPagination(res.data);
      setTotalCount(res.data.total);
      setCurrentPage(res.data.current_page);
      setLastPage(res.data.last_page);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setCategoryData([]);
      setPagination("");
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    getCategoryData(`${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=1`);
    getAllCategoriesDataArray();
  }, []);

  const handleEditCategory = (id, name, image, parentid) => {
    setCategoryId(id);
    setCategoryName(name);
    setCategoryImage(image);
    setCategoryParentId(parentid);
    setLoadingButtonEdit(false);
    setShowCategoryEditModal(true);
  };

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getCategoryData(data);
  };

  const handleConfirmDelete = async () => {
    setLoadingButtonDelete(true);
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudCategory}/${categoryId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", "Category deleted successfully");
      if (currentPage == lastPage) {
        currentPage =
          totalCount % categorysPerPage == 1 ? currentPage - 1 : currentPage;
        getCategoryData(
          `${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=${currentPage}`
        );
      } else {
        getCategoryData(
          `${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=${currentPage}`
        );
      }
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 400) {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    setShowDeleteModal(false);
    setLoadingButtonDelete(false);
  };

  const handleCancelDelete = () => {
    setCategoryId("");
    setCategoryName("");
    setShowDeleteModal(false);
  };

  const openDeleteModal = (id, name) => {
    setCategoryId(id);
    setCategoryName(name);
    setShowDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * categorysPerPage,
      },

      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => (
          <img
            className="AdminBrandSetupListDivallelementImageDivimage"
            src={imgHost + "/" + value}
            alt="Image"
          />
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Parent Category",
        accessor: "parent",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const image = row.original.image;
          const parent = row.original.parent;
          return (
            <>
              <button
                type="button"
                className="action-icon edit-button"
                title="Edit"
                onClick={() => {
                  handleEditCategory(id, name, image, parent);
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                type="button"
                className="action-icon trash-button"
                title="Delete"
                onClick={() => openDeleteModal(id, name)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </>
          );
        },
      },
    ],
    [currentPage, categoryData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: categoryData, // Rename 'categoryData' to 'data'
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // handle search api calls
  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      categoryData = [];
      currentPage = 1;
      let url = `${API_HOST}/${APIEndpoint.getaAllCategory}?page_no=${currentPage}`;
      if (searchQuery !== "") {
        getCategoryData(url);
      } else {
        getCategoryData(url);
      }
    }, 1000);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);

  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active">Categories</li>
          </div>
        </div>

        <div className="page-container">
          {/* Filter and Button */}
          <div className="table-serach-area">
            <button className="primary-btn" onClick={handleAddCategories}>
              Add Category
            </button>
            <div className="search-bar">
              <div className="fontAwsomeDiv">
                {searchLoading ? (
                  <LoaderButton />
                ) : (
                  <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
                )}
              </div>
              <input
                id="seller-searchForProducts"
                placeholder="Search..."
                className="search-input-order"
                name="search-query"
                value={searchQuery}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.target.value;
                  setSearchQuery(val);
                }}
              />
            </div>
          </div>

          <div className=" InnerTableContainer">
            <table
              id="categoryTable"
              className="order-table"
              {...getTableProps()}
            >
              <thead className="Category-grid-container-item2-tableHead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {" "}
                        <div className="header-cell">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {}

              <tbody {...getTableBodyProps()}>
                {loading ? (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">Loading...</div>
                    </td>
                  </tr>
                ) : page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="TableBodyData" {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">
                        No Categories available.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="pagination-container">
              {pagination && (
                <Paginate
                  pagination={pagination}
                  pageChange={handleURLChange}
                  pageName={"Categories"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* category Add Modal */}
      {showAddModal && (
        <AddCategory
          handleCloseAddModel={handleCloseAddModel}
          parentCategories={parentCategories}
        />
      )}

      {/* Category Edit Modal */}
      {showEditModal && (
        <EditCategory
          handleCloseEditModel={handleCloseEditModel}
          CategoryId={categoryId}
          categoryName={categoryName}
          CategoryImage={categoryImage}
          categoryParentId={categoryParentId}
          parentCategories={parentCategories}
          getCategories={getCategoryData}
          loadingButtonEdit={loadingButtonEdit}
          setLoadingButtonEdit={setLoadingButtonEdit}
        />
      )}

      {/* Category Delete Modal */}
      {showDeleteModal && (
        <div
          id="deleteModal"
          className="modal fade"
          style={{ display: showDeleteModal ? "block" : "none" }}
        >
          {/* Delete modal content */}
          <div className="modal-content">
            <span className="modal-close" onClick={handleCancelDelete}>
              <FontAwesomeIcon icon={faTimes} />
            </span>

            <h3 className="modal-header">Delete Confirmation</h3>
            <p className="modal-line">
              Are you sure you want to delete{" "}
              <span className="deleteitemsNameappPages">{categoryName}</span>{" "}
              category?
            </p>

            <div className="modal-buttons">
              <button
                type="button"
                className="btn secondary-btn"
                onClick={handleCancelDelete}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  handleConfirmDelete();
                }}
              >
                {loadingButtonDelete ? <LoaderButton /> : "Yes, delete it!"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
