import React, { useState, useEffect, useContext } from "react";
import APIEndpoint from "../../../config/APIEndpoints.json";
import InputContext from "../../../Components/InputContext";
import Card from "./Card";
import Paginate from "../../../Components/Pagination/Paginate";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const CollectionProductsListModal = (props) => {
  const [pagination, setPagination] = useState("");
  const [showPagination, setShowPagination] = useState(false);
  let [productsList, setProductsList] = useState([]);
  let [searchQuery, setSearchQuery] = useState("");

  let URL = `${API_HOST}/${APIEndpoint.getProductsList}?&order=desc&sort=created_at&page_no=1&per_page=20`;
  let QueryURL = `${API_HOST}/${APIEndpoint.searchbarQuery}${searchQuery}`;
  let [fetchURL, setFetchURL] = useState(URL);
  let [fetchQueryUrl, setFetchQueryUrl] = useState(QueryURL);
  function productExistInArray(productId) {
    return props.selectedProducts.some((product) => product.id == productId);
  }
  const filteredProducts = (data) => {
    if (props.filterProducts == null) {
      return data;
    }
    return data.filter(
      (product) =>
        !props.filterProducts.find(
          (filterProduct) => filterProduct.id === product.id
        )
    );
  };

  useEffect(() => {
    if (productsList.length > 0) {
      setShowPagination(true);
    } else {
      setShowPagination(false);
    }
  }, [productsList]);

  const searchQueryResult = async (fetchQueryUrl) => {
    try {
      await fetch(`${fetchQueryUrl}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.status === 200) {
            // let products = [...productsList, ...res.data.products];
            let products = [...res.data.products];
            setPagination(res.data);
            let filterProducts;
            if (products.length > 0) {
              filterProducts = filteredProducts(products);
            }

            filterProducts &&
              filterProducts.forEach((product) => {
                if (!("isSelected" in product)) {
                  let productExists = productExistInArray(product.id);
                  product.isSelected = productExists;
                }
              });

            setProductsList([...filterProducts]);

            setFetchQueryUrl(res.data.next_page_url);
          }
          if (res.status !== 200) {
            setPagination(null);
          }
          if (res.status == 404) {
            setProductsList([]);
          }
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const fetchProductsList = async (fetchURL) => {
    try {
      await fetch(`${fetchURL}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.status == 200) {
            setPagination(res.data);
            // let products = [...productsList, ...res.data.data];
            let products = [...res.data.data];
            setFetchURL(`${res.data.next_page_url}&per_page=20`);
            let filterProducts;
            if (products.length > 0) {
              filterProducts = filteredProducts(products);
              if (
                filterProducts.length == 0 &&
                res.data.next_page_url !== null
              ) {
                return handleURLChange();
              }
            }
            filterProducts &&
              filterProducts.forEach((product) => {
                if (!("isSelected" in product)) {
                  let productExists = productExistInArray(product.id);

                  product.isSelected = productExists;
                }
              });
            setProductsList([...filterProducts]);
          }
          if (res.status !== 200) {
            setPagination(null);
          }
          if (res.status == 404) {
            setProductsList([]);
          }
        });
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    let timerId;

    if (searchQuery !== "") {
      fetchQueryUrl = `${API_HOST}/${APIEndpoint.searchbarQuery}${searchQuery}`;
      clearTimeout(timerId);

      timerId = setTimeout(() => {
        productsList = [];
        searchQueryResult(fetchQueryUrl);
      }, 500);
    } else {
      clearTimeout(timerId);

      timerId = setTimeout(async () => {
        fetchURL = URL;
        productsList = [];
        fetchProductsList(fetchURL);
      }, 100);
    }

    return () => clearTimeout(timerId);
  }, [searchQuery]);

  const closeModal = () => {
    setProductsList([]);
    setSearchQuery("");
    setFetchURL(URL);
    setFetchQueryUrl(QueryURL);
    props.closeShowAddProducts(false);
  };
  const handleURLChange = (data) => {
    document.getElementById("editCollectionListHolder").scrollTo(0, 0);

    if (searchQuery !== "") {
      data = data + `?${searchQuery}&per_page=20`;
      searchQueryResult(data);
    } else {
      data = data + "?&per_page=20";
      fetchProductsList(data);
    }
  };

  return (
    <div
      className="modailmaindiv"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className="CollectionProductsListModalContainer"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="editCollectionHolder">
          <div className="editCollectionDetailsHolder">
            <div className="editCollectionDetailsTitleContainer">
              <h1 className="editCollectionDetailsTitle">
                Select Products{" "}
                {props.selectedProducts.length > 0 &&
                  `: ${props.selectedProducts.length}`}
              </h1>
              <div className="addProductModalBtnContainer">
                <div
                  className={` primary-btn ${
                    productsList && productsList.length == 0 ? "" : ""
                  }`}
                  onClick={() => {
                    if (productsList.length > 0) {
                      closeModal();
                    }
                  }}
                  style={{
                    opacity: productsList.length == 0 ? 0.5 : 1,
                  }}
                >
                  Add
                </div>
                <div
                  className=" secondary-btn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </div>
              </div>
            </div>
            <div className="editCollectionDetailsSearchBarContainer">
              {/* <InputControl
                inputTagId=""
                placeholder="Search Product"
                label={<span className="inputTagLabel">Search</span>}
                type="text"
                style={{
                  color: "#808080",
                }}
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                  setFetchQueryUrl(
                    `${API_HOST}/${APIEndpoint.searchbarQuery}${event.target.value}`
                  );
                }}
              />{" "} */}
              <div className="input-row">
                <label className="Admin_Profile_Lable_tag">
                  <input
                    type="text"
                    name="Search_Product"
                    className="Admin_Profile_input_tag"
                    onChange={(event) => {
                      setSearchQuery(event.target.value);
                      setFetchQueryUrl(
                        `${API_HOST}/${APIEndpoint.searchbarQuery}${event.target.value}`
                      );
                    }}
                    value={searchQuery}
                    placeholder="Search Product"
                  />
                  <span className="Admin_Profile_span_tag">Search Product</span>
                </label>
              </div>
            </div>
          </div>

          <div
            className="editCollectionListHolder"
            id="editCollectionListHolder"
          >
            {productsList && (
              <div className="addCollectionModelProductsList collectionProductsList">
                {productsList.length > 0 &&
                  productsList.map((product, index) => (
                    <Card
                      item={product}
                      cardIndex={index}
                      productsCollectionProducts={props.filterProducts}
                      productsList={productsList}
                      setProductsList={setProductsList}
                      selectedProducts={props.selectedProducts}
                      setSelectedProducts={props.setSelectedProducts}
                      initialProducts={
                        props.initialProducts && props.initialProducts
                      }
                    />
                  ))}
              </div>
            )}
            {/* Pagination */}
            <div className="pagination-container">
              {showPagination && pagination && (
                <Paginate
                  pagination={pagination}
                  pageChange={handleURLChange}
                  pageName={"Products"}
                  filterLProductsCount={
                    props.filterProducts && props.filterProducts
                  }
                  filterTotal={props.filterProducts.length}
                />
              )}
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default CollectionProductsListModal;
