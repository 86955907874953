import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import TopNavbar from "../Navbar/TopNavbar/TopNavbar";
import APIEndpoint from "../../config/APIEndpoints.json";
import Navigation from "../../config/Navigation.json";
import stringConstant from "../../config/ProjectConstants.json";
import LoaderButton from "../LoaderButton";
import {
  getDataFromCookies,
  setDataToCookiesWithExpirationTime,
} from "../Cookie";
import "./login.css";
import InputContext from "../InputContext";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

function Login() {
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  let isValidEmail = (email) => {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    return regex;
  };

  let blankSpaces = (str) => {
    let regex = /^\S+$/.test(str);
    return regex;
  };

  // call handleEmailPasswordLoginSubmit on Enter keypress
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault();
      setLoadingButton(true);
      handleEmailPasswordLoginSubmit();
    }
  };

  let errorsFound = false;

  const handleEmailPasswordLoginSubmit = async () => {
    setErrorMsg("");
    setErrorMsgEmail("");
    setErrorMsgPassword("");

    let vaildEmail = isValidEmail(values.email);
    if (!values.email) {
      setErrorMsgEmail(stringConstant.errorMessages.errorForBlankEmail);
      errorsFound = true;
    } else if (!vaildEmail) {
      setErrorMsgEmail(stringConstant.errorMessages.errorForInvalidEmail);
      errorsFound = true;
    }

    if (!values.password) {
      setErrorMsgPassword(stringConstant.errorMessages.errorForBlankPasssword);
      errorsFound = true;
    } else if (!blankSpaces(values.password)) {
      setErrorMsgPassword(
        stringConstant.errorMessages.errorForBlankSpacePassword
      );
      errorsFound = true;
    }

    if (errorsFound === true) {
      setLoadingButton(false);
      return;
    }

    try {
      let payload = JSON.stringify(values);
      let response = await fetch(`${API_HOST}/${APIEndpoint.login}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: payload,
      });

      let res = await response.json();
      setLoadingButton(false);

      if (res.status === 200) {
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        setDataToCookiesWithExpirationTime(
          "7b9KpQ3rW6",
          res.data,
          expiryDate,
          "/"
        );
        contextObj.setInput("isLoginByAdmin", true);
        setDataToCookiesWithExpirationTime("loggedIn", true, expiryDate, "/");

        if (contextObj.inputValue.prevPath) {
          navigate(`${contextObj.inputValue.prevPath}`);
        } else {
          contextObj.setInput("prevPath", null);
          navigate(`${Navigation.dashboard}`);
        }
      } else if (res.status === 401) {
        setErrorMsgPassword(res.message);
      } else {
        console.error(res);
      }
    } catch (error) {
      setLoadingButton(false);
      console.error("Error during login:", error);
      // Handle different error types or display a generic error message
      setErrorMsgPassword("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const Token = getDataFromCookies("7b9KpQ3rW6");
    const isLogin = getDataFromCookies("loggedIn");
    if ((Token == "" || Token == null) && (isLogin == "" || isLogin == null)) {
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="BuyerLoginPage-container">
      <div className="BuyerLoginPage-innerBox">
        <h2 className="BuyerLoginPage-heading">Admin Login</h2>
        <label className="ForgotPassword_Joyrejoy_lable_tag">
          <input
            type="email"
            minLength="3"
            maxLength="70"
            autoFocus
            className="ForgotPassword_Joyrejoy_input_tag"
            onChange={(event) => {
              setErrorMsg("");
              setValues((prev) => ({
                ...prev,
                email: event.target.value.trim(),
              }));
              setErrorMsgEmail("");
              setErrorMsg("");
            }}
            placeholder="Email"
          />{" "}
          <span className="ForgotPassword_Joyrejoy_span_tag"> Email</span>
        </label>
        <li className="PasswordErrorMessage"> {errorMsgEmail}</li>
        <label className="ForgotPassword_Joyrejoy_lable_tag">
          <input
            id="PasswordContainer11"
            onKeyPress={handleKeyPress}
            type="password"
            className="ForgotPassword_Joyrejoy_input_tag"
            onChange={(event) => {
              setValues((prev) => ({
                ...prev,
                password: event.target.value.trim(),
              }));
              setErrorMsgPassword("");
              setErrorMsg("");
            }}
            placeholder="Password"
          />
          <span className="ForgotPassword_Joyrejoy_span_tag"> Password</span>
          <span className="password-eye-icons">
            {showPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                className="fontassowmicon"
                style={{ color: "rgb(205, 205, 205)" }}
                onClick={() => {
                  setShowPassword(false);
                  let x = document.getElementById("PasswordContainer11");
                  x.type = "password";
                }}
              />
            ) : (
              <FontAwesomeIcon
                className="fontassowmicon"
                icon={faEyeSlash}
                style={{ color: "rgb(205, 205, 205)" }}
                onClick={() => {
                  setShowPassword(true);
                  let x = document.getElementById("PasswordContainer11");
                  x.type = "text";
                }}
              />
            )}
          </span>
        </label>
        <li className="PasswordErrorMessage"> {errorMsgPassword}</li>
        <div className="BuyerLogin-signup-resetPassword">
          <span
            id="BuyerLogin-signup-resetPassword-resetBtn"
            onClick={() => {
              navigate(`${Navigation.forgotPassword}`);
            }}
          >
            <p
              className="BuyerLogin-SignUpBtn1"
              style={{ color: "blue", cursor: "pointer" }}
            >
              Forgot Password
            </p>
          </span>
        </div>
        <div className="BuyerLoginPage-footer">
          <p className="BuyerLoginPage-error">{errorMsg}</p>
          <button
            disabled={submitButtonDisabled}
            onClick={() => {
              setLoadingButton(true);
              handleEmailPasswordLoginSubmit();
            }}
          >
            {loadingButton ? <LoaderButton /> : "Login"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
