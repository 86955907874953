import { useContext, useEffect, useMemo, useState } from "react";
import "./customer.css";
import {
  faCalendarDays,
  faEye,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import GlobalFilter from "../../../Components/GlobalFilter";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const CustomersDetails = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  const { customerId } = useParams();
  const [buyerDetails, setBuyerDetails] = useState({});
  const [buyerOrderDetails, setBuyerDetailsOrderDetais] = useState([]);
  const navigate = useNavigate();
  const token = getDataFromCookies("7b9KpQ3rW6");

  // Get Seller Details
  const GetCustomersDetails = async () => {
    await fetch(`${API_HOST}/${APIEndpoint.getCustomerDetails}/${customerId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data.orders;
        if (data.data || Array.isArray(dataArray)) {
          setBuyerDetailsOrderDetais(dataArray);
          setBuyerDetails(data.data.customer);
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    GetCustomersDetails();
  }, []);

  // Navigate to OrderDetailsPage
  const handleViewOrderPage = (orderId) => {
    navigate(`/order-details/${orderId}`);
    return;
  };
  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Order Id",
        accessor: "order_number",
      },
      {
        Header: "Order Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>
            <div>
              <span style={{ fontWeight: "bold" }}>Date: </span>
              {new Date(value).toLocaleDateString()}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Time: </span>
              {new Date(value).toLocaleTimeString()}
            </div>
          </>
        ),
      },
      {
        Header: "Total Amount",
        Footer: "Total Amount",
        accessor: "order_amount",
        Cell: ({ value }) => <div className="cell-container">₹{value}</div>,
      },
      {
        Header: "Order Status",
        accessor: "order_status",
        Cell: ({ value }) => (
          <>
            {value === "Pending" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffe082", color: "#fff" }}
              >
                Pending
              </p>
            ) : value === "Declined" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#FFAAAA", color: "#fff" }}
              >
                Declined
              </p>
            ) : value === "Delivered" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#800080", color: "#fff" }}
              >
                Delivered
              </p>
            ) : value === "Confirmed" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#7CB342", color: "#fff" }}
              >
                Confirmed
              </p>
            ) : value === "Packaging" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", color: "#fff" }}
              >
                Packaging
              </p>
            ) : value === "Out For Delivery" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", color: "#fff" }}
              >
                Out For Delivery
              </p>
            ) : (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", color: "#fff" }}
              >
                Declined
              </p>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => (
          <span>
            <button
              onClick={() => handleViewOrderPage(row.original.id)}
              className="action-icon eye-button"
              title="view"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </span>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: buyerOrderDetails,
      initialState: { pageIndex: 0, pageSize: 15, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    });

  return (
    <div>
      <div className="page-data">
        <div className="AdminCustomerDetailsSearchBox">
          <p className="AdminCustomersDetailsheadingbrand">Customer Details</p>
        </div>
        <div className="CustomesPageDivsecondelement">
          <div className="CustomesPageDivsecondelementdiv1">
            <h6 className="CustomesPageDivsecondelementchild CustomesPageDivsecondelementchild1">
              customerID : {"  "}
              {buyerDetails.id} {"  "}
            </h6>
            <li className="CustomesPageDivsecondelementchild">
              {"  "}
              <FontAwesomeIcon icon={faCalendarDays} />
              {"    "}
              <span> Joined At : {formatDate(buyerDetails.created_at)}</span>
            </li>
          </div>
        </div>

        <div className="Customerdetails-grid-container-item1">
          <div className="Names_Container_div">
            {Object.keys(buyerDetails).length > 0 && (
              <div key={buyerDetails.id}>
                {/* <h3 className="customersflexseconddivheading">
                    Customer info
                  </h3> */}
                <div className="Names-Container">
                  <li className="customersflexseconddivbox">
                    <span className="Name-sub-heading"> Name: </span>{" "}
                    {buyerDetails.f_name} {buyerDetails.l_name}
                  </li>
                  <li className="customersflexseconddivbox">
                    <span className="Name-sub-heading">Email: </span>{" "}
                    {buyerDetails.email}
                  </li>
                  <li className="customersflexseconddivbox">
                    <span className="Name-sub-heading"> Phone: </span>{" "}
                    {buyerDetails.phone}
                  </li>

                  <p className="customersflexseconddivbox">
                    <span className="Name-sub-heading">Total order:</span>{" "}
                    {buyerDetails.orders.length}
                  </p>
                  <span className="Name-sub-heading">
                    {" "}
                    Status:{" "}
                    <button
                      className={`customersflexseconddivbuttonbox ${
                        buyerDetails.status === 1
                          ? "activecustomer"
                          : "blockedcustomer"
                      }`}
                    >
                      {buyerDetails.status === 1 ? "Active" : "Block"}
                    </button>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="InnerTableContainer">
            <table
              id="categoryTable"
              className="order-table"
              {...getTableProps()}
            >
              <thead className="Category-grid-container-item2-tableHead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.Header !== "Action"
                            ? column.getSortByToggleProps()
                            : {}
                        )}
                      >
                        {" "}
                        <div className="header-cell">
                          <span>{column.render("Header")}</span>
                          {column.Header !== "Action" ? (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FontAwesomeIcon
                                    className="SortingIcons"
                                    icon={faSortDown}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="SortingIcons"
                                    icon={faSortUp}
                                  />
                                )
                              ) : (
                                <FontAwesomeIcon
                                  className="SortingIcons"
                                  icon={faSort}
                                />
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {buyerOrderDetails && buyerOrderDetails.length > 0 ? (
                  page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="TableBodyData" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td
                              className="showelement"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={headerGroups[0].headers.length}>
                          <div className="nomatchfound">
                            Currently No Customer Orders available{" "}
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                ) : (
                  <>
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">
                          Currently No Customer Orders available{" "}
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"Prev"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {"Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersDetails;
