import React, { useEffect, useMemo, useState, useContext } from "react";
import {
  faPenToSquare,
  faTrashCan,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import { useTable, useSortBy, usePagination } from "react-table";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import "./inhouse-product.css";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import LoaderButton from "../../../Components/LoaderButton";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import placeHolder from "../../../Assets/image-placeholder.png";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const InHouseProductList = () => {
  const { showToast } = Toast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let currentStatus = queryParams.get("status");
  const filterStatus = currentStatus ? currentStatus : "All";

  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const [totalCount, setTotalCount] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [deletingItemName, setDeletingItemName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [productStatus, setProductStatus] = useState("All");
  const [productCount, setProductCount] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState(1);
  const [pagination, setPagination] = useState("");
  const joyRejoyPerProducts = 10;
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const openProductDetails = (slug, action) => {
    contextObj.setInput("currentPageis", currentPage);
    if (action === "view") {
      navigate(`${Navigation.adminProducts}/${slug}`);
    } else if (action === "edit") {
      navigate(`${Navigation.adminEditProduct}/${slug}`);
    }
  };

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    if (filterStatus !== "" && filterStatus !== "All") {
      data = data + `&status=${filterStatus}`;
    }
    if (searchQuery !== "") {
      data = data + "&search=" + searchQuery;
    }
    data = data + `&per_page=${joyRejoyPerProducts}`;
    getAllInHouseProducts(data);
  };

  useEffect(() => {
    setSearchLoading(true);
    const debounceTimeout = setTimeout(() => {
      let url = `${API_HOST}/${APIEndpoint.crudInHouseProducts}?&per_page=${joyRejoyPerProducts}`;
      if (filterStatus !== "" && filterStatus !== "All") {
        url = url + `&status=${filterStatus}`;
      }
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }
      getAllInHouseProducts(url);
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchQuery]);
  const openDeleteModal = (id, name) => {
    setDeletingItemId(id);
    setDeletingItemName(name);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeletingItemId("");
    setDeletingItemName("");
    setShowDeleteModal(false);
  };

  const getAllInHouseProducts = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setSearchLoading(false);
        if (res.status === 200) {
          setData(res.data.products.data);
          setProductCount(res.data.count);
          setPagination(res.data.products);
          setCurrentPage(res.data.products.current_page);
          setLastPage(res.data.products.last_page);
          setTotalCount(res.data.products.total);
        } else if (res.status === 404) {
          setData([]);
          setPagination("");
          setProductCount("");
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        setSearchLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (contextObj.inputValue.adminProductStatus !== filterStatus) {
      contextObj.setInput("adminProductStatus", filterStatus);
    }
    currentPage = 1;
    lastPage = 1;
    setPagination("");
    setProductCount([]);
    setProductStatus(filterStatus);
    setSearchQuery("");
    const isNavigatefromProducts = contextObj.inputValue.isNavigateFromProducts;
    let URL = `${API_HOST}/${APIEndpoint.crudInHouseProducts}`;
    if (isNavigatefromProducts) {
      const currentNewPage =
        contextObj.inputValue.currentPageis !== null
          ? contextObj.inputValue.currentPageis
          : 1;

      URL = URL + `?page_no=${currentNewPage ? currentNewPage : 1}`;
      contextObj.setInput("isNavigateFromProducts", false);
    } else {
      URL = URL + `?page_no=${currentPage}`;
    }
    if (filterStatus !== "" && filterStatus !== "All") {
      URL = URL + `&status=${filterStatus}`;
    }
    getAllInHouseProducts(URL);
  }, [
    contextObj.inputValue.activeAdminProductsFilter,
    contextObj.inputValue.adminProductStatus,
  ]);

  const handleConfirmDelete = async () => {
    setLoadingButton(true);
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudInHouseProducts}/${deletingItemId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      showToast("success", "Product deleted successfully");

      closeDeleteModal();
      setData(data.filter((product) => product.id !== deletingItemId));
    } else if (
      response.status === 401 &&
      response.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (response.status == 401 && response.message !== "") {
      showToast("error", response.message);
    } else {
      showToast("error", "Something went wrong");
    }
    setLoadingButton(false);
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * joyRejoyPerProducts,
      },
      {
        Header: "Image",
        accessor: "thumb_image",
        Cell: ({ row }) => (
          <span>
            <img
              src={imgHost + "/" + row.original.thumb_image}
              alt="Image"
              onError={(e) => {
                e.target.src = placeHolder;
              }}
            />
          </span>
        ),
      },
      {
        Header: "Product",
        Footer: "Product",
        accessor: "name",
        Cell: ({ value }) => (
          <div>
            {" "}
            {value.length > 60 ? `${value.substring(0, 60)}...` : value}
          </div>
        ),
      },
      {
        Header: "Selling Price",
        Footer: "Selling Price",
        accessor: "selling_price",
        Cell: ({ value }) => (
          <>{value > 0 && <div>₹{formatNumberWithCommas(value)}</div>}</>
        ),
      },
      {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          let text;
          if (value === "Verified and Published") {
            text = "Published";
            color = "white";
            backgroundColor = "#4CAF50";
          } else if (value === "Hold") {
            text = "Hold";
            backgroundColor = "#FFA500";
            color = "white";
          } else if (value === "Sold") {
            text = "Sold";
            backgroundColor = "#2196F3";
            color = "white";
          } else if (value === "Draft") {
            text = "Draft";
            backgroundColor = "#c5c5c5";
            color = "#000000";
          } else if (value === "Donated") {
            text = "Donated";
            backgroundColor = "#9C27B0";
            color = "white";
          } else {
            text = value;
            backgroundColor = "#c5c5c5";
            color = "#4f4f4f";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {text}
            </p>
          );
        },
      },
      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          const status = row.original.status;
          const slug = row.original.slug;

          const readOnly =
            status === "Hold" ||
            status == "Sold" ||
            status == "Donated" ||
            status == "Blocked" ||
            status === "Unavailable";

          const editable =
            status === "Draft" || status === "Verified and Published";

          const deletable =
            status == "Draft" ||
            status === "Verified and Published" ||
            status === "Sold" ||
            status == "Donated" ||
            status === "Blocked" ||
            status === "Unavailable";

          return (
            <>
              {readOnly && (
                <button
                  type="button"
                  onClick={() => openProductDetails(slug, "view")}
                  className="action-icon eye-button"
                  title="View"
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              )}
              {editable && (
                <button
                  type="button"
                  onClick={() => openProductDetails(slug, "edit")}
                  className="action-icon edit-button"
                  title="Edit"
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
              )}
              {deletable && (
                <button
                  type="button"
                  onClick={() => openDeleteModal(id, name)}
                  className="action-icon trash-button"
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              )}
            </>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },

    useSortBy,
    usePagination
  );
  let getCurrentSortBy = sessionStorage.getItem("inHouseProductsSortedState");
  const { globalFilter, pageIndex, pageSize } = state;
  useEffect(() => {
    if (getCurrentSortBy != null)
      sessionStorage.setItem(
        "inHouseProductsSortedState",
        JSON.stringify(getCurrentSortBy)
      );
  }, [state.sortBy]);

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">
            {contextObj.inputValue.adminProductStatus} Products
          </li>
        </div>
      </div>
      <div className="page-container">
        {/* Product Counter */}
        <div
          className="data-counter"
          style={{
            display: productStatus !== "All" ? "none" : "",
          }}
        >
          <div className="counter-item">
            <p>All Products</p>
            <p>
              {productCount.all_admin_products &&
                productCount.all_admin_products}
            </p>
          </div>
          <div className="counter-item">
            <p>Published Products</p>
            <p>
              {productCount.published_products &&
                productCount.published_products}
            </p>
          </div>
          <div className="counter-item">
            <p>Draft Products</p>
            <p>{productCount.draft_products && productCount.draft_products}</p>
          </div>
          <div className="counter-item">
            <p>Hold Products</p>
            <p>{productCount.hold_products && productCount.hold_products}</p>
          </div>
          <div className="counter-item">
            <p>Sold Products</p>
            <p>{productCount.sold_products && productCount.sold_products}</p>
          </div>
        </div>

        {/* Filter and Button */}
        <div className="table-serach-area">
          <div className="search-bar-container">
            <div className="search-bar">
              <div className="fontAwsomeDiv">
                {searchLoading ? (
                  <LoaderButton />
                ) : (
                  <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
                )}
              </div>
              <input
                placeholder="Search..."
                className="search-input"
                name="search-query"
                value={searchQuery}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.target.value;
                  setSearchQuery(val);
                }}
              />
            </div>
          </div>
          <button
            type="button"
            className="primary-btn"
            onClick={() => navigate(`${Navigation.adminCreateProduct}`)}
          >
            New Product
          </button>
        </div>

        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      style={{
                        width: column.width,
                      }}
                      {...column.getHeaderProps(
                        column.Header === ""
                          ? {}
                          : column.getSortByToggleProps()
                      )}
                    >
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            {/* table body logic where data appends */}
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={
                contextObj.inputValue.activeAdminProductsFilter + " Products"
              }
            />
          )}
        </div>
      </div>

      {/* Delete modal */}
      {showDeleteModal && (
        <div
          id="deleteModal"
          className="modal fade"
          style={{ display: showDeleteModal ? "block" : "none" }}
        >
          <div className="modal-content">
            <h3 className="modal-header">Delete Confirmation</h3>
            <span className="modal-close" onClick={closeDeleteModal}>
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <p className="modal-line">
              Are you sure you want to delete{" "}
              <span className="deleteitemsNameappPages">
                {deletingItemName}
              </span>{" "}
              item?
            </p>
            <div className="modal-buttons">
              <button
                type="button"
                className="btn primary-btn"
                onClick={() => {
                  handleConfirmDelete();
                }}
              >
                {loadingButton ? <LoaderButton /> : "Delete"}
              </button>
              <button
                type="button"
                className="btn secondary-btn"
                onClick={closeDeleteModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InHouseProductList;
