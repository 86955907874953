import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import Navigate from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import "./payment.css";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import Navigation from "../../../config/Navigation.json";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerPayments = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const { showToast } = Toast();
  let [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [generateButton, setGenerateButton] = useState(true);
  const navigate = new useNavigate();
  const [dateModal, setDateModal] = useState(false);
  const [dateOfTransaction, setDateOfTransaction] = useState("");
  const [dateOfTransactionError, setDateOfTransactionError] = useState("");
  const PaymentDataPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getPaymentList(data);
  };
  const [isSearchApplied, setIsSearchApplied] = useState(false);

  // get all payments
  const getPaymentList = async (url) => {
    setLoading(true);
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }

    try {
      const response = await fetch(`${url}&per_page=${PaymentDataPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();

      if (res.status === 200) {
        setData(res.data.data);
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        navigate(Navigate.login);
        showToast("info", "Sessions expired!");
      } else if (res.status === 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setData([]);
        setPagination("");
      } else {
        if (res.message) console.error(res.message);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showToast("error", "An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const isFromPaymentDetails = getDataFromCookies("isFromPaymentPage");
    const CurrentPageFromCookies = getDataFromCookies(
      "CurrentPageFromCookiesis"
    );
    if (isFromPaymentDetails) {
      getPaymentList(
        `${API_HOST}/api/admin/payments?page_no=${CurrentPageFromCookies}`
      );
      removeDataFromCookie("isFromPaymentPage");
      removeDataFromCookie("CurrentPageFromCookiesis");
    } else {
      getPaymentList(`${API_HOST}/api/admin/payments?page_no=1`);
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  // Set Calender Date
  const handleDateChange = (e) => {
    setDateOfTransactionError("");
    setDateOfTransaction(e.target.value);
  };

  // generate payments on files
  const generatePayments = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    let error = false;
    if (dateOfTransaction === "" || dateOfTransaction === null) {
      setDateOfTransactionError("This field is required");
      error = true;
    }
    if (error === true) return;

    const date = formatDate(dateOfTransaction);
    const payload = {
      date_of_transaction: date,
    };

    const response = await fetch(
      `${API_HOST}/api/admin/generate-adhoc-beneficiaries`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    if (!response.ok) {
      const res = await response.json();
      showToast("error", res.message);
    }

    // download file
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "payments.txt";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      showToast("success", "File downloaded successfully");
      setGenerateButton(true);
      getPaymentList(`${API_HOST}/api/admin/payments?page_no=1`);
    }

    setDateModal(false);
    setDateOfTransaction("");
  };

  const handleviewCustomer = (id) => {
    setDataToCookies("CurrentPageFromCookiesis", currentPage);
    navigate(`/payment-details/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * PaymentDataPerPage, // Adding 1 to get a 1-based serial number
      },
      {
        Header: "Order ID",
        accessor: "order_id",
      },
      {
        Header: "Image",
        accessor: "product_details.thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${imgHost}/${value}`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Product",
        accessor: "product_details.name",
        cell: ({ value }) => (
          <div id="sellers-productName-headingDiv" title={value}>
            {value}
          </div>
        ),
      },

      {
        Header: "Timestamp",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(value);
          const options = {
            year: "numeric",
            month: "long", // Use 'short' for abbreviated month names
            day: "numeric",
          };
          const formattedDate = date.toLocaleString(undefined, options);
          const formattedTime = date.toLocaleTimeString();

          return (
            <div id="sellers-orderTimestamp">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          );
        },
      },
      {
        Header: "Payable Amount",
        accessor: "payable_amount",
        Cell: ({ value }) => (
          <div id="sellers-productSellingPrice" className="cell-container">
            ₹ {value}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <p className="order-status" style={{ backgroundColor: "#ffe6bc" }}>
            {value === "Initiated" && setGenerateButton(false)}
            {value}
          </p>
        ),
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ value }) => (
          <span>
            <FontAwesomeIcon
              className="action-icon edit-action"
              icon={faEye}
              onClick={() => handleviewCustomer(value)}
            />
          </span>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        data = [];
        currentPage = 1;
        let url = `${API_HOST}/api/admin/payments?page_no=${currentPage}`;
        if (searchQuery !== "") {
          getPaymentList(url);
        } else {
          getPaymentList(url);
        }
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Seller Payments</li>
        </div>
      </div>

      <div className="page-container">
        {/* Filter and Button */}
        <div className="table-serach-area">
          <button
            className="primary-btn"
            disabled={generateButton}
            type="button"
            onClick={() => {
              setDateModal(true);
            }}
            style={{
              opacity: !generateButton ? 1 : 0.5,
              cursor: !generateButton ? "pointer" : "not-allowed",
            }}
          >
            Download File
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>

        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.Header !== "Action" && column.Header !== "Image"
                          ? {}
                          : {}
                      )}
                    >
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* table body logic where data appends */}
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Payments"}
            />
          )}
        </div>
      </div>

      {/* Date modal */}
      {dateModal && (
        <div className="Payment_Calender_Main_Container_modal">
          <div className="payment_cal_c2_modal">
            <div>
              <div className="payment_icon_container">
                <FontAwesomeIcon
                  onClick={() => {
                    setDateModal(false);
                    setDateOfTransaction("");
                    setDateOfTransactionError("");
                  }}
                  icon={faX}
                  style={{ color: "#9E9E9E", fontSize: "14px" }}
                />
              </div>
              <h1 className="Payment_Select_Date_heading">Select a date</h1>
            </div>
            <div>
              <label className="ForgotPassword_Joyrejoy_lable_tag">
                <input
                  type="date"
                  name="date_of_transaction"
                  className="ForgotPassword_Joyrejoy_input_tag"
                  value={dateOfTransaction}
                  onChange={handleDateChange}
                  placeholder="Date"
                  min={new Date().toISOString().split("T")[0]}
                />
                <span className="ForgotPassword_Joyrejoy_span_tag">Date</span>
              </label>
              <li className="errorCategoryEdit">{dateOfTransactionError}</li>
            </div>
            <div className="Seller_payment_container">
              <span className="span_text_note">
                Note: Please note that this date indicates when would like to
                initiate the payment transaction of the current list of pending
                sellers.{" "}
              </span>
            </div>
            <button
              className="Seller_Payment_Done_Button"
              onClick={generatePayments}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerPayments;
