import React, { useEffect, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import SellerTopNavigation from "./SellerNavigation";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import loadingImgPath from "../../../Assets/loading.gif";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import "./css/accounts.css";
import Toast from "../../../Components/Toast";
import validator from "validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerAccount = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const { sellerId } = useParams();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [isChecked, setIsChecked] = useState(false);
  const [incomPayChecked, setIncomPayChecked] = useState(true);
  const [sellerDetails, setSellerDetails] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    image: null,
    street_address: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    upi_id: "",
    bank_name: "",
    branch_name: "",
    ifsc_code: "",
    account_holder_name: "",
    account_number: "",
    status: "",
    is_owner: "",
    shop_name: "",
    business_contact_phone: "",
    business_contact_email: "",
    business_type: "",
    business_address: "",
    incomplete_payment: "",
  });
  const [shopNameError, setShopNameError] = useState("");
  const [businessContactEmailError, setBusinessContactEmailError] =
    useState("");
  const [businessContactPhoneError, setBusinessContactPhoneError] =
    useState("");
  const [businessTypeError, setBusinessTypeError] = useState("");
  const [gstNumberError, setGstNumberError] = useState("");
  const [businessAddressError, setBusinessAddressError] = useState("");
  const [BankNameList, setBankNameList] = useState([]);
  const [LoadingButton, setLoadingButton] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [upiIdError, setUpiIdError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderNameError, setAccountHolderNameError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincodeLoading, setPincodeLoading] = useState(false);

  // get city and state
  const getCityAndState = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res[0].Status === "Error") {
      setPincodeLoading(false);
      setPincodeError("Apologies! We're unable to reach this address.");
    } else if (res[0].Status === "Success") {
      setPincodeLoading(false);
      setPincodeError("");
      const cityName = res[0].PostOffice[0].District;
      setSellerDetails((prevData) => ({
        ...prevData,
        city: cityName,
      }));
      const stateName = res[0].PostOffice[0].State;
      setSellerDetails((prevData) => ({
        ...prevData,
        state: stateName,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked, unChecked } = event.target;
    if (type === "checkbox") {
      // Handle checkbox input
      if (checked === true) {
        setSellerDetails((prevData) => ({
          ...prevData,
          isBlocked: checked,
        }));
        setIsBlocked(true);
      } else {
        setSellerDetails((prevData) => ({
          ...prevData,
          isBlocked: unChecked,
        }));
        setIsBlocked(false);
      }
    } else if (name === "phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "image") {
      const file = event.target.files[0];
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (name === "ifsc_code") {
      const upperCaseValue = value.toUpperCase();
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: upperCaseValue,
      }));
    } else if (name === "account_number") {
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 34); // Limit to a maximum of 34 digits

      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "pincode") {
      setPincodeError("");
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 6); // Limit to a maximum of 6 digits
      if (limitedValue.length === 6) {
        setPincodeLoading(true);
        getCityAndState(limitedValue); //fetch the city and state using pincode
      }

      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "city") {
      setCityError("");
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "state") {
      setStateError("");
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "street_address") {
      setStreetAddressError("");
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (name === "business_contact_phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
      setBusinessContactPhoneError("");
    } else if (name === "business_contact_email") {
      if (value.trim() !== "" || value == "") {
        setSellerDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      setBusinessContactEmailError("");
    } else if (name === "gst_number") {
      if (value.trim() !== "" || value == "") {
        setSellerDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setGstNumberError("");
      }
    } else if (name === "shop_name") {
      if (value.trim() !== "" || value == "") {
        setSellerDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setShopNameError("");
      }
    } else if (name === "business_address") {
      if (value.trim() !== "" || value == "") {
        setSellerDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setBusinessAddressError("");
      }
    } else if (name === "business_type") {
      if (value.trim() !== "" || value == "") {
        setSellerDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setBusinessTypeError("");
      }
    } else {
      setSellerDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setPaymentError("");
    setBranchNameError("");
    setIfscCodeError("");
  };

  // Get Seller Details
  const getSellerProfile = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudSeller}/${sellerId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setSellerDetails(res.data);
      if (res.data.is_owner == 1) setIsChecked(true);
      if (res.data.status === 1) setIsUserVerified(true);
      if (res.data.status === 2) setIsBlocked(true);
    }
    if (res.status == 401 && res.message == "You are not authorized.") {
      contextObj.setInput("prevPath", location.pathname);
      navigate("/");
    }
  };

  useEffect(() => {
    GetBankListName();
    getSellerProfile();
  }, []);

  // Verify seller Api
  const verifiedSeller = async () => {
    let errorHas = false;

    if (sellerDetails.f_name === "" || sellerDetails.f_name === null) {
      setFirstNameError("This field is required");
      errorHas = true;
    } else if (sellerDetails.f_name.length > 50) {
      setFirstNameError("Max 50 characters");
      errorHas = true;
    } else {
      setFirstNameError("");
    }

    if (sellerDetails.l_name === "" || sellerDetails.l_name === null) {
      setLastNameError("This field is required");
      errorHas = true;
    } else if (sellerDetails.l_name.length > 50) {
      setFirstNameError("Max 50 characters");
      errorHas = true;
    } else {
      setLastNameError("");
    }

    if (
      sellerDetails.street_address === "" ||
      sellerDetails.street_address === null
    ) {
      setStreetAddressError("This field is required");
      errorHas = true;
    } else if (
      sellerDetails.street_address.length < 3 ||
      sellerDetails.street_address.length > 150
    ) {
      setStreetAddressError("Limit between 3 to 150 characters");
      errorHas = true;
    } else {
      setStreetAddressError("");
    }

    //Pincode validation
    if (sellerDetails.pincode === "") {
      setPincodeError("This field is required");
      errorHas = true;
    } else {
      setPincodeError("");
    }

    //City validation
    if (sellerDetails.city === "" || sellerDetails.city === null) {
      setCityError("This field is required");
      errorHas = true;
    } else if (
      sellerDetails.city.length < 0 ||
      sellerDetails.city.length > 50
    ) {
      setCityError("Limit between 3 to 50 characters");
      errorHas = true;
    } else {
      setCityError("");
    }

    //State validation
    if (sellerDetails.state === "") {
      setStateError("This field is required");
      errorHas = true;
    } else {
      setStateError("");
    }

    // Payment info validation
    setUpiIdError("");

    // Branch Name validation
    if (
      sellerDetails.branch_name &&
      (sellerDetails.branch_name.length > 100 ||
        sellerDetails.branch_name.length < 3)
    ) {
      setBranchNameError("Limit between 3 to 100 characters");
      errorHas = true;
    } else {
      setBranchNameError("");
    }

    // IFSC Code validation
    const ifscRegex = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/; // first 4 digits letter and 7 digits is number
    const isIFSC_code_test = ifscRegex.test(sellerDetails.ifsc_code);

    if (sellerDetails.ifsc_code && sellerDetails.ifsc_code.length !== 11) {
      setIfscCodeError("Limit 11 characters only");
      errorHas = true;
    } else if (sellerDetails.ifsc_code && !isIFSC_code_test) {
      setIfscCodeError("Invalid format");
      errorHas = true;
    } else {
      setIfscCodeError("");
    }

    // Account holder name
    if (
      sellerDetails.account_holder_name &&
      (sellerDetails.account_holder_name.length < 3 ||
        sellerDetails.branch_name.length > 200)
    ) {
      setAccountHolderNameError("Limit between 3 to 200 characters");
      errorHas = true;
    } else {
      setAccountHolderNameError("");
    }

    // Account Number
    const accountNumberRegex = /^[0-9\s]+$/;
    const isAccountNumber = accountNumberRegex.test(
      sellerDetails.account_number
    );
    if (sellerDetails.account_number && !isAccountNumber) {
      setAccountNumber("Invalid format");
      errorHas = true;
    } else if (
      sellerDetails.account_number &&
      (sellerDetails.account_number.length < 6 ||
        sellerDetails.account_number.length > 34)
    ) {
      setAccountNumber("Limit between 6 to 34 digits");
      errorHas = true;
    } else {
      setAccountNumber("");
    }

    // Business details validation
    if (sellerDetails.is_owner == 1) {
      const isPhoneNumber = (phone) => {
        let regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
        return regex;
      };
      const isValidGSTNumber = (num) => {
        let regex = /^[a-zA-Z0-9]+$/.test(num);
        return regex;
      };
      const isValidEmail = (newEmail) => {
        return validator.isEmail(newEmail);
      };

      if (sellerDetails.shop_name === "" || sellerDetails.shop_name === null) {
        setShopNameError("Shop Name is required.");
        errorHas = true;
      }

      if (
        sellerDetails.business_contact_email === "" ||
        sellerDetails.business_contact_email === null
      ) {
        setBusinessContactEmailError("Business Email is required.");
        errorHas = true;
      } else if (!isValidEmail(sellerDetails.business_contact_email)) {
        setBusinessContactEmailError("Please enter a valid Business Email.");
        errorHas = true;
      }

      if (
        sellerDetails.business_contact_phone === "" ||
        sellerDetails.business_contact_phone === null
      ) {
        setBusinessContactPhoneError("Business Phone is required.");
        errorHas = true;
      } else if (!isPhoneNumber(sellerDetails.business_contact_phone)) {
        setBusinessContactPhoneError(
          "Please enter a valid Business Phone number."
        );
        errorHas = true;
      }
      if (
        sellerDetails.gst_number === "" ||
        sellerDetails.gst_number === null
      ) {
        setGstNumberError("PAN/Tax ID (GST Number) is required.");
        errorHas = true;
      } else if (!isValidGSTNumber(sellerDetails.gst_number)) {
        setGstNumberError("Only letters and numbers are allowed.");
        errorHas = true;
      }

      if (
        sellerDetails.business_address === "" ||
        sellerDetails.business_address === null
      ) {
        setBusinessAddressError("Business Address is required.");
        errorHas = true;
      }

      if (
        sellerDetails.business_type === "" ||
        sellerDetails.business_type === null
      ) {
        setBusinessTypeError("Business Type is required.");
        errorHas = true;
      }
    }
    if (errorHas === true) {
      setLoadingButton(false);
      return;
    }

    const formData = new FormData();
    if (sellerDetails.f_name !== null) {
      formData.append("f_name", sellerDetails.f_name);
    }
    if (sellerDetails.l_name !== null) {
      formData.append("l_name", sellerDetails.l_name);
    }
    if (
      sellerDetails.image !== null &&
      sellerDetails.image === "users/def.png"
    ) {
      formData.append("image", sellerDetails.image);
    }
    if (sellerDetails.street_address !== null) {
      formData.append("street_address", sellerDetails.street_address);
    }
    if (sellerDetails.pincode !== null) {
      formData.append("pincode", sellerDetails.pincode);
    }
    if (sellerDetails.city !== null) {
      formData.append("city", sellerDetails.city);
    }
    if (sellerDetails.country !== null) {
      formData.append("country", sellerDetails.country);
    }
    if (sellerDetails.state !== null) {
      formData.append("state", sellerDetails.state);
    }
    if (sellerDetails.upi_id) {
      formData.append("upi_id", sellerDetails.upi_id);
    }
    if (sellerDetails.bank_name) {
      formData.append("bank_name", sellerDetails.bank_name);
    }
    if (sellerDetails.branch_name) {
      formData.append("branch_name", sellerDetails.branch_name);
    }
    if (sellerDetails.ifsc_code) {
      formData.append("ifsc_code", sellerDetails.ifsc_code);
    }
    if (sellerDetails.account_holder_name) {
      formData.append("account_holder_name", sellerDetails.account_holder_name);
    }
    if (sellerDetails.account_number) {
      formData.append("account_number", sellerDetails.account_number);
    }
    formData.append(
      "incomplete_payment",
      sellerDetails.incomplete_payment &&
        ((sellerDetails.bank_name &&
          sellerDetails.branch_name &&
          sellerDetails.ifsc_code &&
          sellerDetails.account_holder_name &&
          sellerDetails.account_number) ||
          sellerDetails.upi_id)
        ? 1
        : 0
    );
    formData.append("email", sellerDetails.email);
    formData.append("phone", sellerDetails.phone);
    formData.append("is_owner", sellerDetails.is_owner);
    if (sellerDetails.is_owner === 1) {
      formData.append("shop_name", sellerDetails.shop_name);
      formData.append(
        "business_contact_email",
        sellerDetails.business_contact_email
      );
      formData.append(
        "business_contact_phone",
        sellerDetails.business_contact_phone
      );
      formData.append("gst_number", sellerDetails.gst_number);
      formData.append("business_address", sellerDetails.business_address);
      formData.append("business_type", sellerDetails.business_type);
    }
    // set profile status
    if (isBlocked) formData.append("status", 0);
    else if (!isBlocked) formData.append("status", 1);

    formData.append("_method", "PUT");

    setLoadingButton(true);
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.crudSeller}/${sellerId}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", res.message);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if ((res.status == 401 || res.status == 400) && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status == 400 && res.errors) {
      let errors = res.errors;
      if (errors.phone) {
        setPhoneError(errors.phone[0]);
      }
    }
    setLoadingButton(false);
    getSellerProfile();
  };

  // Block / UnBlock Seller Api Integration
  const handleBlockUnBlockSeller = async (block) => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (block === true) {
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.unblockSeller}/${sellerId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();
      if (res.status === 200) {
        showToast("success", res.message);
        setIsBlocked(false);
      } else {
        showToast("error", res.message);
        setIsBlocked(true);
      }
    } else {
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.blockSeller}/${sellerId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response.json();
      if (res.status === 200) {
        showToast("success", res.message);
        setIsBlocked(true);
      } else {
        showToast("error", res.message);
        setIsBlocked(false);
      }
    }
    getSellerProfile();
  };

  const GetBankListName = async () => {
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.GetListOfAllBank}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setBankNameList(res.data);
    }
  };
  return (
    <div className="page-data">
      <SellerTopNavigation />
      <div className="page-container">
        {/* Data Counter */}
        <div className="data-counter">
          <div className="counter-item">
            <p>Total Earnings</p>
            <p>
              {sellerDetails.total_earnings &&
                `₹${sellerDetails.total_earnings}`}
            </p>
          </div>
          <div className="counter-item">
            <p>Pending Order amount </p>
            <p>
              {sellerDetails.pending_order_amount &&
                `₹${sellerDetails.pending_order_amount}`}
            </p>
          </div>
          <div className="counter-item">
            <p>Delivery Charge Given</p>
            <p>
              {sellerDetails.delivery_charge_given &&
                `₹${sellerDetails.delivery_charge_given}`}
            </p>
          </div>
          {/* <div className="counter-item">
            <p>Commission Given</p>
            <p>{sellerDetails.commission_given}</p>
            <p>
              {sellerDetails.commission_given &&
                `₹${sellerDetails.commission_given}`}
            </p>
          </div> */}
        </div>

        <div>
          <div className="seller-profile-container">
            <div className="AccountPageSecondInnerDiv">
              <div className="profile-box ribbon-box">
                <span
                  className="verified-ribbon"
                  style={{
                    backgroundColor:
                      sellerDetails.status === 0
                        ? "#f5ae49"
                        : sellerDetails.status === 1
                        ? "#4CAF50"
                        : sellerDetails.status === 2
                        ? "#F44336"
                        : sellerDetails.status === 3
                        ? "#9E9E9E"
                        : null,
                  }}
                >
                  {sellerDetails.status === 0
                    ? "Unverified"
                    : sellerDetails.status === 1
                    ? "Verified"
                    : sellerDetails.status === 2
                    ? "Blocked"
                    : sellerDetails.status === 3
                    ? "Deactivated"
                    : ""}
                </span>
                <h3
                  className="box-heading"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  Profile Information
                </h3>
                <div className="profilecontainer">
                  <div className="profiledetialgrid">
                    <div>
                      <label className="labelinAccountPage">
                        <input
                          className="sellerVerifyInputTage"
                          value={sellerDetails.f_name}
                          name="f_name"
                          onChange={handleInputChange}
                          placeholder="First Name"
                          maxLength={50}
                        />
                        <span className="accountPage_Span_Tag">First Name</span>
                      </label>
                      <label className="account-error">{firstNameError}</label>
                    </div>
                    <div>
                      <label className="labelinAccountPage">
                        <input
                          className="sellerVerifyInputTage"
                          name="l_name"
                          value={sellerDetails.l_name}
                          onChange={handleInputChange}
                          placeholder="Last Name"
                        />
                        <span className="accountPage_Span_Tag">Last Name</span>
                      </label>
                      <label className="account-error">{lastNameError}</label>
                    </div>
                    <div>
                      <label className="labelinAccountPage">
                        <div style={{ opacity: 0.5 }}>
                          <input
                            className="sellerVerifyInputTage"
                            value={sellerDetails.email}
                            name="email"
                            readOnly
                            disabled
                            onChange={handleInputChange}
                            placeholder="Email"
                          />
                          <span className="accountPage_Span_Tag">Email</span>
                        </div>
                      </label>
                    </div>
                    <div className="Sellerphone">
                      <label className="labelinAccountPage">
                        <div style={{ opacity: 0.5 }}>
                          <input
                            className="sellerVerifyInputTage"
                            name="phone"
                            readOnly
                            disabled
                            value={sellerDetails.phone}
                            onChange={handleInputChange}
                            maxLength={10}
                            placeholder="Phone"
                          />
                          <span className="accountPage_Span_Tag">Phone</span>
                        </div>
                      </label>
                      <label className="account-error">{phoneError}</label>
                    </div>
                    <div className="BlockUnblockChcekBox">
                      <label>
                        <input
                          type="checkbox"
                          checked={isBlocked ? true : false}
                          onChange={handleInputChange}
                          onClick={() => handleBlockUnBlockSeller(isBlocked)}
                        />{" "}
                        {isBlocked ? "Seller Blocked" : "Block Seller?"}{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-box">
              <h3 className="box-heading">Pickup Address</h3>
              <div className="address-detail-div">
                <div>
                  <label className="labelinAccountPage profile-pincode-label">
                    <input
                      className="sellerVerifyInputTage"
                      name="pincode"
                      value={sellerDetails.pincode}
                      maxLength={6}
                      minLength={6}
                      onChange={handleInputChange}
                      placeholder="Pincode"
                    />
                    {pincodeLoading && (
                      <img
                        src={loadingImgPath}
                        className="pincode-loading-image"
                      />
                    )}
                    <span className="accountPage_Span_Tag">Pincode</span>
                  </label>
                  <label className="account-error">{pincodeError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="city"
                      value={sellerDetails.city}
                      onChange={handleInputChange}
                      placeholder="City"
                    />
                    <span className="accountPage_Span_Tag">City</span>
                  </label>
                  <label className="account-error">{cityError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="country"
                      value={sellerDetails.country}
                      onChange={handleInputChange}
                      readOnly
                      disabled
                      placeholder="Country"
                    />
                    <span className="accountPage_Span_Tag">Country</span>
                  </label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <select
                      name="state"
                      className="sellerVerifyInputTage"
                      value={sellerDetails.state}
                      placeholder="State"
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="Andaman & Nicobar Islands">
                        Andaman & Nicobar Islands
                      </option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadra and Nagar Haveli">
                        Dadra & Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman & Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">Jammu & Kashmir</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Ladakh">Ladakh</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    <span className="accountPage_Span_Tag">State</span>
                  </label>
                  <label className="account-error">{stateError}</label>
                </div>
                <div className="address-seller">
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage addressSectionAccountPage"
                      name="street_address"
                      value={sellerDetails.street_address}
                      onChange={handleInputChange}
                      placeholder="Address"
                    />
                    <span className="accountPage_Span_Tag"> Address</span>
                  </label>{" "}
                  <label className="account-error">{streetAddressError}</label>
                </div>
              </div>
            </div>

            <div className="profile-box">
              <h3 className="box-heading">Business Details</h3>
              <div className="isChecked-shopOnwer-div">
                <input type="checkbox" id="myCheckbox" checked={isChecked} />
                <label className="isShop-owner-lable" htmlFor="myCheckbox">
                  Seller owns a shop/company?
                </label>
              </div>
              <div className="address-detail-div">
                <div>
                  <label className="labelinAccountPage profile-pincode-label">
                    <input
                      className="sellerVerifyInputTage"
                      name="shop_name"
                      value={sellerDetails.shop_name}
                      onChange={handleInputChange}
                      placeholder="Shop Name"
                      disabled={!isChecked}
                      readOnly={!isChecked}
                    />
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      Shop Name{" "}
                    </span>
                  </label>
                  <label className="account-error">{shopNameError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="business_contact_email"
                      value={sellerDetails.business_contact_email}
                      onChange={handleInputChange}
                      placeholder="Business Email"
                      disabled={!isChecked}
                      readOnly={!isChecked}
                    />
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      Business Email
                    </span>
                  </label>
                  <label className="account-error">
                    {businessContactEmailError}
                  </label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="business_contact_phone"
                      value={sellerDetails.business_contact_phone}
                      onChange={handleInputChange}
                      placeholder="Business Phone"
                      disabled={!isChecked}
                      readOnly={!isChecked}
                    />
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      Business Phone
                    </span>
                  </label>
                  <label className="account-error">
                    {businessContactPhoneError}
                  </label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="gst_number"
                      value={sellerDetails.gst_number}
                      onChange={handleInputChange}
                      placeholder="PAN/Tax ID (GST Number)"
                      disabled={!isChecked}
                      readOnly={!isChecked}
                    />
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      PAN/Tax ID (GST Number)
                    </span>
                  </label>
                  <label className="account-error">{gstNumberError}</label>
                </div>
                <div
                //className="address-seller"
                >
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="business_address"
                      value={sellerDetails.business_address}
                      onChange={handleInputChange}
                      placeholder="Business Address"
                      disabled={!isChecked}
                    />
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      {" "}
                      Business Address
                    </span>
                  </label>{" "}
                  <label className="account-error">
                    {businessAddressError}
                  </label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <select
                      name="business_type"
                      className="sellerVerifyInputTage"
                      value={sellerDetails.business_type}
                      placeholder="Business Type"
                      onChange={handleInputChange}
                      disabled={!isChecked}
                    >
                      <option value="">Select</option>
                      <option value="Individual">Individual</option>
                      <option value="Company">Company</option>
                    </select>
                    <span
                      className={`accountPage_Span_Tag ${
                        !isChecked ? "disabled" : ""
                      }`}
                    >
                      Business Type
                    </span>
                  </label>
                  <label className="account-error">{businessTypeError}</label>
                </div>
              </div>
              {sellerDetails.shop_url && sellerDetails.shop_name && (
                <div className="visitSellerShop">
                  <p
                    className="visitSellerShopName"
                    onClick={() => {
                      let shopUrl = `${process.env.REACT_APP_BUYER_DOMAIN}/shop/${sellerDetails.shop_url}`;
                      window.open(shopUrl, "_blank");
                    }}
                  >
                    {" "}
                    {sellerDetails.shop_name}{" "}
                    <span>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="profile-box">
              <h3 className="box-heading">Payment Information</h3>
              <div className="address-detail-div">
                <div className="address-seller">
                  <label className="labelinAccountPage">
                    <select
                      className="sellerVerifyInputTage"
                      onChange={handleInputChange}
                      name="bank_name"
                      value={sellerDetails.bank_name}
                      placeholder="Bank Name"
                    >
                      {" "}
                      <option value="">Select</option>
                      {BankNameList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="accountPage_Span_Tag">Bank Name</span>
                  </label>
                  <label className="account-error">{bankNameError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      type="text"
                      minLength={3}
                      maxLength={100}
                      className="sellerVerifyInputTage"
                      name="branch_name"
                      value={sellerDetails.branch_name}
                      onChange={handleInputChange}
                      placeholder="Branch Name"
                    />
                    <span className="accountPage_Span_Tag">Branch Name</span>
                  </label>
                  <label className="account-error">{branchNameError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="ifsc_code"
                      maxLength={11}
                      value={sellerDetails.ifsc_code}
                      onChange={handleInputChange}
                      placeholder="IFSC Code"
                    />
                    <span className="accountPage_Span_Tag">IFSC Code</span>
                  </label>
                  <label className="account-error">{ifscCodeError}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="account_holder_name"
                      value={sellerDetails.account_holder_name}
                      onChange={handleInputChange}
                      placeholder="Account Holder Name"
                    />
                    <span className="accountPage_Span_Tag">
                      Account Holder Name
                    </span>
                  </label>
                  <label className="account-error">
                    {accountHolderNameError}
                  </label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="account_number"
                      value={sellerDetails.account_number}
                      onChange={handleInputChange}
                      placeholder="Account Number"
                    />
                    <span className="accountPage_Span_Tag">Account Number</span>
                  </label>
                  <label className="account-error">{accountNumber}</label>
                </div>
                <div>
                  <label className="labelinAccountPage">
                    <input
                      className="sellerVerifyInputTage"
                      name="upi_id"
                      value={sellerDetails.upi_id}
                      onChange={handleInputChange}
                      placeholder="UPI Id (Optional)"
                    />
                    <span className="accountPage_Span_Tag">
                      UPI Id (Optional){" "}
                    </span>
                  </label>
                  <label className="account-error">{upiIdError}</label>
                </div>
              </div>
              {sellerDetails.incomplete_payment &&
                ((sellerDetails.bank_name &&
                  sellerDetails.branch_name &&
                  sellerDetails.ifsc_code &&
                  sellerDetails.account_holder_name &&
                  sellerDetails.account_number) ||
                  sellerDetails.upi_id) && (
                  <label className="incomplete-input-line">
                    <input
                      type="checkbox"
                      className="incomplete-checkbox-input"
                      checked={incomPayChecked}
                      onChange={(e) => {
                        const val = e.target.checked;
                        setIncomPayChecked(val);
                      }}
                    />
                    <p>Filled incomplete payments information</p>
                  </label>
                )}
              <li className="account-error">{paymentError}</li>
            </div>
          </div>
          <div className="profile-button-area">
            <button
              type="button"
              className="btn primary-btn"
              onClick={() => {
                verifiedSeller();
              }}
              style={{
                backgroundColor: !isUserVerified && "#f5ae49",
                opacity: LoadingButton ? 0.5 : 1,
              }}
              disabled={LoadingButton}
            >
              {isUserVerified ? "Update Profile" : " Verify & Update"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerAccount;
