import { getDataFromCookies, removeDataFromCookie } from "../Cookie";
import Navigation from "../../config/Navigation.json";

const HOST_API = process.env.REACT_APP_HOST_API_URL;

const Logout = async () => {
  let your_access_token = getDataFromCookies("7b9KpQ3rW6") || null;
  if (your_access_token !== null) {
    let response = await fetch(`${HOST_API}/api/admin/logout`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${your_access_token}`,
      },
    });
    let res = await response.json();
    if (res.status === 200) {
      window.location.href = `${Navigation.login}`;
      removeDataFromCookie("7b9KpQ3rW6");
      removeDataFromCookie("loggedIn");
      removeDataFromCookie("isLoginByAdmin");
    } else {
      console.error(res);
    }
  }

  sessionStorage.removeItem("7b9KpQ3rW6");
  window.location.href = `${Navigation.login}`;
};

export default Logout;
