import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Navigation from "../../../config/Navigation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SellerNavigation = () => {
  const navigate = useNavigate();
  const { sellerId } = useParams();
  const location = useLocation();

  // Extract the selected link from the current route path
  const currentPath = location.pathname;
  const selectedLink = currentPath.split("/")[4];
  const name = sessionStorage.getItem("sellerUserName");

  const handleNavigation = (link) => {
    navigate(`${Navigation.sellerList}/${sellerId}/${link}`);
  };

  return (
    <>
      <div className="page-heading">
        <div className="breadcrumb">
          <FontAwesomeIcon
            onClick={() => {
              window.history.back();
            }}
            icon={faArrowLeft}
            style={{
              fontSize: "16px",
              color: "gray",
              cursor: "pointer",
              marginRight: "10px",
            }}
          />
          <li className="active">{name}</li>
        </div>
      </div>
      <ul className="seller-profile-navigation">
        <li
          className={selectedLink === "account" && "active"}
          onClick={() => handleNavigation("account")}
        >
          Account
        </li>
        <li
          className={selectedLink === "orders" && "active"}
          onClick={() => handleNavigation("orders")}
        >
          Order
        </li>
        <li
          className={selectedLink === "products" && "active"}
          onClick={() => handleNavigation("products")}
        >
          Product
        </li>
        <li
          className={selectedLink === "transaction" && "active"}
          onClick={() => handleNavigation("transaction")}
        >
          Transaction
        </li>
      </ul>
    </>
  );
};

export default SellerNavigation;
