import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "rsuite/dist/rsuite.css";
import { Sidenav, Nav } from "rsuite";
// find all the icons from here https://rsuite.github.io/rsuite-icons
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import ExitIcon from "@rsuite/icons/Exit";
import OrderForm from "@rsuite/icons/legacy/OrderForm";
import HeartO from "@rsuite/icons/legacy/HeartO";
import Money from "@rsuite/icons/legacy/Money";
import ArowBack from "@rsuite/icons/ArowBack";
import Grid from "@rsuite/icons/Grid";
import ThreeColumnsIcon from "@rsuite/icons/ThreeColumns";
import Tag from "@rsuite/icons/Tag";
import List from "@rsuite/icons/List";
import StickyNoteO from "@rsuite/icons/legacy/StickyNoteO";
import UserO from "@rsuite/icons/legacy/UserO";
import Peoples from "@rsuite/icons/legacy/Peoples";
import Admin from "@rsuite/icons/Admin";
import BuildingO from "@rsuite/icons/legacy/BuildingO";
import Image from "@rsuite/icons/Image";
import NewspaperO from "@rsuite/icons/legacy/NewspaperO";
import Review from "@rsuite/icons/Review";
import FileTextO from "@rsuite/icons/legacy/FileTextO";
import ThreeColumns from "@rsuite/icons/ThreeColumns";
import FlagO from "@rsuite/icons/legacy/FlagO";
import Profile from "@rsuite/icons/legacy/Profile";
import Setting from "@rsuite/icons/legacy/Setting";
import Gear from "@rsuite/icons/legacy/Gear";
import Question2 from "@rsuite/icons/legacy/Question2";
import ReviewPassIcon from "@rsuite/icons/ReviewPass";
import Navigation from "../../../config/Navigation.json";
import Logout from "../../Auth/Logout";
import InputContext from "../../InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigate from "../../../config/Navigation.json";
import { getDataFromCookies, removeDataFromCookie } from "../../Cookie";
import "./app-menu.css";
import Toast from "../../Toast";

// Constants
const APP_NAME = process.env.REACT_APP_NAME;
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const AppMenu = ({ toggleClick }) => {
  const { showToast } = Toast();
  const navigate = useNavigate();
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const [orderCount, setOderCount] = useState("");
  const [orderClicked, setOderClicked] = useState(false);
  const [inHouseProductCount, setInHouseProductCount] = useState("");
  const [inHouseProductClicked, setInHouseProductClicked] = useState(false);
  const [sellerProductCount, setSellerProduct] = useState("");
  const [sellerProductClicked, setSellerProductClicked] = useState(false);
  const [sellerCount, setSellerCount] = useState("");
  const [sellerClicked, setSellerClicked] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleToggleClick = () => {
    toggleClick(!openSidebar);
    setOpenSidebar(!openSidebar);
  };

  const handleLogout = async () => {
    await Logout();
  };

  // Handle Menu active
  const isStartWith = (path) => {
    return location.pathname.startsWith(path) ? "active" : "";
  };
  const activeNavMenu = (menu, status) => {
    if (menu === "orders") {
      return (
        location.pathname.startsWith("/orders") &&
        sessionStorage.getItem("orderStatus") === status
      );
    } else if (menu === "adminProducts") {
      return (
        location.pathname.startsWith("/admin/products") &&
        sessionStorage.getItem("adminProductStatus") === status
      );
    } else if (menu === "sellerProducts") {
      return (
        location.pathname.startsWith("/seller/products") &&
        sessionStorage.getItem("sellerProductStatus") === status
      );
    } else if (menu === "sellerList") {
      return (
        location.pathname.startsWith("/user/sellers") &&
        sessionStorage.getItem("sellerListStatus") === status
      );
    } else if (menu === "adminUsers") {
      return (
        (location.pathname.startsWith("/roles") ||
          location.pathname.startsWith("/user/admins")) &&
        sessionStorage.getItem("adminUserMenu") === status
      );
    }
  };

  const getOrderCounts = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    const url = `${API_HOST}/${APIEndpoint.getOrderCount}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setOderCount(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(`${Navigate.login}`);
      showToast("info", "Sessions expired! Please login");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status == 400) {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setOderCount("");
    } else {
      console.error(res);
    }
  };

  const getInHouseProductCounts = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    const url = `${API_HOST}/${APIEndpoint.getInHouseProductCount}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setInHouseProductCount(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(`${Navigate.login}`);
      showToast("info", "Sessions expired! Please login");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status == 400) {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setInHouseProductCount("");
    } else {
      console.error(res);
    }
  };

  const getSellerProductCounts = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    const url = `${API_HOST}/${APIEndpoint.getSellerProductCount}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setSellerProduct(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(`${Navigate.login}`);
      showToast("info", "Sessions expired! Please login");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status == 400) {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setSellerProduct("");
    } else {
      console.error(res);
    }
  };

  const getSellerCounts = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    const url = `${API_HOST}/${APIEndpoint.getSellerCount}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setSellerCount(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(`${Navigate.login}`);
      showToast("info", "Sessions expired! Please login");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status == 400) {
      showToast("error", res.message);
    } else if (res.status === 404) {
      setSellerCount("");
    } else {
      console.error(res);
    }
  };

  // don not add side navbar for the below routes
  if (
    location.pathname == "/" ||
    location.pathname == "/version" ||
    location.pathname == "/forgot-password"
  ) {
    return <></>;
  }

  return (
    <div
      className="side-navbar"
      style={{
        width: openSidebar ? "250px" : "max-content",
        maxWidth: "250px",
      }}
      onMouseEnter={() => {
        setOpenSidebar(true);
        toggleClick(true);
      }}
      onMouseLeave={() => {
        setOpenSidebar(false);
        toggleClick(false);
      }}
    >
      <div className="sidebar-header">
        {openSidebar ? (
          <svg
            onClick={handleToggleClick}
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            width="28"
            viewBox="0 0 384 512"
          >
            <path
              fill="white"
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
          </svg>
        ) : (
          <svg
            onClick={handleToggleClick}
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            width="28"
            viewBox="0 0 448 512"
          >
            <path
              fill="white"
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            />
          </svg>
        )}
      </div>
      <Sidenav expanded={openSidebar}>
        <Sidenav.Body style={{ overflow: openSidebar ? "auto" : "" }}>
          <Nav>
            <Nav.Item
              eventKey="1"
              icon={<DashboardIcon />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.dashboard);
              }}
              active={isStartWith(Navigation.dashboard)}
            >
              Dashboard
            </Nav.Item>
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              Order Management
            </p>
            <Nav.Menu
              placement="rightStart"
              eventKey="2"
              title="Orders"
              icon={<OrderForm />}
              onClick={() => {
                if (!orderClicked) {
                  setOderClicked(true);
                  getOrderCounts();
                } else {
                  setOderClicked(false);
                }
              }}
            >
              <Nav.Item
                eventKey="2-1"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=All`);
                  sessionStorage.setItem("orderStatus", "All");
                  contextObj.setInput("orderStatus", "All");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "All")}
              >
                All {orderCount.all ? `(${orderCount.all})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-2"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Pending`);
                  sessionStorage.setItem("orderStatus", "Pending");
                  contextObj.setInput("orderStatus", "Pending");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Pending")}
              >
                Pending {orderCount.pending ? `(${orderCount.pending})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-3"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Confirmed`);
                  sessionStorage.setItem("orderStatus", "Confirmed");
                  contextObj.setInput("orderStatus", "Confirmed");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Confirmed")}
              >
                Confirmed{" "}
                {orderCount.confirmed ? `(${orderCount.confirmed})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-4"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Cancelled`);
                  sessionStorage.setItem("orderStatus", "Cancelled");
                  contextObj.setInput("orderStatus", "Cancelled");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Cancelled")}
              >
                Cancelled{" "}
                {orderCount.cancelled ? `(${orderCount.cancelled})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-5"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Packaging`);
                  sessionStorage.setItem("orderStatus", "Packaging");
                  contextObj.setInput("orderStatus", "Packaging");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Packaging")}
              >
                Packaging{" "}
                {orderCount.packaging ? `(${orderCount.packaging})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-7"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=In Transit`);
                  sessionStorage.setItem("orderStatus", "In Transit");
                  contextObj.setInput("orderStatus", "In Transit");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "In Transit")}
              >
                In Transit{" "}
                {orderCount.in_transit ? `(${orderCount.in_transit})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-8"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Delivered`);
                  sessionStorage.setItem("orderStatus", "Delivered");
                  contextObj.setInput("orderStatus", "Delivered");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Delivered")}
              >
                Delivered{" "}
                {orderCount.delivered ? `(${orderCount.delivered})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="2-9"
                onClick={() => {
                  navigate(`${Navigation.orders}?status=Returned`);
                  sessionStorage.setItem("orderStatus", "Returned");
                  contextObj.setInput("orderStatus", "Returned");
                  handleToggleClick();
                }}
                active={activeNavMenu("orders", "Returned")}
              >
                Returned{" "}
                {orderCount.returned ? `(${orderCount.returned})` : `(0)`}
              </Nav.Item>
            </Nav.Menu>
            <Nav.Item
              eventKey="3"
              icon={<Money />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.sellerPayments);
              }}
              active={isStartWith(Navigation.sellerPayments)}
            >
              Seller Payments
            </Nav.Item>
            <Nav.Item
              eventKey="4"
              icon={<ArowBack />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.refundPayments);
              }}
              active={isStartWith(Navigation.refundPayments)}
            >
              Customer Refunds
            </Nav.Item>
            <Nav.Item
              eventKey="5"
              icon={<HeartO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.donations);
              }}
              active={
                isStartWith(Navigation.donations) ||
                isStartWith(Navigation.donate)
              }
            >
              Donations
            </Nav.Item>

            {/* CMS */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              Product Management
            </p>
            <Nav.Item
              eventKey="5"
              icon={<Grid />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.ageCollection);
              }}
              active={isStartWith(Navigation.collections)}
            >
              Collection
            </Nav.Item>
            <Nav.Item
              eventKey="6"
              icon={<StickyNoteO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.categories);
              }}
              active={isStartWith(Navigation.categories)}
            >
              Categories
            </Nav.Item>
            <Nav.Item
              eventKey="7"
              icon={<Tag />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.brands);
              }}
              active={isStartWith(Navigation.brands)}
            >
              Brands
            </Nav.Item>
            <Nav.Menu
              placement="rightStart"
              eventKey="8"
              icon={<List />}
              title={`${APP_NAME} Products`}
              onClick={() => {
                if (!inHouseProductClicked) {
                  setInHouseProductClicked(true);
                  getInHouseProductCounts();
                } else {
                  setInHouseProductClicked(false);
                }
              }}
            >
              <Nav.Item
                eventKey="8-1"
                onClick={() => {
                  navigate(`${Navigation.adminProducts}?status=All`);
                  sessionStorage.setItem("adminProductStatus", "All");
                  contextObj.setInput("adminProductStatus", "All");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminProducts", "All")}
              >
                All{" "}
                {inHouseProductCount.all
                  ? `(${inHouseProductCount.all})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="8-2"
                onClick={() => {
                  navigate(
                    `${Navigation.adminProducts}?status=Verified and Published`
                  );
                  sessionStorage.setItem("adminProductStatus", "Published");
                  contextObj.setInput("adminProductStatus", "Published");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminProducts", "Published")}
              >
                Published{" "}
                {inHouseProductCount.published_products
                  ? `(${inHouseProductCount.published_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="8-3"
                onClick={() => {
                  navigate(`${Navigation.adminProducts}?status=Draft`);
                  sessionStorage.setItem("adminProductStatus", "Draft");
                  contextObj.setInput("adminProductStatus", "Draft");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminProducts", "Draft")}
              >
                Draft{" "}
                {inHouseProductCount.draft_products
                  ? `(${inHouseProductCount.draft_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="8-4"
                onClick={() => {
                  navigate(`${Navigation.adminProducts}?status=Hold`);
                  sessionStorage.setItem("adminProductStatus", "Hold");
                  contextObj.setInput("adminProductStatus", "Hold");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminProducts", "Hold")}
              >
                Hold{" "}
                {inHouseProductCount.hold_products
                  ? `(${inHouseProductCount.hold_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="8-5"
                onClick={() => {
                  navigate(`${Navigation.adminProducts}?status=Sold`);
                  sessionStorage.setItem("adminProductStatus", "Sold");
                  contextObj.setInput("adminProductStatus", "Sold");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminProducts", "Sold")}
              >
                Sold{" "}
                {inHouseProductCount.sold_products
                  ? `(${inHouseProductCount.sold_products})`
                  : `(0)`}
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu
              placement="rightStart"
              eventKey="9"
              title="Seller Products"
              icon={<List />}
              onClick={() => {
                if (!sellerProductClicked) {
                  setSellerProductClicked(true);
                  getSellerProductCounts();
                } else {
                  setSellerProductClicked(false);
                }
              }}
            >
              <Nav.Item
                eventKey="9-1"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=All`);
                  sessionStorage.setItem("sellerProductStatus", "All");
                  contextObj.setInput("sellerProductStatus", "All");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "All")}
              >
                All{" "}
                {sellerProductCount.all ? `(${sellerProductCount.all})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-2"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Unverified`);
                  sessionStorage.setItem("sellerProductStatus", "Unverified");
                  contextObj.setInput("sellerProductStatus", "Unverified");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Unverified")}
              >
                Unverified{" "}
                {sellerProductCount.unverified_products
                  ? `(${sellerProductCount.unverified_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-3"
                onClick={() => {
                  navigate(
                    `${Navigation.sellerProducts}?status=Verified and Published`
                  );
                  sessionStorage.setItem(
                    "sellerProductStatus",
                    "Verified and Published"
                  );
                  contextObj.setInput(
                    "sellerProductStatus",
                    "Verified and Published"
                  );
                  handleToggleClick();
                }}
                active={activeNavMenu(
                  "sellerProducts",
                  "Verified and Published"
                )}
              >
                Verified and Published{" "}
                {sellerProductCount.verified_and_published_products
                  ? `(${sellerProductCount.verified_and_published_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-4"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Rejected`);
                  sessionStorage.setItem("sellerProductStatus", "Rejected");
                  contextObj.setInput("sellerProductStatus", "Rejected");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Rejected")}
              >
                Rejected{" "}
                {sellerProductCount.rejected_products
                  ? `(${sellerProductCount.rejected_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-5"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Donated`);
                  sessionStorage.setItem("sellerProductStatus", "Donated");
                  contextObj.setInput("sellerProductStatus", "Donated");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Donated")}
              >
                Donated{" "}
                {sellerProductCount.donated_products
                  ? `(${sellerProductCount.donated_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-6"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Unavailable`);
                  sessionStorage.setItem("sellerProductStatus", "Unavailable");
                  contextObj.setInput("sellerProductStatus", "Unavailable");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Unavailable")}
              >
                Unavailable{" "}
                {sellerProductCount.unavailable_products
                  ? `(${sellerProductCount.unavailable_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-7"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Hold`);
                  sessionStorage.setItem("sellerProductStatus", "Hold");
                  contextObj.setInput("sellerProductStatus", "Hold");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Hold")}
              >
                Hold{" "}
                {sellerProductCount.hold_products
                  ? `(${sellerProductCount.hold_products})`
                  : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="9-8"
                onClick={() => {
                  navigate(`${Navigation.sellerProducts}?status=Sold`);
                  sessionStorage.setItem("sellerProductStatus", "Sold");
                  contextObj.setInput("sellerProductStatus", "Sold");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerProducts", "Sold")}
              >
                Sold{" "}
                {sellerProductCount.sold_products
                  ? `(${sellerProductCount.sold_products})`
                  : `(0)`}
              </Nav.Item>
            </Nav.Menu>

            {/* User Management */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              User Management
            </p>
            <Nav.Item
              eventKey="10"
              icon={<Peoples />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.customerList);
              }}
              active={isStartWith("/user/customers")}
            >
              Customers
            </Nav.Item>
            <Nav.Menu
              placement="rightStart"
              eventKey="11"
              title="Sellers"
              icon={<UserO />}
              onClick={() => {
                if (!sellerClicked) {
                  setSellerClicked(true);
                  getSellerCounts();
                } else {
                  setSellerClicked(false);
                }
              }}
            >
              <Nav.Item
                eventKey="11-1"
                onClick={() => {
                  navigate(`${Navigation.sellerList}?status=All`);
                  sessionStorage.setItem("sellerListStatus", "All");
                  contextObj.setInput("sellerListStatus", "All");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerList", "All")}
              >
                All {sellerCount.all ? `(${sellerCount.all})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="11-2"
                onClick={() => {
                  navigate(`${Navigation.sellerList}?status=Unverified`);
                  sessionStorage.setItem("sellerListStatus", "Unverified");
                  contextObj.setInput("sellerListStatus", "Unverified");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerList", "Unverified")}
              >
                Unverified{" "}
                {sellerCount.unverified ? `(${sellerCount.unverified})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="11-3"
                onClick={() => {
                  navigate(`${Navigation.sellerList}?status=Verified`);
                  sessionStorage.setItem("sellerListStatus", "Verified");
                  contextObj.setInput("sellerListStatus", "Verified");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerList", "Verified")}
              >
                Verified{" "}
                {sellerCount.verified ? `(${sellerCount.verified})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="11-4"
                onClick={() => {
                  navigate(`${Navigation.sellerList}?status=Blocked`);
                  sessionStorage.setItem("sellerListStatus", "Blocked");
                  contextObj.setInput("sellerListStatus", "Blocked");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerList", "Blocked")}
              >
                Blocked{" "}
                {sellerCount.blocked ? `(${sellerCount.blocked})` : `(0)`}
              </Nav.Item>
              <Nav.Item
                eventKey="11-5"
                onClick={() => {
                  navigate(`${Navigation.sellerList}?status=Deactivated`);
                  sessionStorage.setItem("sellerListStatus", "Deactivated");
                  contextObj.setInput("sellerListStatus", "Deactivated");
                  handleToggleClick();
                }}
                active={activeNavMenu("sellerList", "Deactivated")}
              >
                Deactivated{" "}
                {sellerCount.deactivated
                  ? `(${sellerCount.deactivated})`
                  : `(0)`}
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu
              placement="rightStart"
              eventKey="12"
              title="Admins"
              icon={<Admin />}
            >
              <Nav.Item
                eventKey="12-1"
                onClick={() => {
                  navigate(Navigation.roles);
                  sessionStorage.setItem("adminUserMenu", "Roles");
                  contextObj.setInput("adminUserMenu", "Roles");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminUsers", "Roles")}
              >
                Roles
              </Nav.Item>
              <Nav.Item
                eventKey="12-2"
                onClick={() => {
                  navigate(Navigation.adminList);
                  sessionStorage.setItem("adminUserMenu", "Users");
                  contextObj.setInput("adminUserMenu", "Users");
                  handleToggleClick();
                }}
                active={activeNavMenu("adminUsers", "Users")}
              >
                Users
              </Nav.Item>
            </Nav.Menu>

            {/* CMS */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              CMS
            </p>
            <Nav.Item
              eventKey="1"
              icon={<ThreeColumnsIcon />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.productsCollection);
              }}
              active={isStartWith(Navigation.productsCollection)}
            >
              Products Collection
            </Nav.Item>
            <Nav.Item
              eventKey="13"
              icon={<BuildingO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.ngoList);
              }}
              active={isStartWith(Navigation.ngoList)}
            >
              NGOs
            </Nav.Item>
            <Nav.Item
              eventKey="14"
              icon={<Image />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.banners);
              }}
              active={isStartWith(Navigation.banners)}
            >
              Banners
            </Nav.Item>
            <Nav.Item
              eventKey="15"
              icon={<Tag />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.blogCategories);
              }}
              active={isStartWith(Navigation.blogCategories)}
            >
              Blog Categories
            </Nav.Item>
            <Nav.Item
              eventKey="16"
              icon={<NewspaperO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.blogs);
              }}
              active={isStartWith(Navigation.blogs)}
            >
              Blogs
            </Nav.Item>
            <Nav.Item
              eventKey="17"
              icon={<Question2 />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.faq);
              }}
              active={isStartWith(Navigation.faq)}
            >
              FAQ's
            </Nav.Item>
            <Nav.Item
              eventKey="18"
              icon={<ThreeColumns />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.testimonials);
              }}
              active={isStartWith(Navigation.testimonials)}
            >
              Testimonials
            </Nav.Item>
            <Nav.Item
              eventKey="19"
              icon={<FileTextO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.policies);
              }}
              active={isStartWith(Navigation.policies)}
            >
              Policies
            </Nav.Item>

            {/* Help and Support */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              Help & Support
            </p>

            <Nav.Item
              eventKey="20"
              icon={<Review />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.navigateToFeedbackList);
              }}
              active={isStartWith(Navigation.navigateToFeedbackList)}
            >
              Feedbacks
            </Nav.Item>
            <Nav.Item
              eventKey="21"
              icon={<ReviewPassIcon />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.navigateToFeedbackSurvey);
              }}
              active={isStartWith(Navigation.navigateToFeedbackSurvey)}
            >
              Feedback Survey
            </Nav.Item>

            {/* System Settings */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              System Settings
            </p>

            <Nav.Item
              eventKey="22"
              icon={<Setting />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.navigateToBusinessSetup);
              }}
              active={isStartWith(Navigation.navigateToBusinessSetup)}
            >
              Business Setting
            </Nav.Item>
            <Nav.Item
              eventKey="23"
              icon={<Gear />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.navigateToAdminConfiguration);
              }}
              active={isStartWith(Navigation.navigateToAdminConfiguration)}
            >
              Env Configuration
            </Nav.Item>
            <Nav.Item
              eventKey="24"
              icon={<FlagO />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.navigateToSystemConfiguration);
              }}
              active={isStartWith(Navigation.navigateToSystemConfiguration)}
            >
              Feature Flag
            </Nav.Item>

            {/* Profile Settings */}
            <hr className="sidebar-divider" />
            <p
              className="menu-item-heading"
              style={{ display: openSidebar ? "block" : "none" }}
            >
              Profile Settings
            </p>

            <Nav.Item
              eventKey="25"
              icon={<Profile />}
              onClick={() => {
                handleToggleClick();
                navigate(Navigation.profile);
              }}
              active={isStartWith(Navigation.profile)}
            >
              Profile
            </Nav.Item>
            <Nav.Item
              eventKey="26"
              icon={<ExitIcon />}
              onClick={() => {
                handleToggleClick();
                handleLogout();
              }}
            >
              Logout
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

export default AppMenu;
