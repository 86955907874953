import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function StarRating({ rating }) {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(255, 159, 0)", height: "35px", padding: "5px" }}
        />
      );
    } else {
      stars.push(
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "gray", height: "35px", padding: "5px" }}
        />
      );
    }
  }

  return <div style={{ display: "flex" }}>{stars}</div>;
}

export default StarRating;
