import React, { useState } from "react";
import CreatedContexts from "./createContext";

const CreatedContextsState = (Props) => {
  const [states, setStates] = useState({
    sellerId: "",
    inHouseProductsSortedState: [],
  });
  const setInputValue = (field, value) => {
    setStates((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  return (
    <CreatedContexts.Provider value={{ states, setInputValue }}>
      {Props.children}
    </CreatedContexts.Provider>
  );
};

export default CreatedContextsState;
