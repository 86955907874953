import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./role.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../../Components/Toast";
import InputContext from "../../../../Components/InputContext";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../../Components/Cookie";

const EditAdminRole = ({ closeEditModal, RoleId }) => {
  const [editRoleName, setEditRoleName] = useState({
    name: "",
  });
  const location = useLocation();
  const [errorForName, setErrorForName] = useState("");
  const [errorForPermission, setErrorForPermission] = useState("");
  const [permisions, setPermisions] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [IsPermissionGet, setIsPermissionGet] = useState(false);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [roleDetails, setRoleDetails] = useState([]);
  const [activePermisitions, setActivePermisitions] = useState([]);
  const [showEditContain, setShowEditContain] = useState(false);
  const apiHost = process.env.REACT_APP_HOST_API_URL;
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();

  // const nameRegex = /^[A-Za-z\s]*$/;
  const isValidName = (value) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(value);
  };

  const handleEditChange = (e) => {
    setErrorForName("");
    let { name, value } = e.target;
    if (name == "name") {
      let validName = isValidName(value);
      if (value.trim() !== "" || value == "") {
        if (value == "" || (validName && name.length <= 80)) {
          setEditRoleName((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
    }
  };

  function ListOfValidRole(users) {
    let array = [];
    for (let i = 0; i < users.length; i++) {
      if (users[i].isChecked === true) {
        array.push(users[i].id);
      }
    }
    return array;
  }

  function updateCheckedStatus(stringArray, objectArray) {
    // setDataUpdated(false);
    return objectArray.map((obj) => {
      const displayNameExists = stringArray.includes(obj.display_name);
      return { ...obj, isChecked: displayNameExists };
    });
  }

  const changeCheckboxStatus = (e, id) => {
    setErrorForPermission("");
    const myUsers = [...permisions];
    const { checked } = e.target;
    myUsers.map((user) => {
      if (id === "p1") {
        setIsParentChecked(checked);
        user.isChecked = checked;
      } else {
        if (user.id === id) {
          user.isChecked = checked;
        }
        const isAllChildsChecked = myUsers.every(
          (user) => user.isChecked === true
        );
        if (isAllChildsChecked) {
          setIsParentChecked(checked);
        } else {
          setIsParentChecked(false);
        }
      }
      return user;
    });

    setPermisions([...myUsers]);
  };

  const updateDataLists = async () => {
    await VerifiedRolePermission();
    await GetDataForEdit();
    setDataUpdated(true);
  };
  useEffect(() => {
    updateDataLists();
  }, []);

  useEffect(() => {
    if (dataUpdated == true) {
      let afterChecked = updateCheckedStatus(activePermisitions, permisions);
      setPermisions(afterChecked);
      setDataUpdated(false);
      setShowEditContain(true);
    }
  }, [dataUpdated]);

  const VerifiedRolePermission = async () => {
    await fetch(`${apiHost}/${APIEndpoint.getRolePermission}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data;
          dataArray.forEach((data) => {
            data.isChecked = false;
          });

          setPermisions(dataArray);
          //  setShowAddPopup(true);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  const GetDataForEdit = async () => {
    await fetch(`${apiHost}/${APIEndpoint.adminRoles}/${RoleId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const dataArray = res.data;
          setRoleDetails(dataArray);

          setEditRoleName((prevData) => ({
            ...prevData,
            name: dataArray.display_name,
          }));
          setActivePermisitions(dataArray.permissions);
          setIsPermissionGet(true);
          //  setShowAddPopup(true);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  // Edit User
  function replaceSpaceAndLowercase(inputString) {
    // Replace spaces with underscores
    let modifiedString = inputString.replace(/ /g, "_");
    // Convert all letters to lowercase
    modifiedString = modifiedString.toLowerCase();
    return modifiedString;
  }

  const EditRole = async () => {
    const permission_id = ListOfValidRole(permisions);
    let error = false;
    if (editRoleName.name === "") {
      setErrorForName("Please Enter Role Name.");
      error = true;
    }
    if (permission_id.length < 1) {
      setErrorForPermission("Please Select the Module Permission.");
      error = true;
    }
    if (error == true) {
      return;
    }
    const name = replaceSpaceAndLowercase(editRoleName.name);

    let Payload = {
      display_name: editRoleName.name,
      name: name,
      permission_id: permission_id,
    };
    Payload = JSON.stringify(Payload);
    await fetch(`${apiHost}/${APIEndpoint.adminRoles}/${RoleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: Payload,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          showToast("success", "Admin User Role Update successfully");
          setDataToCookies("isEditedRole", true);
          closeEditModal();
        } else if (res.status === 400) {
          if (res.errors.name) {
            setErrorForName(res.errors.name[0]);
          }
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {showEditContain && (
        <div className="Add_Role_Admin_main_C1">
          <div className="Add_Role_Admin_Main_c1_b1">
            <div className="Role_setup_heading">
              <h1 className="Addrole_Heading"> Edit Role</h1>
              <span
                className="Add_role_cross_icon"
                onClick={() => closeEditModal()}
              >
                <FontAwesomeIcon icon={faX} style={{ color: "#9e9e9e" }} />
              </span>
            </div>
            <div className="AdminRoleSetup-grid-container-item1">
              <div>
                <label className="Role_Setup_lable_tag">
                  <input
                    type="text"
                    name="name"
                    className="Role_Setup_input_tag"
                    value={editRoleName.name}
                    onChange={handleEditChange}
                    placeholder="Role Name"
                  />
                  <span className="Role_Setup_span_tag">Role Name</span>
                </label>
                <li className="error_for_name">{errorForName}</li>
              </div>
              <h1 className="role_setup_Model_permission_heading">
                Module Permission
              </h1>
              <div className="Role_C1_B1_X1">
                <input
                  type="checkbox"
                  value="parent"
                  onChange={(e) => changeCheckboxStatus(e, "p1")}
                  checked={isParentChecked}
                />
                <label className="add_role_select_all_text">Select All</label>
              </div>
              {permisions &&
                permisions.map((permision) => {
                  return (
                    <div
                      className="add_role_permission_child_div"
                      key={permision.id}
                    >
                      <div className="Role_C1_B1_X1">
                        <input
                          type="checkbox"
                          checked={permision.isChecked}
                          value="child"
                          onChange={(e) =>
                            changeCheckboxStatus(e, permision.id)
                          }
                        />
                        <span className="role-name-label">
                          <label className="add_role_lable_display">
                            {permision.display_name}
                          </label>
                        </span>
                      </div>
                    </div>
                  );
                })}
              <li className="error_for_name"> {errorForPermission}</li>
            </div>
            <div className="modal-buttons">
              <button className="btn secondary-btn" onClick={closeEditModal}>
                Cancel
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={EditRole}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAdminRole;
