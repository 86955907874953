import React, { useEffect, useRef, useState } from "react";

import { useInView } from "react-intersection-observer";

const LazyImage = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.01, // Trigger when 1% of the div is visible
  });
  const [loadedOnce, setLoadedOnce] = useState(false); // State to track if image has been loaded once

  const imageRef = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);
  useEffect(() => {
    // If the image has been loaded once and it's not in view now, don't re-fetch it when it comes back into view
    if (!inView && loadedOnce) return;

    if (inView) {
      setLoadedOnce(true);
    }
  }, [inView, loadedOnce]);

  return (
    <div
      ref={ref}
      style={{
        height: "100%",
      }}
    >
      {(inView || loadedOnce) && (
        <img
          ref={imageRef}
          className={
            props.className ? `${props.className} lazyImage` : "lazyImage"
          }
          src={props.src}
          style={props.style}
          alt={props.alt ? props.alt : "Image"}
          loading="lazy"
        />
      )}
    </div>
  );
};

export default LazyImage;
