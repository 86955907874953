import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="global-filter">
      <input
        className="table-search-input"
        placeholder="Search here..."
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};
export default GlobalFilter;
