import { useContext, useEffect, useMemo, useState } from "react";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./blog-category.css";
import stringConstant from "../../../config/ProjectConstants.json";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const BlogCategory = () => {
  const location = useLocation();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [blogCategoryList, setBlogCategoryList] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [deleteName, setDeleteName] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editBlogCategoryId, setEditBlogCategoryId] = useState(null);
  const [showEditModalPopup, setShowEditModalPopup] = useState(false);
  const [showAddBlogModelPopup, setShowAddBlogModelPopup] = useState(false);
  const navigate = useNavigate();
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState({
    name: "",
  });
  const [addBlogCategoriesError, setAddBlogCategoriesError] = useState("");
  const [EditBlogCategoriesData, setEditBlogCategoriesData] = useState({
    name: "",
  });
  const [editBlogCategoriesError, setEditBlogCategoriesError] = useState("");
  const BlogCategoryPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const [totalCount, setTotalCount] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  //
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    GetBlogCategoryListData(data);
  };

  // Get List of All Category
  const GetBlogCategoryListData = async (url) => {
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    await fetch(`${url}&per_page=${BlogCategoryPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          const newdataArray = res.data.data;
          setBlogCategoryList(newdataArray);
          setPagination(res.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalCount(res.data.total);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        } else if (
          res.status === 404 &&
          res.message === "No categories found"
        ) {
          setBlogCategoryList([]);
          setPagination("");
        } else {
          console.error("Data is not an array");
        }
        setSearchLoading(false);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    GetBlogCategoryListData(
      `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${currentPage}`
    );
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        GetBlogCategoryListData(
          `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  // Delete Model popup
  const handleDeleteClickpopup = (id, name) => {
    setDeleteItemId(id);
    setDeleteName(name);
    setShowDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * BlogCategoryPerPage,
      },
      {
        Header: "Blog Category Name",
        accessor: "name",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          return (
            <div>
              <button
                onClick={() => openEditModal(id, name)}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                onClick={() => handleDeleteClickpopup(id, name)}
                className="action-icon trash-button"
                title="Delete"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: blogCategoryList,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Code for Add Blog Categories
  // Set Input for Add Blog Categories
  const handelAddInputChange = (event) => {
    setAddBlogCategoriesError("");
    const { name, value } = event.target;
    if (value.trim() !== "" || value == "") {
      setCategoriesData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // model Open call
  const handleAddBlogCategory = () => {
    setShowAddBlogModelPopup(true);
    setAddBlogCategoriesError("");
    setCategoriesData((prevData) => ({
      ...prevData,
      name: "",
    }));
  };

  // Add Blog Categories API and Validation
  const AddBlogCategories = async () => {
    let errorHas = false;

    if (categoriesData.name === "" || categoriesData.name === null) {
      setAddBlogCategoriesError("Blog Categories Name is required");
      errorHas = true;
    } else if (
      categoriesData.name.length < 3 ||
      categoriesData.name.length > 50
    ) {
      setAddBlogCategoriesError(
        "The Blog category name must be between 3 and 50 characters."
      );
      errorHas = true;
    } else {
      setAddBlogCategoriesError("");
    }

    if (errorHas === true) {
      return;
    }
    const data = {
      name: categoriesData.name,
    };
    const Payload = JSON.stringify(data);
    await fetch(`${API_HOST}/${APIEndpoint.createBlogCategory}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: Payload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 201) {
          showToast(
            "success",
            stringConstant.alertErrorMessage.alertForCategoriesCreated
          );
          setCategoriesData((prevData) => ({
            ...prevData,
            name: "",
          }));
          setShowAddBlogModelPopup(false);
          lastPage =
            totalCount % BlogCategoryPerPage == 0 ? lastPage + 1 : lastPage;
          GetBlogCategoryListData(
            `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${lastPage}`
          );
        } else if (data.status === 400) {
          if (data.errors.name) {
            setAddBlogCategoriesError(data.errors.name[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };
  // code For Edit Blog Categories

  // Open edit Model
  const openEditModal = (id, name) => {
    setEditBlogCategoriesError("");
    setEditBlogCategoryId(id);
    setShowEditModalPopup(true);
    setEditBlogCategoriesData((prevData) => ({
      ...prevData,
      name: name,
    }));
  };

  // handle Edit Input Change
  const handleEditInputChange = async (event) => {
    setEditBlogCategoriesError("");
    const { name, value } = event.target;
    if (value.trim() !== "" || value == "") {
      setEditBlogCategoriesData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const EditBlogCategories = async (id) => {
    let errorHas = false;

    if (
      EditBlogCategoriesData.name === "" ||
      EditBlogCategoriesData.name === null
    ) {
      setEditBlogCategoriesError("Blog Categories Name is required");
      errorHas = true;
    } else if (
      EditBlogCategoriesData.name.length < 3 ||
      EditBlogCategoriesData.name.length > 50
    ) {
      setEditBlogCategoriesError(
        "The Blog category name must be between 3 and 50 characters."
      );
      errorHas = true;
    } else {
      setEditBlogCategoriesError("");
    }

    if (errorHas === true) {
      return;
    }
    const data = {
      name: EditBlogCategoriesData.name,
    };
    const Payload = JSON.stringify(data);
    await fetch(`${API_HOST}/${APIEndpoint.blogCategoriesCrud}/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: Payload,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === 200) {
          showToast(
            "success",
            stringConstant.alertErrorMessage.alertForCategoriesEdit
          );
          setEditBlogCategoriesData((prevData) => ({
            ...prevData,
            name: "",
          }));
          setShowEditModalPopup(false);
          GetBlogCategoryListData(
            `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${currentPage}`
          );
        } else if (data.status === 400) {
          if (data.errors.name) {
            setEditBlogCategoriesError(data.errors.name[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };
  // code For Delete Blog Categories
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handelBlogCategoryDelete = async (id, name) => {
    //setDeleteRolenmae(roleName);
    await fetch(`${API_HOST}/${APIEndpoint.blogCategoriesCrud}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          showToast(
            "success",
            `${name} Blog Category deleted Successfully.
          `
          );
          setShowDeleteModal(false);
          if (currentPage == lastPage) {
            currentPage =
              totalCount % BlogCategoryPerPage == 1
                ? currentPage - 1
                : currentPage;
            GetBlogCategoryListData(
              `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${currentPage}`
            );
          } else {
            GetBlogCategoryListData(
              `${API_HOST}/${APIEndpoint.blogCategoriesCrud}?page_no=${currentPage}`
            );
          }
        } else if (
          response.status === 401 &&
          response.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status == 401 && response.message !== "") {
          showToast("error", response.message);
        } else if (response.status === 400) {
          showToast(
            "error",
            `${name} Blog category could not be deleted as 1 or more blogs are associated with this category.`
          );
          setShowDeleteModal(false);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to delete product with id ${id}. Error: ${error}`
        );
      });
  };

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Blog Categories</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              handleAddBlogCategory();
            }}
            className="Blog_Category_Add_Button"
          >
            Add Categories
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="InnerTableContainer">
          <table
            id="categoryTable"
            className="order-table"
            {...getTableProps()}
          >
            <thead className="Category-grid-container-item2-tableHead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="Category-grid-container-item2-tableHead">
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No Categories available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Blog Categories"}
            />
          )}
        </div>
        {showAddBlogModelPopup && (
          <div className="Add_Blog_Category_Admin_main_C1">
            <div className="Add_Blog_Category_Admin_Main_c1_b1">
              <div className="Add_Blog_heading_Container">
                <h1 className="Add_Blog_Heading"> Add Blog Categories</h1>
                <span
                  className="modal-close"
                  onClick={() => {
                    setShowAddBlogModelPopup(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
              <div>
                <label className="Add_Blog_category_label">
                  <input
                    type="text"
                    name="name"
                    maxLength={60}
                    minLength={2}
                    className="Add_Blog_category_input"
                    value={categoriesData.name}
                    onChange={handelAddInputChange}
                    placeholder="Blog Categories Name"
                  />
                  <span className="Add_Blog_category_span">
                    Blog Categories Name
                  </span>
                </label>
                <li className="blog_categories_name">
                  {addBlogCategoriesError}
                </li>
              </div>
              <div className="modal-buttons">
                <button
                  onClick={() => {
                    setShowAddBlogModelPopup(false);
                  }}
                  className="btn secondary-btn"
                >
                  {" "}
                  Cancel
                </button>
                <button className="btn primary-btn" onClick={AddBlogCategories}>
                  {" "}
                  Add{" "}
                </button>
              </div>
            </div>
          </div>
        )}
        {showEditModalPopup && (
          <div className="Add_Blog_Category_Admin_main_C1">
            <div className="Add_Blog_Category_Admin_Main_c1_b1">
              <div className="Add_Blog_heading_Container">
                <h1 className="Add_Blog_Heading"> Edit Blog Categories</h1>
                <button
                  className="modal-close"
                  onClick={() => {
                    setShowEditModalPopup(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div>
                <label className="Add_Blog_category_label">
                  <input
                    type="text"
                    name="name"
                    maxLength={60}
                    minLength={2}
                    className="Add_Blog_category_input"
                    value={EditBlogCategoriesData.name}
                    onChange={handleEditInputChange}
                    placeholder="Blog Categories Name"
                  />
                  <span className="Add_Blog_category_span">
                    Blog Categories Name
                  </span>
                </label>
                <li className="blog_categories_name">
                  {editBlogCategoriesError}
                </li>
              </div>
              <div className="modal-buttons">
                <button
                  onClick={() => {
                    setShowEditModalPopup(false);
                  }}
                  className="btn secondary-btn"
                >
                  {" "}
                  Cancel
                </button>
                <button
                  className="btn primary-btn"
                  onClick={() => {
                    EditBlogCategories(editBlogCategoryId);
                  }}
                >
                  {" "}
                  Save{" "}
                </button>
              </div>
            </div>
          </div>
        )}
        {showDeleteModal && (
          <div className="Add_Blog_Category_Admin_main_C1">
            <div className="Add_Blog_Category_Admin_Main_c1_b1">
              <div className="Add_Blog_heading_Container">
                <h1 className="Delete_Blog_Heading">
                  {" "}
                  Delete Blog Categories Confirmation{" "}
                </h1>
                <button
                  className="crossbuttonbreand"
                  onClick={handleCancelDelete}
                >
                  <span className="add_Categories_close_icon">&times;</span>
                </button>
              </div>
              <p className="Delete_Blog_para">
                Are you sure you want to delete{" "}
                <h2 className="deleteitemsNameappPages">{deleteName}</h2> item?
              </p>
              <div className="modal-buttons">
                <button
                  type="button"
                  className="btn primary-btn"
                  onClick={() =>
                    handelBlogCategoryDelete(deleteItemId, deleteName)
                  }
                >
                  Yes, Delete It!
                </button>
                <button
                  type="button"
                  className="btn secondary-btn"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCategory;
