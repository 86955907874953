import { useContext, useState } from "react";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import { Switch } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useEffect } from "react";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const AddBanner = ({ handleCloseModal }) => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const contextObj = useContext(InputContext);
  const [loading, setLoading] = useState(false);
  const [shopData, setShopData] = useState([]);
  const [banner, setBanner] = useState({
    image1: null,
    image2: null,
    cta_url: null,
    status: "Published",
    seller_id: null,
  });

  const [errors, setErrors] = useState({
    image1: "",
    image2: "",
    cta_url: "",
    status: "",
  });

  const [imgContainer, setImgContainer] = useState({
    image1: null,
    image2: null,
  });
  const GetShopListData = async () => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }

      const response = await fetch(`${API_HOST}/api/admin/shops`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setShopData(res.data);
      } else if (res.status === 404) {
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(Navigation.login);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        showToast("info", "Sessions expired!");
      } else if (res.status == 401 && res.message !== "") {
        showToast("error", res.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetShopListData();
  }, []);
  // Handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "image1" || name === "image2") {
      const file = event.target.files[0];
      setBanner((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setBanner((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  const removeImageFromContainer = (imgName) => {
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setBanner((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setErrors((prev) => ({
      ...prev,
      [imgName]: "",
    }));
    document.getElementById(imgName).value = "";
  };

  const resetInputType = (imgName) => {
    setImgContainer((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    setBanner((prev) => ({
      ...prev,
      [imgName]: null,
    }));
    document.getElementById(imgName).value = "";
  };

  const compressAndResizeImage = (file, imgName) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate new dimensions to fit within 1200x1200 while preserving aspect ratio
        let maxWidth = 1200;
        let maxHeight = 1200;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            if (blob.size > maxSizeInBytes) {
              setErrors((prev) => ({
                ...prev,
                [imgName]: "Image size must be up to 2 MB",
              }));
              resetInputType(imgName);
              return;
            }

            // Display compressed image
            const compressedImg = new File([blob], file.name, {
              type: file.type,
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgContainer((prev) => ({
                ...prev,
                [imgName]: reader.result,
              }));
            };
            reader.readAsDataURL(compressedImg);

            setBanner((prev) => ({
              ...prev,
              [imgName]: compressedImg,
            }));
          },
          file.type,
          0.7 // Compression quality (0.7 means 70% quality)
        );
      };
    };

    reader.readAsDataURL(file);
  };

  // image upload functions start here
  const handleImageUpload = (imgName) => {
    const ele = document.getElementById(imgName);
    ele.click();

    ele.addEventListener("change", (event) => {
      const file = event.target.files[0];
      if (file === undefined) return;

      setErrors((prev) => ({
        ...prev,
        [imgName]: "",
      }));

      // Check file extension
      const allowedExtensions = ["png", "jpg", "jpeg", "webp", "gif"];
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        setErrors((prev) => ({
          ...prev,
          [imgName]:
            "Please upload an image with .png, .jpg, .jpeg, .webp, .gif extension.",
        }));
        resetInputType(imgName);
        return;
      }

      // Compress and resize image
      compressAndResizeImage(file, imgName);
    });
  };

  // Add New Banner
  const handleAddBanner = async () => {
    let error = false;

    // Image 1 validation
    if (banner.image1 === null) {
      setErrors((prev) => ({
        ...prev,
        image1: "This field is required",
      }));
      error = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        image1: "",
      }));
    }

    // Image 2 validation
    if (banner.image2 === null) {
      setErrors((prev) => ({
        ...prev,
        image2: "This field is required",
      }));
      error = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        image2: "",
      }));
    }

    // CTA URL Validation
    const isValidURL = (url) => {
      const regex =
        /^(http|https):\/\/[a-zA-Z0-9][a-zA-Z0-9\-\.]*\.[a-zA-Z]{2,4}(\/\S*)?$/i;
      return regex.test(url);
    };
    if (banner.cta_url !== null && !isValidURL(banner.cta_url)) {
      setErrors((prev) => ({
        ...prev,
        cta_url: "Invalid URL format",
      }));
      error = true;
    } else {
      setErrors((prev) => ({
        ...prev,
        cta_url: "",
      }));
    }

    if (error == true) return;

    const formData = new FormData();
    formData.append("image1", banner.image1);
    formData.append("image2", banner.image2);
    formData.append("status", banner.status);
    if (banner.seller_id != null) {
      formData.append("seller_id", banner.seller_id);
    }
    if (banner.cta_url !== null) {
      formData.append("cta_url", banner.cta_url);
    }

    setLoading(true);
    const response = await fetch(`${API_HOST}/${APIEndpoint.addNewBanner}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const res = await response.json();

    if (res.status === 201) {
      showToast("success", res.message);
      setBanner((prev) => ({
        ...prev,
        image1: null,
        image2: null,
        cta_url: null,
        status: "Published",
      }));
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      contextObj.setInput("navigation", "true");
      contextObj.setInput("navigationFrom", "sessionExpired");
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    handleCloseModal();
    setLoading(false);
  };

  return (
    <div className="modal fade">
      <div className="modal-content banner-modal-container">
        <h3 className="modal-header">Add Banner</h3>
        <span className="modal-close" onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className="modal-body">
          <div className="banner-images">
            <div>
              <label>
                Web Image (1600 x 265) px <span className="red">*</span>
              </label>
              <div>
                <div
                  onClick={() => {
                    if (!imgContainer.image1) {
                      handleImageUpload("image1");
                    }
                  }}
                  className={
                    "image-dropzone " +
                    (imgContainer.image1 && "dropzone-uploaded")
                  }
                >
                  {imgContainer.image1 ? (
                    <>
                      <img
                        className="product-image"
                        src={imgContainer.image1}
                        loading="lazy"
                      />
                      <div className="img-btn-action">
                        <div className="img-btns">
                          <button
                            type="button"
                            className="image-remove-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImageFromContainer("image1");
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            Remove
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p className="click-to-upload">Click to upload</p>
                  )}
                  <input
                    id="image1"
                    type="file"
                    accept=".png, .jpg, .jpeg, .webp, .gif"
                    name="image1"
                    onChange={handleInputChange}
                    hidden
                  />
                </div>
                {errors.image1 && (
                  <label className="error">{errors.image1}</label>
                )}
              </div>
            </div>
            <div>
              <label>
                Mobile Image (990 x 440) px <span className="red">*</span>
              </label>
              <div>
                <div
                  onClick={() => {
                    if (!imgContainer.image2) {
                      handleImageUpload("image2");
                    }
                  }}
                  className={
                    "image-dropzone " +
                    (imgContainer.image2 && "dropzone-uploaded")
                  }
                >
                  {imgContainer.image2 ? (
                    <>
                      <img
                        className="product-image"
                        src={imgContainer.image2}
                        loading="lazy"
                      />
                      <div className="img-btn-action">
                        <div className="img-btns">
                          <button
                            type="button"
                            className="image-remove-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeImageFromContainer("image2");
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            Remove
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p className="click-to-upload">Click to upload</p>
                  )}
                  <input
                    id="image2"
                    type="file"
                    accept=".png, .jpg, .jpeg, .webp, .gif, .svg"
                    name="image2"
                    onChange={handleInputChange}
                    hidden
                  />
                </div>
                {errors.image2 && (
                  <label className="red">{errors.image2}</label>
                )}
              </div>
            </div>
          </div>
          <label>CTA URL</label>
          <div>
            <input
              name="cta_url"
              type="text"
              className="form-control"
              value={banner.cta_url}
              onChange={handleInputChange}
            />
            {errors.cta_url && <label className="red">{errors.cta_url}</label>}
          </div>
          <div>
            <label className="seller_stories_lable_tag">
              <select
                value={banner.seller_id}
                type="text"
                name="seller_id"
                className="seller_stories_input_tag"
                placeholder="Shop Name"
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {/* Map the data array here */}
                {shopData.map((shop) => (
                  <option
                    key={shop.id}
                    value={shop.id}
                    className="custom-option"
                  >
                    {shop.shop_name} - (
                    <span className="shop-url"> {shop.shop_url} </span> )
                  </option>
                ))}
              </select>
              <span className="seller_stories_span_tag">Shop Name</span>
            </label>
            <p className="red">{}</p>
          </div>
          <label>
            Status <span className="red">*</span>
          </label>
          <div>
            <Switch
              checked={banner.status === "Published"}
              onChange={() => {
                setBanner((prev) => ({
                  ...prev,
                  status:
                    banner.status === "Published" ? "Unpublished" : "Published",
                }));
              }}
            />
            <label>{banner.status}</label>
          </div>
        </div>
        <div className="modal-buttons">
          <button
            type="button"
            className="btn secondary-btn"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          {loading ? (
            <button type="button" className="btn disabled-primary-btn">
              Add Banner
            </button>
          ) : (
            <button
              type="button"
              className="btn primary-btn"
              onClick={handleAddBanner}
            >
              Add Banner
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
