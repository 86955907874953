import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../config/Navigation.json";
import "./collection.css";

const CollectionNavigationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const activePath = currentPath;
  return (
    <>
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Collections</li>
        </div>
      </div>
      <div className="collection-navigation">
        <li
          className={`collection-item ${
            activePath === "/collections/age" ? "active" : ""
          }`}
          onClick={() => navigate(Navigation.ageCollection)}
        >
          Age
        </li>
        <li
          className={`collection-item ${
            activePath === "/collections/price" ? "active" : ""
          }`}
          onClick={() => navigate(Navigation.priceCollection)}
        >
          {" "}
          Price
        </li>
        <li
          className={`collection-item ${
            activePath === "/collections/condition" ? "active" : ""
          }`}
          onClick={() => navigate(Navigation.conditionCollection)}
        >
          {" "}
          Conditions
        </li>
      </div>
    </>
  );
};

export default CollectionNavigationPage;
