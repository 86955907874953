import { useEffect, useMemo, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSearch,
  faTimes,
  faTrashCan,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import LoaderButton from "../../../Components/LoaderButton";
import Paginate from "../../../Components/Pagination/Paginate";
import "./Faq.css";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Faq = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const { showToast } = Toast();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddFaq, setIsAddFaq] = useState(false);
  const [showAddEditModal, setshowAddEditModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [FaqEditId, setFaqEditId] = useState("");
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loadingbutton, setloadingbutton] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const [Faq, setFaq] = useState({
    question: "",
    answer: "",
    module: "",
  });
  let [lastPage, setLastPage] = useState("");
  const [errors, setErrors] = useState({
    answer: "",
    module: "",
    question: "",
  });
  let token = getDataFromCookies("7b9KpQ3rW6");
  const [totalCount, setTotalCount] = useState("");
  // handle pagination
  const dataPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getFaqData(data);
  };
  //Input Change

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "" || value == "") {
      setFaq((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setErrors((prevData) => ({
      ...prevData,
      question: "",
      answer: "",
      module: "",
    }));
  };

  //Check Basic Validation Before API Calls
  const validateFaqData = () => {
    let errorHas = false;
    if (Faq.question === "" || Faq.question === null) {
      setErrors((prev) => ({
        ...prev,
        question: "Question is required!",
      }));
      errorHas = true;
    }
    if (Faq.answer === "" || Faq.answer === null) {
      setErrors((prev) => ({
        ...prev,
        answer: "Answer is required!",
      }));
      errorHas = true;
    }
    if (Faq.module === "" || Faq.module === null) {
      setErrors((prev) => ({
        ...prev,
        module: "Module is required!",
      }));
      errorHas = true;
    }
    return errorHas;
  };

  // Get FAQ Data

  const getFaqData = async (url) => {
    try {
      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }

      const response = await fetch(`${url}&per_page=${dataPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // if (!response.ok) {
      //   throw new Error(`API request failed with status: ${response.status}`);
      // }
      const res = await response.json();
      if (res.status === 200) {
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalCount(res.data.total);
        setData(res.data.data);
      } else if (res.status === 404) {
        setData([]);
        setPagination("");
      }
      setLoading(false);
      setSearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        //navigate("/error");
      } else {
        // window.location.href = "/error";
      }
    }
  };

  useEffect(() => {
    getFaqData(`${API_HOST}/${APIEndpoint.getFaqData}?page_no=1`);
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        getFaqData(
          `${API_HOST}/${APIEndpoint.getFaqData}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const handelAddFaqShow = () => {
    setshowAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setIsAddFaq(false);
    setshowAddEditModal(false);
    setFaq((prevData) => ({
      ...prevData,
      question: "",
      answer: "",
      module: "",
    }));
    setErrors((prevData) => ({
      ...prevData,
      question: "",
      answer: "",
      module: "",
    }));
  };

  const closeDeleteModal = () => {
    setDeleteId(null);
    setshowDeleteModal(false);
  };

  const openFaqEditModal = (id, Question, Answer, module) => {
    setFaqEditId(id);
    setFaq((prevData) => ({
      ...prevData,
      question: Question,
      answer: Answer,
      module: module,
    }));
    setshowAddEditModal(true);
  };

  //
  const handleResponse = (response, responseData) => {
    if (response.status === 200 || response.status === 201) {
      if (response.status === 200) {
        getFaqData(
          `${API_HOST}/${APIEndpoint.getFaqData}?page_no=${currentPage}`
        );
        showToast("success", responseData.message);
        handleCloseAddEditModal();
      } else if (response.status === 201) {
        // lastPage = totalCount % dataPerPage == 0 ? lastPage + 1 : lastPage;
        getFaqData(`${API_HOST}/${APIEndpoint.getFaqData}?page_no=1`);
        showToast("success", responseData.message);
        handleCloseAddEditModal();
      }
    } else if (
      response.status === 401 &&
      responseData.status === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      handleUnauthorized();
    } else if (response.status == 401 && response.message !== "") {
      showToast("error", response.message);
    } else if (response.status === 400) {
      handleResponseErrors(responseData);
    } else if (response.status === 409) {
      setErrors((prevData) => ({
        ...prevData,
        question: responseData.message,
      }));
    }
  };

  const handleUnauthorized = () => {
    removeDataFromCookie("7b9KpQ3rW6", "/");
    removeDataFromCookie("loggedIn", "/");
    navigate("/");
    showToast("info", "Sessions expired! Please login again");
  };

  const handleResponseErrors = (responseData) => {
    if (responseData.error) {
      const { error } = responseData;
      Object.keys(error).forEach((key) => {
        setErrors((prev) => ({
          ...prev,
          [key]: error[key][0],
        }));
      });
    }
  };

  // Add FAQ
  const AddFAQ = async () => {
    try {
      setloadingbutton(true);
      const errorHas = validateFaqData();
      if (errorHas) {
        setloadingbutton(false);
        return;
      }
      const response = await fetch(`${API_HOST}/${APIEndpoint.crudFAQ}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(Faq),
      });
      const responseData = await response.json();
      setLoading(false);
      setloadingbutton(false);
      handleResponse(response, responseData);
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  // Edit FAQ
  const EditFAQ = async () => {
    try {
      setloadingbutton(true);
      const errorHas = validateFaqData();
      if (errorHas) {
        setloadingbutton(false);
        return;
      }
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.crudFAQ}/${FaqEditId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(Faq),
        }
      );
      const responseData = await response.json();
      setloadingbutton(false);
      handleResponse(response, responseData);
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const openDeleteModal = (id) => {
    setDeleteId(id);
    setshowDeleteModal(true);
  };

  // Delete API Calls

  const handleConfirmDelete = async (id) => {
    try {
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }

      setDeleteBtnLoading(true);
      const response = await fetch(`${API_HOST}/${APIEndpoint.crudFAQ}/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 204) {
        showToast("success", "FAQ deleted successfully");
        // Assuming this function retrieves updated FAQ data
        if (currentPage == lastPage) {
          currentPage =
            totalCount % dataPerPage == 1 ? currentPage - 1 : currentPage;
          getFaqData(
            `${API_HOST}/${APIEndpoint.getFaqData}?page_no=${currentPage}`
          );
        } else {
          getFaqData(
            `${API_HOST}/${APIEndpoint.getFaqData}?page_no=${currentPage}`
          );
        }
      } else if (response.status === 401) {
        navigate(Navigation.login);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        showToast("info", "Session expired!");
      } else if (response.status == 401 && response.message !== "") {
        showToast("error", response.message);
      } else {
        showToast("info", response.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      showToast("error", "An error occurred while deleting FAQ.");
    } finally {
      setDeleteBtnLoading(false);
      closeDeleteModal();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * dataPerPage,
      },
      {
        Header: "Question",
        accessor: "question",
      },
      {
        Header: "Module",
        accessor: "module",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const question = row.original.question;
          const answer = row.original.answer;
          const module = row.original.module;
          return (
            <div>
              <button
                onClick={() => openFaqEditModal(id, question, answer, module)}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <button
                type="button"
                className="action-icon trash-button"
                title="Delete"
                onClick={() => openDeleteModal(id)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">FAQ's</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          <button
            onClick={() => {
              setIsAddFaq(true);
              handelAddFaqShow();
            }}
            className="btn primary-btn"
          >
            Add FAQ
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead className="Category-grid-container-item2-tableHead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th className="Category-grid-container-item2-tableHead">
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No FAQ available.</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Faq"}
            />
          )}
        </div>

        {/* FAQ Add Edit Modal */}
        {showAddEditModal && (
          <div className="FAQ_Edit_Blogs">
            {" "}
            <div className="FAQ_Edit_Modal_C1">
              {" "}
              <div
                style={{ right: "5px", top: "5px" }}
                onClick={handleCloseAddEditModal}
                className="modal-close"
              >
                <FontAwesomeIcon
                  style={{ fontSize: "12px", fontWeight: "700" }}
                  icon={faX}
                />
              </div>
              <div>
                <h1 className="heading_Price">
                  {" "}
                  {isAddFaq ? "Add FAQ" : "Edit FAQ"}
                </h1>
                <label className="custom-input-label-field">
                  <input
                    type="text"
                    placeholder="Question"
                    className="custom-input-field"
                    name="question"
                    autoComplete="off"
                    value={Faq.question}
                    onChange={handleInputChange}
                  />
                  <span className="custom-label-field">Question</span>
                </label>
                <span className="Add_Blog_error">{errors.question}</span>
                <label className="custom-input-label-field">
                  <textarea
                    type="text"
                    placeholder="Answer"
                    className="custom-input-field faq_textarea_input"
                    name="answer"
                    autoComplete="off"
                    value={Faq.answer}
                    onChange={handleInputChange}
                  />
                  <span className="custom-label-field">Answer</span>
                </label>
                <span className="Add_Blog_error">{errors.answer}</span>
                <label className="custom-input-label-field">
                  <select
                    type="text"
                    placeholder="Type"
                    className="custom-input-field"
                    name="module"
                    autoComplete="off"
                    value={Faq.module}
                    onChange={handleInputChange}
                  >
                    <option value={""}>Select</option>
                    <option value={"Buyer"}>Buyer</option>
                    <option value={"Seller"}>Seller</option>
                    <option value={"General"}>General</option>
                  </select>
                  <span className="custom-label-field">Type</span>
                </label>
                <span className="Add_Blog_error">{errors.module}</span>
              </div>
              <div className="Faq-Button-Container">
                <button
                  className="btn secondary-btn"
                  onClick={() => {
                    handleCloseAddEditModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn primary-btn"
                  disabled={loadingbutton}
                  onClick={() => {
                    {
                      isAddFaq ? AddFAQ() : EditFAQ();
                    }
                  }}
                >
                  {loadingbutton ? <LoaderButton /> : isAddFaq ? "Add" : "Save"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* FAQ Delete Modal */}
        {showDeleteModal && (
          <div
            id="deleteModal"
            className="modal fade"
            style={{ display: showDeleteModal ? "block" : "none" }}
          >
            <div className="modal-content">
              <span className="modal-close" onClick={closeDeleteModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <h3 className="modal-header">Delete Confirmation</h3>
              <p className="modal-line">Are you sure you want to delete FAQ?</p>
              <div className="modal-buttons">
                {deleteBtnLoading ? (
                  <button type="button" className="btn disabled-primary-btn">
                    Yes, Delete It!
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn primary-btn"
                    onClick={() => handleConfirmDelete(deleteId)}
                  >
                    Yes, Delete It!
                  </button>
                )}
                <button
                  className="btn secondary-btn"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Faq;
