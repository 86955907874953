import React, { useState } from "react";
import InputContext from "./InputContext";

const InputProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    payload: "",
    navigation: "false",
    navigationFrom: "",
    sellerUserName: "",
    isMasterAdmin: false,
    activeOrderFilter: "",
    activeAdminProductsFilter: "",
    activeSellersProductsFilter: "",
    activeButtons: {
      VerifyAndPublish: true,
      Verify: true,
      Rejected: true,
      RejectedNote: true,
    },
    prevPath: null,
  });

  const setInput = (field, value) => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <InputContext.Provider value={{ inputValue, setInput }}>
      {children}
    </InputContext.Provider>
  );
};

export default InputProvider;
