import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./userCreate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import APIEndpoint from "../../../../config/APIEndpoints.json";
import Navigation from "../../../../config/Navigation.json";
import StringConstant from "../../../../config/ProjectConstants.json";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import LoaderButton from "../../../../Components/LoaderButton";
import InputContext from "../../../../Components/InputContext";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../../Components/Cookie";
import Toast from "../../../../Components/Toast";
import CustomDropdown from "./CustomDropdown";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const UserCreate = ({ handleCloseAddModelPopup }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adminRolesURL = `${API_HOST}/${APIEndpoint.adminRoles}`;
  const [hasMoreRoles, setHasMoreRoles] = useState(null);
  const [loadingMoreRoles, setLoadingMoreRoles] = useState(false);
  const contextObj = useContext(InputContext);
  // error usestates
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [profileImageError, setProfileImageError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [showResetNewPassword, setShowResetNewPassword] = useState(false);
  const [showResetNewConPassword, setShowResetNewConPassword] = useState(false);
  //image upload
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });
  const [permissionIdValue, setPermissionIdValue] = useState("");
  const [optionsValue, setOptionsValue] = useState([]);
  // upload profile image
  const { showToast } = Toast();

  const [userData, setUserData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    phone: "",
    password: "",
    Confirm_password: "",
    roleId: "",
    image: null,
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const isValidName = (value) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(value);
  };
  // Get Auth Token
  const AuthToken = getDataFromCookies("7b9KpQ3rW6");
  // handle select value
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "f_name") {
      let validFirstName = isValidName(value);
      if (value.trim() == "") {
        setUserData((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));
      }
      if (validFirstName) {
        setFirstNameError("");
        if (value.trim() !== "" || value == "") {
          setUserData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      } else {
        setFirstNameError("Only character are allowed");
      }
    } else if (name === "l_name") {
      let validLastName = isValidName(value);
      if (validLastName || value === "") {
        setLastNameError("");
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        setLastNameError("Only character are allowed");
      }
    } else if (name === "email") {
      setEmailError("");
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setPhoneError("");
      setUserData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "password") {
      if (value.trim() !== "" || value == "") {
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
      setPasswordError("");
    } else if (name === "Confirm_password") {
      setConfirmPasswordError("");
      if (value.trim() !== "" || value == "") {
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "roleId") {
      setPermissionIdValue(value);
      setRoleNameError("");
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const url = `${API_HOST}/${APIEndpoint.createAdminUser}`;
  const your_access_token = getDataFromCookies("7b9KpQ3rW6");

  if (!your_access_token) {
    window.location.href = `${Navigation.login}`;
  }

  const createUsers = async () => {
    let errorHas = false;
    const fnameRegex = /^[a-zA-Z\s]+$/;
    const isFirstName = fnameRegex.test(userData.f_name);

    if (userData.f_name == "" || userData.f_name === null) {
      setFirstNameError(StringConstant.errorMessages.errorForBlankFirstName);
      errorHas = true;
    } else if (!isFirstName) {
      setFirstNameError(StringConstant.errorMessages.errorForInvalidFirstName);
      errorHas = true;
    } else if (userData.f_name.length > 50) {
      setFirstNameError(
        StringConstant.errorMessages.errorForInvalidFirstNameLength
      );
      errorHas = true;
    } else {
      setFirstNameError(StringConstant.errorMessages.errorForBlankSpace);
    }

    const lnameRegex = /^[a-zA-Z\s]+$/;
    const isLastName = lnameRegex.test(userData.l_name);
    if (userData.l_name === "" || userData.l_name === null) {
      setLastNameError(StringConstant.errorMessages.errorForBlankLastName);
      errorHas = true;
    } else if (!isLastName) {
      setLastNameError(StringConstant.errorMessages.errorForInvalidLastName);
      errorHas = true;
    } else if (userData.l_name.length > 50) {
      setLastNameError(
        StringConstant.errorMessages.errorForInvalidLastNameLength
      );
      errorHas = true;
    } else {
      setLastNameError(StringConstant.errorMessages.errorForBlankSpace);
    }

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isEmail = emailRegex.test(userData.email);
    if (userData.email === "" || userData.email === null) {
      setEmailError(StringConstant.errorMessages.errorForBlankEmail);
      errorHas = true;
    } else if (!isEmail) {
      setEmailError(StringConstant.errorMessages.errorForInvalidEmail);
      errorHas = true;
    } else if (userData.email.length > 254) {
      setEmailError(StringConstant.errorMessages.errorForInvalidEmailLength);
      errorHas = true;
    } else {
      setEmailError(StringConstant.errorMessages.errorForBlankSpace);
    }

    const phoneRegex =
      /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[123456789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(userData.phone);
    if (userData.phone === "" || userData.phone === null) {
      setPhoneError(StringConstant.errorMessages.errorForBlankPhoneNumber);
      errorHas = true;
    } else if (!isPhoneNumber) {
      setPhoneError(StringConstant.errorMessages.errorForInvalidPhoneNumber);
      errorHas = true;
    } else {
      setPhoneError(StringConstant.errorMessages.errorForBlankSpace);
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_\-+=])[^\s]{6,}$/;
    const isPassword = passwordRegex.test(userData.password);
    if (userData.password === "" || userData.password === null) {
      setPasswordError(StringConstant.errorMessages.errorForBlankPasssword);
      errorHas = true;
    } else if (!isPassword) {
      setPasswordError(StringConstant.errorMessages.errorForPasswordValidation);
      errorHas = true;
    } else if (userData.password !== userData.Confirm_password) {
      setConfirmPasswordError(
        StringConstant.errorMessages.errorInvalidConfirmPassword
      );
      errorHas = true;
    } else {
      setPasswordError(StringConstant.errorMessages.errorForBlankSpace);
    }
    if (
      userData.roleId === "" ||
      userData.roleId === null ||
      userData.roleId === "Please choose one option"
    ) {
      setRoleNameError(StringConstant.errorMessages.errorForBlankRoleName);
      errorHas = true;
    } else if (userData.roleId === "1") {
      setRoleNameError(
        StringConstant.errorMessages.errorForNotAllowedMasterAdmin
      );
      errorHas = true;
    } else {
      setRoleNameError(StringConstant.errorMessages.errorForBlankSpace);
    }

    if (errorHas === true) {
      setLoadingButton(false);
      return;
    }

    const formData = new FormData();
    if (userData.f_name !== null) {
      formData.append("f_name", userData.f_name);
    }
    if (userData.l_name !== null) {
      formData.append("l_name", userData.l_name);
    }
    if (userData.phone !== null) {
      formData.append("phone", userData.phone);
    }
    if (userData.password !== null) {
      formData.append("password", userData.password);
    }
    if (userData.Confirm_password !== null) {
      formData.append("password_confirmation", userData.Confirm_password);
    }
    if (userData.roleId !== null) {
      formData.append("role_id", userData.roleId);
    }
    if (userData.image !== null && userData.image !== "users/def.png") {
      formData.append("image", userData.image);
    }
    if (userData.email !== null) {
      formData.append("email", userData.email);
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${your_access_token}`,
      },
      body: formData,
    });

    const res = await response.json();
    setLoadingButton(false);

    if (res.status === 201) {
      setDataToCookies("isAddedUsers", true);
      handelGoToUserList();
      showToast("success", "User Created successfully");
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 400) {
      if (res.errors.email) {
        setEmailError(res.errors.email[0]);
      }
      if (res.errors.phone) {
        setPhoneError(res.errors.phone[0]);
      }
    } else {
      console.error(res);
    }
  };

  // img remove after upload
  const inputFileRef = useRef(null);
  const handleRemoveImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));

    // Clear the value of the input file element
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveImage();
  };
  const handelGoToUserList = () => {
    handleCloseAddModelPopup();
  };

  const fetchMoreAdminRoles = async (url) => {
    await getAdminRole(url);
  };
  // Get Admin Roles API calls
  const getAdminRole = async (url) => {
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        if (res.status === 401) {
          sessionStorage.removeItem("7b9KpQ3rW6");
          sessionStorage.removeItem("loggedIn");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        } else if (res.status === 200) {
          let data = res.data;
          // let newdataArray = [...new Set(data.data)];
          const filteredData = data.data.filter(
            (user) => user.name !== "master_admin"
          );
          if (data.current_page == 1) {
            setOptionsValue(filteredData);
          } else {
            setOptionsValue((prev) => [...prev, ...filteredData]);
          }

          setHasMoreRoles(data.next_page_url);
        }
        setLoadingMoreRoles(false);
      })
      .catch((err) => {
        setLoadingMoreRoles(false);
        console.error(err);
      });
  };
  useEffect(() => {
    if (optionsValue.length == 0) {
      getAdminRole(adminRolesURL);
    }
  }, []);

  const GetPermissionList = () => {
    const id = permissionIdValue;
    const option = optionsValue.find((option) => option.id == id);
    if (option) {
      const permissions = option.permissions;
      return (
        <div>
          {permissions.map((permission) => (
            <li className="listOfPermission" key={permission}>
              {permission}
            </li>
          ))}
        </div>
      );
    }
    return null;
  };
  const handleSelect = (selectedId) => {
    handleChange({ target: { name: "roleId", value: selectedId } });
  };
  return (
    <>
      <div className="admin_user_register_popup_container">
        <div className="admin_user_register_popup_second">
          <div className="UserheadingandButtondiv">
            <p className="UsersCreatePageHeadingelement"> Add User </p>
            <span
              className="Add_role_cross_icon"
              onClick={() => handelGoToUserList()}
            >
              {" "}
              <FontAwesomeIcon icon={faX} style={{ color: "#9e9e9e" }} />
            </span>
          </div>
          <div className="Edit-user-body">
            <div className="profile-row111">
              <div className="basic-info">
                <div className="profile-input">
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          value={userData.f_name}
                          onChange={handleChange}
                          name="f_name"
                          placeholder="First Name"
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">
                          First Name
                        </span>
                      </label>
                      {firstNameError && (
                        <span className="profile-error-red">
                          {firstNameError}
                        </span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="l_name"
                          value={userData.l_name}
                          placeholder="Last Name"
                          onChange={handleChange}
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">
                          Last Name
                        </span>
                      </label>
                      {lastNameError && (
                        <span className="profile-error-red">
                          {lastNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          onChange={handleChange}
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">Email</span>
                      </label>

                      {emailError && (
                        <span className="profile-error-red">{emailError}</span>
                      )}
                    </div>
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          type="text"
                          name="phone"
                          maxLength={10}
                          placeholder="Phone"
                          className="Admin_user_register_input"
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (!/\d/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span className="Admin_user_register_span">Phone </span>
                      </label>

                      {phoneError && (
                        <span className="profile-error-red">{phoneError}</span>
                      )}
                    </div>
                  </div>
                  <div className="same-line">
                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          type={showResetNewPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">
                          {" "}
                          Password{" "}
                        </span>
                        <span className="password-eye-icons-password">
                          {showResetNewPassword ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              className="fontassowmicon"
                              style={{ color: "#cdcdcd" }}
                              onClick={() => {
                                setShowResetNewPassword(false);
                              }}
                              tabindex="0"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="fontassowmicon"
                              icon={faEyeSlash}
                              style={{ color: "#cdcdcd" }}
                              onClick={() => {
                                setShowResetNewPassword(true);
                              }}
                              tabindex="0"
                            />
                          )}
                        </span>
                      </label>
                      {passwordError && (
                        <span className="profile-error-red password-error-message">
                          {passwordError}
                        </span>
                      )}
                    </div>

                    <div className="input-row">
                      <label className="Admin_user_register_label">
                        <input
                          onChange={handleChange}
                          onKeyPress={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          type={showResetNewConPassword ? "text" : "password"}
                          name="Confirm_password"
                          placeholder="Confirm Password"
                          className="Admin_user_register_input"
                        />
                        <span className="Admin_user_register_span">
                          Confirm Password
                        </span>
                        <span className="password-eye-icons-password">
                          {showResetNewConPassword ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              className="fontassowmicon"
                              style={{ color: "rgb(205, 205, 205)" }}
                              onClick={() => {
                                setShowResetNewConPassword(false);
                              }}
                              tabindex="0"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="fontassowmicon"
                              icon={faEyeSlash}
                              style={{ color: "rgb(205, 205, 205)" }}
                              onClick={() => {
                                setShowResetNewConPassword(true);
                              }}
                              tabindex="0"
                            />
                          )}
                        </span>
                      </label>

                      {confirmPasswordError && (
                        <span className="profile-error-red password-error-message">
                          {confirmPasswordError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="input-rowmain">
                    <div>
                      <label className="Admin_user_register_label password-error-message">
                        <CustomDropdown
                          options={optionsValue}
                          onSelect={handleSelect}
                          hasMoreRoles={hasMoreRoles}
                          fetchMoreAdminRoles={fetchMoreAdminRoles}
                          loadingMoreRoles={loadingMoreRoles}
                          setLoadingMoreRoles={setLoadingMoreRoles}
                        />
                        <span
                          className="Admin_user_register_span"
                          id="AddEditUserRoleName"
                        >
                          Role Name
                        </span>
                      </label>

                      {roleNameError && (
                        <span className="profile-error-red password-error-message">
                          {roleNameError}
                        </span>
                      )}
                      <div>
                        <li className="permision_Heading_user_create">
                          Permissions
                        </li>
                        <GetPermissionList />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-buttons">
            <button
              onClick={() => handelGoToUserList()}
              className="btn secondary-btn"
            >
              Cancel
            </button>
            <button
              className="btn primary-btn"
              onClick={() => {
                setLoadingButton(true);
                createUsers();
              }}
            >
              {loadingButton ? <LoaderButton /> : "Add User"}
            </button>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default UserCreate;
