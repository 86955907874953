import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "./policies.css";
import LoaderButton from "../../../Components/LoaderButton.jsx";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Toast from "../../../Components/Toast.js";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie.jsx";
import InputContext from "../../../Components/InputContext.js";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Policy = ({ isToggleOn, addNew }) => {
  const location = useLocation();
  const { policyId } = useParams();
  const navigate = useNavigate();
  let token = getDataFromCookies("7b9KpQ3rW6");
  const [loading, setLoading] = useState(true);
  const { showToast } = Toast();
  const [isAddButtonDisable, setisAddButtonDisable] = useState(false);
  const [isUpdateButtonDisable, setisUpdateButtonDisable] = useState(false);
  const [policy, setPolicy] = useState({
    name: "",
    module: "",
    content: "",
  });
  const inputRef = useRef(null);
  const [errors, setErrors] = useState({
    name: "",
    module: "",
    content: "",
  });
  const contextObj = useContext(InputContext);
  useEffect(() => {
    contextObj.setInput("isOnAddEditPolicy", true);
    if (!addNew) {
      getPolicyById();
    }
  }, []);

  const getPolicyById = async () => {
    try {
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.crudForPolicies}/${policyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();

      if (response.status === 200) {
        const { name, module, content } = responseData.data;
        setPolicy({ name, module, content });
      } else if (
        response.status === 401 &&
        responseData.status === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        handleUnauthorized();
      } else if (response.status == 401 && response.message !== "") {
        showToast("error", response.message);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleDescriptionChange = (value) => {
    setPolicy((prev) => ({
      ...prev,
      content: value,
    }));
    setErrors((prev) => ({
      ...prev,
      content: "",
    }));
  };

  const handleModuleChange = (event) => {
    const { value } = event.target;
    setPolicy((prev) => ({
      ...prev,
      module: value,
    }));
    setErrors((prev) => ({
      ...prev,
      module: "",
    }));
  };

  const updatePolicyById = async () => {
    try {
      setisUpdateButtonDisable(true);
      const errorHas = validatePolicyData();
      if (errorHas) {
        setisUpdateButtonDisable(false);
        return;
      }
      const response = await fetch(
        `${API_HOST}/${APIEndpoint.crudForPolicies}/${policyId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(policy),
        }
      );
      const responseData = await response.json();
      setisUpdateButtonDisable(false);
      handleResponse(response, responseData);
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const addPolicy = async () => {
    try {
      setisAddButtonDisable(true);
      const errorHas = validatePolicyData();
      if (errorHas) {
        setisAddButtonDisable(false);
        return;
      }
      const response = await fetch(`${API_HOST}/${APIEndpoint.addPolicy}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(policy),
      });
      const responseData = await response.json();
      setisAddButtonDisable(false);
      handleResponse(response, responseData);
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  const validatePolicyData = () => {
    let errorHas = false;
    if (policy.name === "" || policy.name === null) {
      setErrors((prev) => ({
        ...prev,
        name: "Policy Name is required!",
      }));
      errorHas = true;
    }
    if (policy.module === "" || policy.module === null) {
      setErrors((prev) => ({
        ...prev,
        module: "Module Name is required!",
      }));
      errorHas = true;
    }
    if (
      policy.content === "" ||
      policy.content === null ||
      policy.content === "<p><br></p>"
    ) {
      setErrors((prev) => ({
        ...prev,
        content: "Policy Data is required!",
      }));
      errorHas = true;
    }
    return errorHas;
  };

  const handleResponse = (response, responseData) => {
    if (response.status === 200 || response.status === 201) {
      if (response.status === 201) {
        setDataToCookies("isPolicyAdded", true);
      }
      showToast("success", responseData.message);
      navigate("/policies");
    } else if (
      response.status === 401 &&
      responseData.status === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      handleUnauthorized();
    } else if (response.status == 401 && response.message !== "") {
      showToast("error", response.message);
    } else if (response.status === 400) {
      handleResponseErrors(responseData);
    }
  };

  const handleUnauthorized = () => {
    removeDataFromCookie("7b9KpQ3rW6", "/");
    removeDataFromCookie("loggedIn", "/");
    navigate("/");
    showToast("info", "Sessions expired! Please login again");
  };

  const handleResponseErrors = (responseData) => {
    if (responseData.errors) {
      const { errors } = responseData;
      Object.keys(errors).forEach((key) => {
        setErrors((prev) => ({
          ...prev,
          [key]: errors[key][0],
        }));
      });
    }
  };

  return (
    <div className="page-data">
      <div className="main_Product_Container_list">
        <div className="productNameDescriptionDiv">
          <div className="Policy-Name-module-Container">
            <div className="single-row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  id="product-create-update-name"
                  type="text"
                  name="name"
                  value={policy.name}
                  onChange={handleInputChange}
                  placeholder="Policy Title"
                  className="Admin_Profile_input_tag"
                  ref={inputRef}
                />
                <span className="Admin_Profile_span_tag">Policy Title</span>
              </label>
              <label className="red">{errors.name}</label>
            </div>

            <div className="single-row">
              <label className="Admin_Profile_Lable_tag">
                {" "}
                <select
                  className="Admin_Profile_input_tag"
                  id="product-create-update-name"
                  type="text"
                  name="module"
                  value={policy.module}
                  placeholder="Module Name"
                  onChange={handleModuleChange}
                  ref={inputRef}
                >
                  {" "}
                  <option value="">Select</option>
                  <option value="Admin">Admin</option>
                  <option value="Buyer">Buyer </option>
                  <option value="Seller">Seller</option>
                </select>
                <span className="Admin_Profile_span_tag">Module Name </span>
              </label>

              <label className="red">{errors.module}</label>
            </div>
          </div>
          <div className="single-row">
            <label>Policy Data</label>
            <ReactQuill
              id="product-create-update-description"
              className="custom-react-quill"
              name="description"
              value={policy.content}
              onChange={handleDescriptionChange}
              theme="snow"
              style={{
                height: "fitContent",
                background: "#ffffff",
              }}
            />
            <label className="red">{errors.content}</label>
          </div>
        </div>
        <div className="saveUpdateBtnContainer">
          <button
            className="btn secondary-btn"
            onClick={() => {
              setPolicy((prev) => ({
                ...prev,
                name: "",
                content: "",
              }));
              navigate("/policies");
            }}
          >
            Cancel
          </button>
          <button
            className="saveUpdatePolicyBtn"
            onClick={() => {
              addNew ? addPolicy() : updatePolicyById();
            }}
            disabled={isAddButtonDisable || isUpdateButtonDisable}
          >
            {addNew ? (
              isAddButtonDisable ? (
                <LoaderButton />
              ) : (
                "Save"
              )
            ) : isUpdateButtonDisable ? (
              <LoaderButton />
            ) : (
              "Update"
            )}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Policy;
