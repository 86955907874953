import React, { useEffect, useState, useMemo, useContext } from "react";
import FaceRating from "./StarRating";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import NewSellerStory from "./NewStory";
import SellerStoriesEdit from "./EditStory";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import placeHolder from "../../../Assets/image-placeholder.png";
import InputContext from "../../../Components/InputContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";

let API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerStories = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const contextObj = useContext(InputContext);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState("");
  const [name, setName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState("");
  const navigate = new useNavigate();
  const { showToast } = Toast();
  let [currentPage, setCurrentPage] = useState("");
  const [totalCount, setTotalCount] = useState("");
  let [lastPage, setLastPage] = useState("");
  const [pagination, setPagination] = useState("");
  const testimonialsPerPageToShow = 10;
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    fetchData(data);
  };
  const fetchData = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${url}&per_page=${testimonialsPerPageToShow}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      setPagination(res.data);
      setData(res.data.data);
      setCurrentPage(res.data.current_page);
      setLastPage(res.data.last_page);
      setTotalCount(res.data.total);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status === 404) {
      setData([]);
      setPagination("");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    }
    setSearchLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(`${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=1`);
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        fetchData(
          `${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const confirmDelete = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.adminStoriesCrud}/${itemId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", "Testimonial deleted successfully");
      setDataToCookies("isDelete", true);
      // setData(data.filter((product) => product.id !== itemId));
      closeDeleteModal();
    } else if (
      response.status === 401 &&
      response.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("error", "Something went wrong");
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    const isadded = getDataFromCookies("isAddTestomonial");
    if (isadded) {
      fetchData(`${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=1`);
      setShowAddModal(false);
      removeDataFromCookie("isAddTestomonial");
    } else {
      setShowAddModal(false);
    }
  };

  const openEditModal = (id) => {
    setEditId(id);
    setShowEditModal(true);
  };
  const closeEditModel = () => {
    const isEdited = getDataFromCookies("isEditTestomonial");
    if (isEdited) {
      fetchData(
        `${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=${currentPage}`
      );
      removeDataFromCookie("isEditTestomonial");
      setShowEditModal(false);
    } else {
      setShowEditModal(false);
    }
  };

  const openDeleteModal = (id, name) => {
    setItemId(id);
    setName(name);
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setItemId("");
    setName("");
    setShowDeleteModal(false);
    const isDeleted = getDataFromCookies("isDelete");
    if (isDeleted) {
      if (currentPage == lastPage) {
        currentPage =
          totalCount % testimonialsPerPageToShow == 1
            ? currentPage - 1
            : currentPage;
        fetchData(
          `${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=${currentPage}`
        );
      } else {
        fetchData(
          `${API_HOST}/${APIEndpoint.adminStoriesList}?page_no=${currentPage}`
        );
      }
      removeDataFromCookie("isDelete");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * testimonialsPerPageToShow, // Adding 1 to get a 1-based serial number
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => (
          <img
            src={imgHost + "/" + value}
            onError={(e) => {
              e.target.src = placeHolder;
            }}
            alt="Image"
          />
        ),
      },
      {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <span title={value}>
            {value.length > 20 ? value.slice(0, 20) + "..." : value}
          </span>
        ),
      },

      {
        Header: "City",
        Footer: "City",
        accessor: "city",
      },
      {
        Header: "Rating",
        Footer: "Rating",
        accessor: "rating",
        Cell: ({ value }) => (
          <div className="cell-container">
            <FaceRating totalStars={5} rating={value} />
          </div>
        ),
      },
      {
        Header: "Module",
        Footer: "Module",
        accessor: "module",
      },
      {
        Header: "Status",
        accessor: "show_on_page",
        Cell: ({ value }) => {
          let backgroundColor = "";
          let color = "";
          let text = "";
          if (value === 0) {
            backgroundColor = "#c5c5c5";
            color = "#000000";
            text = "Unpublished";
          } else {
            color = "white";
            backgroundColor = "#4CAF50";
            text = "Published";
          }

          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {text}
            </p>
          );
        },
      },
      {
        Header: "Action",
        Footer: "Action",
        Cell: ({ row }) => {
          const id = row.original.id;
          const name = row.original.name;
          return (
            <div>
              <button
                onClick={() => openEditModal(id)}
                className="action-icon edit-button"
                title="Edit"
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>

              <button
                onClick={() => openDeleteModal(id, name)}
                className="action-icon trash-button"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      // pageCount: Math.ceil(data.length / pageSize),
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Testimonials</li>
        </div>
      </div>
      <div className="page-container">
        {/* Filter and Button */}
        <div className="table-serach-area">
          <button
            className="primary-btn"
            onClick={() => {
              openAddModal();
            }}
          >
            Add Testimonial
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        {/* Data table */}
        <div className="InnerTableContainer">
          <table className="order-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* table body logic where data appends */}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            width: cell.column.width,
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pageName={"Testimonials"}
              pagination={pagination}
              pageChange={handleURLChange}
            />
          )}
        </div>

        {/* Add Testimonial Modal */}
        {showAddModal && <NewSellerStory handleCloseAddModel={closeAddModal} />}

        {/* Edit Testimonial Modal */}
        {showEditModal && (
          <SellerStoriesEdit
            handleCloseEditModel={closeEditModel}
            editId={editId}
          />
        )}

        {/* Testimonial Delete Modal */}
        {showDeleteModal && (
          <div
            id="deleteModal"
            className="modal fade"
            style={{ display: showDeleteModal ? "block" : "none" }}
          >
            <div className="modal-content">
              <span className="close" onClick={closeDeleteModal}>
                &times;
              </span>

              <h3 className="modal-header">Delete Confirmation</h3>
              <p className="modal-line">
                Are you sure want to delete{" "}
                <span className="deleteitemsNameappPages">{name}'s</span>{" "}
                testimonial?
              </p>
              <div className="modal-buttons">
                <button
                  className="btn primary-btn"
                  onClick={(e) => {
                    confirmDelete();
                  }}
                >
                  Yes, delete it!
                </button>
                <button
                  className="btn secondary-btn"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SellerStories;
