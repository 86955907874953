import React, { useEffect, useState, useMemo, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Navigation from "../../../config/Navigation.json";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";
import placeHolder from "../../../Assets/image-placeholder.png";
import "./refund-payment.css";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const RefundPayments = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = Toast();
  const [filterStatus, setFilterStatus] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchApplied, setIsSearchApplied] = useState(false);

  // handle pagination
  const dataPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState("");

  const getRefundPayments = async (url = null) => {
    if (!url) {
      url = `${API_HOST}/api/admin/refunds?page_no=${currentPage}&per_page=${dataPerPage}`;
      if (searchQuery !== "") url += "&search=" + searchQuery;
      if (filterStatus !== "") url += "&status=" + filterStatus;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getDataFromCookies("7b9KpQ3rW6")}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      const resData = res.data.data;
      setData(resData);
      setPagination(res.data);
      setCurrentPage(res.data.current_page);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status === 404) {
      setData([]);
      setPagination("");
    } else {
      console.error("Something went wrong");
    }
    setLoading(false);
    setSearchLoading(false);
  };

  useEffect(() => {
    getRefundPayments();
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        setCurrentPage(1);
        getRefundPayments();
      }, 500);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setLoading(true);
      setCurrentPage(1);
      getRefundPayments();
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [filterStatus]);

  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getRefundPayments(data);
  };

  const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) {
      return "";
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * dataPerPage,
      },

      {
        Header: "Image",
        accessor: "product_details.thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${imgHost}/${value}`}
            onError={(e) => {
              e.target.src = placeHolder;
            }}
          />
        ),
      },
      {
        Header: "Product",
        accessor: "product_details.name",
        Cell: ({ value }) => <div className="product-name">{value}</div>,
      },
      {
        Header: "Order No.",
        accessor: "order_number",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: "Refund Amount",
        accessor: "refund_amount",
        Cell: ({ value }) => (
          <div>₹{formatNumberWithCommas(Math.round(value))}</div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color = "white";
          let text;
          if (value === "processed") {
            text = "Processed";
            backgroundColor = "#4CAF50";
          } else if (value === "Refunded") {
            text = "Refunded";
            backgroundColor = "#9C27B0";
          } else {
            text = value;
            backgroundColor = "#9C27B0";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {text}
            </p>
          );
        },
      },
    ],
    [selectedProductId, currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: dataPerPage, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Customer Refunds</li>
        </div>
      </div>
      <div className="page-container">
        <div className="table-serach-area">
          <div style={{ display: "flex", gap: "5px" }}>
            <select
              className="btn form-control"
              value={filterStatus}
              style={{ width: "fit-content" }}
              onChange={(e) => {
                const val = e.target.value;
                if (val !== "") {
                  setFilterStatus(val);
                  setLoading(true);
                  setIsSearchApplied(true);
                }
              }}
            >
              <option value="">Select</option>
              <option value="initiated">Initiated</option>
              <option value="pending">Pending</option>
              <option value="processed">Processed</option>
              <option value="failed">Failed</option>
            </select>
            {filterStatus && (
              <button
                type="submit"
                className="btn clear-btn"
                style={{ color: "red", fontWeight: "400" }}
                onClick={() => {
                  setFilterStatus("");
                }}
              >
                Clear
              </button>
            )}
          </div>

          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>
        <div className="Refund-table-container">
          {/* Data table */}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.Header !== "" && column.Header !== "Action"
                          ? {}
                          : {}
                      )}
                    >
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* table body logic where data appends */}

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                // Check if there is data in the current page
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Refunds"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RefundPayments;
