import { useContext, useEffect, useState } from "react";
import APIEndpoint from "../../../config/APIEndpoints.json";
import stringConstant from "../../../config/ProjectConstants.json";
import Navigation from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import LoaderButton from "../../../Components/LoaderButton";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const NewCategory = ({ handleCloseAddModel, parentCategories }) => {
  const location = useLocation();
  const [thumbnailError, setThumbnailError] = useState("");
  const [addCategoryError, setAddCatogeryError] = useState("");
  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [category, setCategory] = useState({
    name: "",
    parent_category_id: "",
    thumb_image: null,
  });
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    setAddCatogeryError("");
    setThumbnailError("");
    const { name, value } = event.target;
    if (name === "thumb_image") {
      const file = event.target.files[0];
      setCategory((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (name === "parent_category") {
      setCategory((prevData) => ({
        ...prevData,
        parent_category_id: value,
      }));
    } else {
      if (value.trim() !== "" || value == "") {
        setCategory((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  // new logic for thumb image upload starts from here.
  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp", "gif"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          setThumbnailError(
            "Please upload an image with .png, .jpg, .jpeg, .webp, .gif extension."
          );
          return;
        }
        if (file.size > maxFileSize) {
          compressImage(file, maxFileSize);
        } else {
          readFile(file);
        }
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800;
          const maxHeight = 800;
          let width = image.width;
          let height = image.height;

          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                return;
              }
              setCategory((prevData) => ({
                ...prevData,
                thumb_image: compressedFile,
              }));
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  const PostCategoryData = async () => {
    let errorHas = false;

    if (!category.name) {
      setAddCatogeryError(
        stringConstant.errorMessages.errorForBlankCategoryName
      );
      errorHas = true;
    } else {
      setAddCatogeryError(stringConstant.errorMessages.errorForBlankSpace);
    }

    if (category.name === "" || category.name === null) {
      setAddCatogeryError(
        stringConstant.errorMessages.errorForBlankCategoryName
      );
      errorHas = true;
    } else if (category.name.length < 3 || category.name.length > 50) {
      setAddCatogeryError(
        stringConstant.errorMessages.alertForCategoryNameLength
      );
      errorHas = true;
    } else {
      setAddCatogeryError("");
    }
    if (category.thumb_image == null) {
      setThumbnailError(
        stringConstant.errorMessages.errorForBlankCategoryImage
      );
      errorHas = true;
    }
    if (errorHas == true) {
      setLoadingButton(false);
      return;
    }

    const formData = new FormData();
    formData.append("name", category.name);
    formData.append("image", category.thumb_image);
    if (category.parent_category_id !== 0) {
      formData.append("parent_category_id", category.parent_category_id);
    }

    await fetch(`${API_HOST}/${APIEndpoint.crudCategory}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadingButton(false);
        if (data.status === 201) {
          setDataToCookies("isCategoryCreated", true);
          setContainer1((prevContainer) => ({
            ...prevContainer,
            image: null,
          }));
          setCategory((prevData) => ({
            ...prevData,
            name: "",
            thumb_image: null,
          }));
          setAddCatogeryError("");

          showToast(
            "success",
            stringConstant.alertErrorMessage.alertForCategoryCreated
          );
          handleCloseAddModel();
        } else if (data.status === 400) {
          if (data.errors.name) {
            setAddCatogeryError(data.errors.name[0]);
          } else if (data.errors.image) {
            setThumbnailError(data.errors.image[0]);
          }
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
      })
      .catch((err) => console.error(err));
  };

  // img remove after upload
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setCategory((prevData) => ({
      ...prevData,
      thumb_image: null,
    }));
    // Clear the value of the input file element
    const thumbImageInput = document.getElementById("thumb-img-input");
    if (thumbImageInput.value) {
      thumbImageInput.value = null;
    }
  };

  useEffect(() => {
    setDataToCookies("isCategoryCreated", false);
  }, []);

  return (
    <div className="modailmaindiv" style={{ border: "1px solid green" }}>
      <div className="AdminEditBrandBox">
        <h3 className="category-modal-heading">Add Category</h3>

        <span className="modal-close" onClick={handleCloseAddModel}>
          <FontAwesomeIcon icon={faTimes} />
        </span>

        <label htmlFor="">Name</label>
        <input
          type="text"
          name="name"
          value={category.name}
          className="Add_category_input"
          onChange={handleInputChange}
          placeholder="Enter name"
        />
        {addCategoryError && <div className="red">{addCategoryError}</div>}

        <label htmlFor="">Parent (Optional)</label>
        <select
          name="parent_category"
          className="Add_category_input"
          placeholder="Parent Category"
          onChange={handleInputChange}
          onClick={handleInputChange}
        >
          <option value="">Select</option>
          {parentCategories &&
            parentCategories.map((parentCategory) => {
              return (
                <option key={parentCategory.id} value={parentCategory.id}>
                  {parentCategory.name}
                </option>
              );
            })}
        </select>

        <div className="Category_thumbnailOuterContainer">
          <div>
            <label>Image</label>
            <div
              onClick={thumbImgUpload}
              className="modal-dropzone"
              onMouseEnter={handleMouseEnterThumbImg}
              onMouseLeave={handleMouseLeaveThumbImg}
            >
              {container1.image ? (
                <div className="Category_image-placeholderForThumbnail">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      className="Category_image-storing-containerForThumbnail"
                      src={container1.image}
                      loading="lazy"
                    />
                  </div>
                  {container1.hovering && (
                    <button
                      className="delete-button"
                      onClick={handleDeleteThumbClick}
                    >
                      Remove Image
                    </button>
                  )}
                </div>
              ) : (
                <p className="click-to-upload">Click to upload</p>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp, .gif"
                name="thumb_image"
                onChange={handleInputChange}
                id="thumb-img-input"
                hidden
              />
            </div>
            {thumbnailError && <label className="red">{thumbnailError}</label>}
          </div>
        </div>

        <div className="modal-buttons">
          <button
            type="button"
            className="btn secondary-btn"
            onClick={handleCloseAddModel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn primary-btn"
            onClick={() => {
              setLoadingButton(true);
              PostCategoryData();
            }}
          >
            {loadingButton ? <LoaderButton /> : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewCategory;
