import React, { useEffect, useState, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Navigation from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
import APIEndpoint from "../../../config/APIEndpoints.json";
import "./editProductsCollection.css";

import Card from "./Card";
import ToggelCustomBtn from "./switchBtn";
import CollectionProductsListModal from "./addProductToCollectionModal";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const EditProductsCollection = () => {
  const location = useLocation();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const { collectionId } = useParams();

  const [productsCollection, setProductsCollection] = useState({
    products: [],
    description: "",
    title: "",
    type: "linear",
    visible: "1",
    position: "",
  });
  const [showAddProducts, setShowAddProducts] = useState(false);
  const [initialProducts, setInitialProducts] = useState([]);
  let [selectedProducts, setSelectedProducts] = useState([]);
  const createProductsCollection = async () => {
    collectionErrors((prev) => ({
      ...prev,
      title: "",
      description: "",
      position: "",
    }));
    let productIds = [];
    productsCollection.products.forEach((product) => {
      productIds.push(product.id);
    });
    let payload = {
      product_ids: productIds,
      title: productsCollection.title,
      description: productsCollection.description,
      type: "linear",
      position: productsCollection.position,
      visible: productsCollection.visible,
    };

    try {
      await fetch(`${API_HOST}/${`api/admin/collection`}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.status == 201) {
            showToast("success", "Collection Created");
            navigate("/products/collections");
          } else if (res.status == 400) {
            let err = res.errors;
            if (err) {
              if (err.product_ids) {
                showToast(
                  "info",
                  "Collection can not be empty. Add products to continue"
                );
              }
              if (err.title) {
                collectionErrors((prev) => ({
                  ...prev,
                  title: err.title[0],
                }));
              }
              if (err.position) {
                collectionErrors((prev) => ({
                  ...prev,
                  position: err.position[0],
                }));
              }
              if (err.description) {
                collectionErrors((prev) => ({
                  ...prev,
                  description: err.description[0],
                }));
              }
            }
          } else if (
            res.status === 401 &&
            res.message === "You are not authorized."
          ) {
            contextObj.setInput("prevPath", location.pathname);
            alert("removeDataFromCookie in edit page");
            removeDataFromCookie("7b9KpQ3rW6", "/");
            removeDataFromCookie("loggedIn", "/");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            navigate(Navigation.login);
            showToast("info", "Sessions expired!");
          } else if (res.status == 401 && res.message !== "") {
            showToast("error", res.message);
          } else if (
            res.status === 404 &&
            res.message === "No categories found"
          ) {
            setProductsCollection([]);
          }
        });
    } catch (error) {
      console.error("error ======>", error);
    }
  };
  const updateProductsCollection = async () => {
    collectionErrors((prev) => ({
      ...prev,
      title: "",
      description: "",
      position: "",
    }));
    let productIds = [];
    productsCollection.products.forEach((product) => {
      productIds.push(product.id);
    });
    let payload = {
      product_ids: productIds,
      title: productsCollection.title,
      description: productsCollection.description,
      type: "linear",
      position: productsCollection.position,
      visible: productsCollection.visible,
    };

    try {
      await fetch(`${API_HOST}/${`api/admin/collection`}/${collectionId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.status == 200) {
            showToast("success", "Collection updated");
            navigate("/products/collections");
          } else if (res.status == 400) {
            let err = res.errors;
            if (err) {
              if (err.product_ids) {
                showToast(
                  "info",
                  "Collection can not be empty. Add products to continue"
                );
              }
              if (err.title) {
                collectionErrors((prev) => ({
                  ...prev,
                  title: err.title[0],
                }));
              }
              if (err.position) {
                collectionErrors((prev) => ({
                  ...prev,
                  position: err.position[0],
                }));
              }
              if (err.description) {
                collectionErrors((prev) => ({
                  ...prev,
                  description: err.description[0],
                }));
              }
            }
          } else if (
            res.status === 401 &&
            res.message === "You are not authorized."
          ) {
            contextObj.setInput("prevPath", location.pathname);

            removeDataFromCookie("7b9KpQ3rW6", "/");
            removeDataFromCookie("loggedIn", "/");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            navigate(Navigation.login);
            showToast("info", "Sessions expired!");
          } else if (res.status == 401 && res.message !== "") {
            showToast("error", res.message);
          } else if (
            res.status === 404 &&
            res.message === "No categories found"
          ) {
            setProductsCollection([]);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  const getCollectionById = async () => {
    try {
      await fetch(
        `${API_HOST}/${APIEndpoint.getProductsCollection}/${collectionId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.status == 200) {
            setProductsCollection(res.data);
            if (res.data && res.data.products) {
              setInitialProducts(res.data.products);
            }
          } else if (
            res.status === 401 &&
            res.message === "You are not authorized."
          ) {
            contextObj.setInput("prevPath", location.pathname);
            removeDataFromCookie("7b9KpQ3rW6", "/");
            removeDataFromCookie("loggedIn", "/");
            contextObj.setInput("navigation", "true");
            contextObj.setInput("navigationFrom", "sessionExpired");
            navigate(Navigation.login);
            showToast("info", "Sessions expired!");
          } else if (res.status == 401 && res.message !== "") {
            showToast("error", res.message);
          } else if (
            res.status === 404 &&
            res.message === "No categories found"
          ) {
            setProductsCollection([]);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (collectionId !== undefined) {
      getCollectionById();
    }
  }, [collectionId]);
  useEffect(() => {
    if (showAddProducts) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showAddProducts]);
  function removeDuplicates(array) {
    const uniqueObjects = new Set(array.map((obj) => JSON.stringify(obj)));

    const uniqueArray = Array.from(uniqueObjects, (obj) => JSON.parse(obj));

    return uniqueArray;
  }
  useEffect(() => {
    if (productsCollection && !showAddProducts) {
      let prevProducts = productsCollection.products;
      let updatedProducts = [...prevProducts, ...selectedProducts];

      updatedProducts = removeDuplicates(updatedProducts);

      setProductsCollection((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
    }
  }, [showAddProducts]);

  let [errors, collectionErrors] = useState({
    title: "",
    description: "",
    position: "",
  });
  return (
    <div className="page-data">
      <div className="prevPageBtnContainer">
        <p className="collectionProductsListTitle">
          {collectionId !== undefined
            ? "Update Collection"
            : "Create New Collection"}
        </p>

        <div
          className="primary-btn"
          onClick={() => {
            navigate("/products/collections");
          }}
        >
          Go To Collection List
        </div>
      </div>
      <div className="editCollectionContainer">
        <div className="editCollectionDetails">
          <p className="collectionProductsListTitle">Collection Details</p>
          <div className="CollectionDetailsInputHolder">
            <div className="input-row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="f_name"
                  className="Admin_Profile_input_tag"
                  onChange={(event) => {
                    collectionErrors((prev) => ({
                      ...prev,
                      title: "",
                    }));
                    setProductsCollection((prev) => ({
                      ...prev,
                      title: event.target.value,
                    }));
                  }}
                  value={productsCollection && productsCollection.title}
                  placeholder="Collection Title"
                />
                <span className="Admin_Profile_span_tag">Collection Title</span>
              </label>
              {errors.title !== "" && (
                <span className="profile-error-red">{errors.title}</span>
              )}
            </div>

            <div className="input-row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="f_name"
                  className="Admin_Profile_input_tag"
                  onChange={(event) => {
                    collectionErrors((prev) => ({
                      ...prev,
                      description: "",
                    }));
                    setProductsCollection((prev) => ({
                      ...prev,
                      description: event.target.value,
                    }));
                  }}
                  value={productsCollection && productsCollection.description}
                  placeholder="Collection Description"
                />
                <span className="Admin_Profile_span_tag">
                  Collection Description
                </span>
              </label>
              {errors.description !== "" && (
                <span className="profile-error-red">{errors.description}</span>
              )}
            </div>
          </div>
          <div className="CollectionDetailsInputHolder">
            <div className="input-row">
              <label className="Admin_Profile_Lable_tag">
                <input
                  type="text"
                  name="f_name"
                  className="Admin_Profile_input_tag"
                  onChange={(event) => {
                    collectionErrors((prev) => ({
                      ...prev,
                      position: "",
                    }));
                    setProductsCollection((prev) => ({
                      ...prev,
                      position: event.target.value,
                    }));
                  }}
                  value={productsCollection && productsCollection.position}
                  placeholder="Collection Position"
                />
                <span className="Admin_Profile_span_tag">
                  Collection Position
                </span>
              </label>
              {errors.position !== "" && (
                <span className="profile-error-red">{errors.position}</span>
              )}
            </div>
            <div className="visibilityControlerContainer">
              <p>Is Visible?</p>
              <div className="toggelButtonContainer">
                <ToggelCustomBtn
                  checked={productsCollection && productsCollection.visible}
                  changeValue={setProductsCollection}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="collectionProductsListContainer">
          <div className="collectionProductsListHeader">
            <p className="collectionProductsListTitle">Products List</p>
            <div className="updateCollectionBtnHolder">
              <div
                className={`AddProductToCollectionBtn primary-btn ${
                  productsCollection &&
                  productsCollection.products &&
                  productsCollection.products.length >= 20
                    ? "disabledBtn"
                    : ""
                }`}
                onClick={() => {
                  if (productsCollection.products.length < 20) {
                    setShowAddProducts(true);
                  }
                }}
              >
                Add Product
              </div>

              <div
                className="AddProductToCollectionBtn primary-btn"
                onClick={() => {
                  if (collectionId !== undefined) {
                    updateProductsCollection();
                  } else {
                    createProductsCollection();
                  }
                }}
              >
                {collectionId !== undefined
                  ? "Update Collection"
                  : "Create Collection"}
              </div>
            </div>
          </div>
          <div className="collectionProductsList">
            {productsCollection &&
              productsCollection.products &&
              productsCollection.products.length > 0 &&
              productsCollection.products.map((product, index) => {
                return (
                  <Card
                    item={product}
                    cardIndex={index}
                    productsList={productsCollection.products}
                    productsCollection={setProductsCollection}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    removeFromList={true}
                  />
                );
              })}
          </div>
          {productsCollection &&
            productsCollection.products &&
            productsCollection.products.length == 0 && (
              <div className="noProductsInCollection">
                <p className="noProductsInCollectionTxt">
                  No products in the collection. Add products to continue.
                </p>
              </div>
            )}
        </div>
      </div>

      {showAddProducts && (
        <CollectionProductsListModal
          closeShowAddProducts={setShowAddProducts}
          filterProducts={productsCollection && productsCollection.products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          initialProducts={initialProducts}
        />
      )}
    </div>
  );
};

export default EditProductsCollection;
