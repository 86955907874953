import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Navigation from "../../../config/Navigation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigate from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import "../Orders/order.css";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";
import imgHost from "../../../Components/ReUsed";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Donations = () => {
  const { showToast } = Toast();
  const location = useLocation();
  const navigate = new useNavigate();
  const contextObj = useContext(InputContext);

  const queryParams = new URLSearchParams(location.search);
  let currentStatus = queryParams.get("status");
  const filterStatus = currentStatus ? currentStatus : "All";

  let [data, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderCount, setOrderCount] = useState({});
  const [pagination, setPagination] = useState("");
  let [currentPage, setCurrentPage] = useState(1);
  const [tablePageIndex, setTablePageIndex] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(15);
  const [searchLoading, setSearchLoading] = useState(false);
  let [searchQuery, setSearchQuery] = useState("");
  const [isSearchApply, setIsSearchApply] = useState(false);

  const [enabledFilter, setEnabledFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterLength, setFilterLength] = useState(0);

  let url = `${API_HOST}/${APIEndpoint.donations}?page=${currentPage}&per_page=${ordersPerPage}`;

  const fetchDonations = async (url) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    if (searchQuery !== "") {
      url = url + "&search=" + searchQuery;
    }
    setLoading(true);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setPagination(res.data.donations);
        const dataArray = res.data.donations.data;
        const updatedData = [...[], ...dataArray];
        setOrderData(updatedData);
        setOrderCount(res.data.counts);
        setCurrentPage(res.data.donations.current_page);
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status == 401 && res.message !== "") {
        showToast("error", res.message);
      } else if (res.status == 400) {
        showToast("error", res.message);
      } else if (res.status === 404) {
        setOrderData([]);
        setPagination("");
        setOrderCount(res.data.counts);
      } else {
        console.error(res);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setSearchLoading(false);
    setLoading(false);
  };

  const handleURLChange = (url) => {
    window.scrollTo(0, 0);
    fetchDonations(url);
  };

  // set orderStatus on sidebar menu click
  useEffect(() => {
    setSearchQuery("");
    setTablePageIndex(0);
    fetchDonations(url);
  }, [filterStatus]);

  // handle search api calls
  useEffect(() => {
    if (isSearchApply) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        data = [];
        currentPage = 1;
        let url = `${API_HOST}/${APIEndpoint.donations}?page=${currentPage}&per_page=${ordersPerPage}`;
        if (searchQuery !== "") {
          url = url + "&search=" + searchQuery;
          fetchDonations(url);
        } else {
          fetchDonations(url);
        }
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        Footer: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * ordersPerPage,
      },
      {
        Header: "Image",
        accessor: "product.thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${imgHost}/${value}`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Product",
        accessor: "product.name",
        cell: ({ value }) => (
          <div id="sellers-productName-headingDiv" title={value}>
            {value}
          </div>
        ),
      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }) => (
          <>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <>
            {value === "Pending" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffe082" }}
              >
                Pending
              </p>
            ) : value === "Delivered" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "green", color: "white" }}
              >
                Delivered
              </p>
            ) : value === "Confirmed" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#c3e5a0" }}
              >
                Confirmed
              </p>
            ) : value === "Out For Delivery" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#9575CD", whiteSpace: "nowrap" }}
              >
                Out For Delivery
              </p>
            ) : value === "Cancelled" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffbcbc", whiteSpace: "nowrap" }}
              >
                Cancelled
              </p>
            ) : (
              <p
                className="order-status"
                style={{ backgroundColor: "#cbcbcb" }}
              >
                {value}
              </p>
            )}
          </>
        ),
      },
      {
        Header: "Actions",
        Footer: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <button
            onClick={() => {
              navigate(`${Navigate.donations}/${value}`);
            }}
            className="action-icon eye-button"
            title="View"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tablePageIndex,
        pageSize: ordersPerPage,
        globalFilter: "",
      },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const notNullKeys = Object.keys(filters).filter(
      (key) => filters[key] !== ""
    );
    setFilterLength(notNullKeys.length);
  }, [filters]);

  const resetFilters = () => {
    setFilters({});
    fetchDonations(url);
  };

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Donations</li>
        </div>
      </div>

      <div className="page-container">
        <div className="table-serach-area">
          {/* search bar */}

          <div className="first-area">
            <div className="search-bar">
              <div className="fontAwsomeDiv">
                {searchLoading ? (
                  <LoaderButton />
                ) : (
                  <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
                )}
              </div>
              <input
                id="seller-searchForProducts"
                placeholder="Search..."
                className="search-input-order"
                name="search-query"
                value={searchQuery}
                onChange={(e) => {
                  e.preventDefault();
                  const val = e.target.value;
                  setSearchQuery(val);
                  setIsSearchApply(true);
                }}
              />
            </div>
            <button
              className="table-filter"
              onClick={(e) => {
                e.preventDefault();
                setEnabledFilter(!enabledFilter);
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters{" "}
              {filterLength > 0 && `(${filterLength})`}
            </button>
            <button
              className="filter-reset"
              onClick={(e) => {
                e.preventDefault();
                resetFilters();
                setEnabledFilter(false);
              }}
            >
              Reset All
            </button>
          </div>
          <button
            className="btn primary-btn"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate("/donate");
            }}
          >
            New Donation
          </button>
        </div>

        {enabledFilter && (
          <div className="filter-bar">
            <div className="filter-header">
              <h3>Filters</h3>
              <span
                className="filter-area-close"
                onClick={(e) => {
                  e.preventDefault();
                  setEnabledFilter(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <div className="filter-body">
              <div>
                <label>Donated By</label>
                <select
                  name="donated_by"
                  value={filters.donated_by}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Seller">Seller</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div>
                <label htmlFor="">Initiated By</label>
                <select
                  name="initiated_by"
                  value={filters.initiated_by}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Seller">Seller</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div>
                <label htmlFor="">Status</label>
                <select
                  name="status"
                  value={filters.status}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Confirmed">Confirmed</option>
                  <option value="Seller Pickup Processing">
                    Seller Pickup Processing
                  </option>
                  <option value="Seller Pickup Completed">
                    Seller Pickup Completed
                  </option>
                  <option value="Seller Return Processing">
                    Seller Return Processing
                  </option>
                  <option value="Seller Return Completed">
                    Seller Return Completed
                  </option>
                  <option value="NGO Delivery Processing">
                    NGO Delivery Processing
                  </option>
                  <option value="Out For Delivery">Out For Delivery</option>
                  <option value="Delivered">Delivered</option>
                  <option value="NGO Return Processing">
                    NGO Return Processing
                  </option>
                  <option value="NGO Return Completed">
                    NGO Return Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <div className="filter-footer">
              <button
                className="filter-area-reset btn secondary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  resetFilters();
                  setEnabledFilter(false);
                }}
              >
                Reset All
              </button>
              <button
                type="button"
                className="btn primary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (filterLength > 0) {
                    if (filters.donated_by)
                      url += `&donated_by=${filters.donated_by}`;
                    if (filters.initiated_by)
                      url += `&initiated_by=${filters.initiated_by}`;
                    if (filters.status) url += `&status=${filters.status}`;
                    fetchDonations(url);
                    setEnabledFilter(false);
                  }
                }}
                style={{
                  opacity: filterLength > 0 ? 1 : 0.5,
                  pointerEvents: filterLength < 1 && "none",
                }}
              >
                Apply Filters
              </button>
            </div>
          </div>
        )}

        {/* Data table */}
        <div className="InnerTableContainer">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="TableHeading"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}

        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={contextObj.inputValue.activeOrderFilter + " Donations"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Donations;
