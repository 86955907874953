import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getDataFromCookies } from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import "../../../Assets/style.css";

import "cropperjs/dist/cropper.css";

// import constant
import IMG_HOST from "../../../Components/ReUsed";
const API_HOST = process.env.REACT_APP_HOST_API_URL;
const BUYER_APP = process.env.REACT_APP_BUYER_DOMAIN;

const SellerProductEdit = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const { slug } = useParams();
  const { showToast } = Toast();

  useEffect(() => {
    const urls = slug.split("-");
    const productId = urls[urls.length - 2];
    getProductDetails(productId);
  }, []);

  // save new products
  const [product, setProduct] = useState({
    name: "",
    status: "",
    description: "",
    category_id: "",
    brand_id: "",
    condition: "",
    product_price: "",
    current_market_price: "",
    age_group_id: "",
    thumb_image: null,
    video_url: "",
    platform_charge_type: "",
    platform_charge: "",
    gst: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    warehouse_id: "",
    is_donatable: 0,
    is_negotiable: 0,
    sku: "",
    barcode: "",
    status: "",
    hsn: "",
    denied_note: "",
    meta_title: "",
    meta_description: "",
  });
  const [openSeoEdit, setOpenSeoEdit] = useState(false);
  const metaTitleMaxLength = 70;
  const metaDescMaxLength = 320;
  const [metaTitleRemaining, setMetaTitleRemaining] =
    useState(metaTitleMaxLength);
  const [metaDescRemaining, setMetaDescRemaining] = useState(metaDescMaxLength);

  const [imgContainer, setImgContainer] = useState({
    thumb_image: null,
    front_image: null,
    left_image: null,
    right_image: null,
    back_image: null,
    top_image: null,
  });

  const setImagePath = (images) => {
    if (images.thumb_image !== "null" && images.thumb_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        thumb_image: IMG_HOST + "/" + images.thumb_image,
      }));
    }
    if (images.front_image !== "null" && images.front_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        front_image: IMG_HOST + "/" + images.front_image,
      }));
    }
    if (images.left_image !== "null" && images.left_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        left_image: IMG_HOST + "/" + images.left_image,
      }));
    }
    if (images.right_image !== "null" && images.right_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        right_image: IMG_HOST + "/" + images.right_image,
      }));
    }
    if (images.back_image !== "null" && images.back_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        back_image: IMG_HOST + "/" + images.back_image,
      }));
    }
    if (images.top_image !== "null" && images.top_image !== null) {
      setImgContainer((prev) => ({
        ...prev,
        top_image: IMG_HOST + "/" + images.top_image,
      }));
    }
  };

  // Get product details
  const getProductDetails = async (productId) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.getSellerProducts}/${productId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await response.json();
    if (res.status === 200) {
      setProduct(res.data);
      if (res.data) {
        setProduct(res.data);
        setImagePath(res.data);
      }
    } else {
      showToast("info", "Something went wrong");
    }
  };

  // Featch Category API TO show List of All Category Element;
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoriesData = async () => {
    await fetch(`${API_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setCategoryData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })

      .catch((err) => console.error(err));
  };

  // Featch Brand API TO show List of All Brand List;
  const [brandData, setBrandData] = useState([]);

  const getAllBrandList = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    await fetch(`${API_HOST}/${APIEndpoint.getBrandsList}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setBrandData(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [conditionData, setConditionData] = useState([]);

  const getAllConditionsList = async () => {
    await fetch(`${API_HOST}/api/conditions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          const titlesArray = dataArray.map((item) => item.title);
          setConditionData(titlesArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  // Featch Conditions API TO show List of All Conditions List;
  const [applicableAgeGroupData, setapplicableAgeGroupData] = useState([]);
  const getAllAplplicableAgeGroupList = async () => {
    const response = await fetch(`${API_HOST}/api/applicable-age-groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setapplicableAgeGroupData(res.data);
    }
  };

  const [gstPercentage, setGstPercentage] = useState([]);
  const getGSTpercentage = async () => {
    await fetch(`${API_HOST}/api/gst-percents`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setGstPercentage(data.data);
      })
      .catch((err) => console.error(err));
  };

  let token = getDataFromCookies("7b9KpQ3rW6");
  const [warehouseOptionsValue, setWarehouseOptionsValue] = useState([]);
  const getWereHouseAddress = async () => {
    await fetch(`${API_HOST}/api/admin/warehouses`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setWarehouseOptionsValue(dataArray);
        } else {
          console.error("Data is not available");
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAllCategoriesData();
    getAllBrandList();
    getAllConditionsList();
    getAllAplplicableAgeGroupList();
    getGSTpercentage();
    getWereHouseAddress();
  }, []);

  // generate slug URL
  const getSlugURL = (productName) => {
    if (productName) {
      let slug = productName.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
      return slug.replace(/-+/g, "-").replace(/^-|-$/g, "");
    }
  };

  // Remove html tags
  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <div className="page-data">
      <div className="page-heading" style={{ marginBottom: "25px" }}>
        <div className="breadcrumb">
          <li
            onClick={() => {
              window.history.back();
            }}
          >
            Products
          </li>
          <li className="active">View Product</li>
        </div>
        <button
          type="button"
          onClick={() => window.history.back()}
          className="primary-btn"
        >
          Product List
        </button>
      </div>

      {/* Product read-only note */}
      {product &&
        (product.status === "Hold" ||
          product.status === "Sold" ||
          product.status === "Unavailable" ||
          product.status === "Donated") && (
          <div className="alert primary-alert">
            You can't save your changes right now because the product details is
            in read-only mode.
          </div>
        )}

      {/* Product denied note */}
      {product.denied_note && product.status === "Unavailable" && (
        <div className="alert danger-alert">{product.denied_note}</div>
      )}

      <div className="page-container">
        <div className="page-content">
          <div className="left-area">
            {/* name, description */}
            <div className="small-box">
              <label>
                Product Name <span className="red">*</span>
              </label>
              <input
                disabled={true}
                type="text"
                name="name"
                maxLength="150"
                value={product.name}
                className="form-control"
              />
              <label className="mt-10">Description </label>
              <ReactQuill
                readOnly
                disabled={true}
                name="description"
                value={product.description}
                theme="snow"
                style={{ height: "fitContent" }}
                className="custom-react-quill"
              />
            </div>

            {/* images, video URL */}
            <div className="small-box">
              <div className="product-images">
                <div>
                  <label>Thumbnail </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.thumb_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.thumb_image && (
                      <img
                        className="product-image"
                        src={imgContainer.thumb_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Front View </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.front_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.front_image && (
                      <img
                        className="product-image"
                        src={imgContainer.front_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Left View </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.left_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.left_image && (
                      <img
                        className="product-image"
                        src={imgContainer.left_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Right View </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.right_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.right_image && (
                      <img
                        className="product-image"
                        src={imgContainer.right_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Back View </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.back_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.back_image && (
                      <img
                        className="product-image"
                        src={imgContainer.back_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label>Top View </label>
                  <div
                    className={
                      "image-dropzone " +
                      (imgContainer.top_image && "dropzone-uploaded")
                    }
                  >
                    {imgContainer.top_image && (
                      <img
                        className="product-image"
                        src={imgContainer.top_image}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="video-url">
                <label className="mt-10">Video URL</label>
                <input
                  disabled={true}
                  type="text"
                  name="video_url"
                  value={product.video_url}
                  className="form-control"
                />
              </div>
            </div>

            {/* Product Price, Estimated Original Price, Warehouse */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>Product Price (₹)</label>
                  <input
                    disabled={true}
                    type="text"
                    name="product_price"
                    min="100"
                    value={product.product_price}
                    className="form-control"
                  />
                </div>
                <div>
                  <label>Estimated Original Price (₹) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="current_market_price"
                    value={product.current_market_price}
                    min="200"
                    className="form-control"
                  />
                </div>
                <div>
                  <label>Warehouse </label>
                  <select
                    disabled={true}
                    className="form-control warehouse-select"
                    name="warehouse_id"
                    value={product.warehouse_id}
                  >
                    <option value="">Select</option>
                    {warehouseOptionsValue.map((option) => {
                      return (
                        <option key={option.id} value={option.id}>
                          {option.warehouse_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            {/* GST, Platform Charge Type and Value */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>GST Percentage </label>
                  <select
                    disabled={true}
                    value={product.gst}
                    name="gst"
                    className="form-control gst-percentage"
                  >
                    <option value="">Select</option>
                    {Object.entries(gstPercentage).map(([key, value]) => (
                      <option key={key} value={value}>
                        {`${value}%`}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label>Platform Charges </label>
                  <div className="platform-charge-area">
                    <select
                      disabled={true}
                      value={product.platform_charge_type}
                      name="platform_charge_type"
                      className="form-control platform-charge-type"
                    >
                      <option value="">Select</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                    <input
                      disabled={true}
                      value={product.platform_charge}
                      name="platform_charge"
                      type="text"
                      className="form-control platform-charge"
                    />
                  </div>
                </div>
                <div>
                  <label>Selling Price (₹)</label>
                  <input
                    disabled={true}
                    type="number"
                    name="selling_price"
                    value={product.selling_price}
                    className="form-control"
                    style={{ backgroundColor: "#e7e7e7" }}
                  />
                </div>
              </div>
            </div>

            {/* SKU, Barcode, HSN */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>SKU</label>
                  <input
                    disabled={true}
                    type="text"
                    name="sku"
                    className="form-control"
                    value={product.sku}
                  />
                </div>
                <div>
                  <label>Barcode</label>
                  <input
                    disabled={true}
                    type="text"
                    name="barcode"
                    className="form-control"
                    value={product.barcode}
                  />
                </div>
                <div>
                  <label>HSN</label>
                  <input
                    disabled={true}
                    type="text"
                    name="hsn"
                    className="form-control"
                    value={product.hsn}
                  />
                </div>
              </div>
            </div>

            {/* Length, Width, Height, Weight */}
            <div className="small-box">
              <div className="multi-input-row">
                <div>
                  <label>Box Length (cm) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="length"
                    value={product.length}
                    className="form-control"
                    step="0.01"
                  />
                </div>
                <div>
                  <label>
                    Box width (cm){" "}
                    {product.status === "Verified and Published" && (
                      <span className="red">*</span>
                    )}
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    name="width"
                    value={product.width}
                    className="form-control"
                    step="0.01"
                  />
                </div>
                <div>
                  <label>Box height (cm) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="height"
                    value={product.height}
                    className="form-control"
                  />
                </div>
                <div>
                  <label>Weight (kg) </label>
                  <input
                    disabled={true}
                    type="text"
                    name="weight"
                    value={product.weight}
                    className="form-control"
                    step="0.01"
                  />
                </div>
              </div>
            </div>

            {/* Product SEO */}
            <div className="small-box">
              <div className="small-container-heading">
                <p className="productShippingPara">Search Engine Listing</p>
                <button
                  className="blue-edit-btn"
                  type="button"
                  onClick={() => {
                    setOpenSeoEdit(!openSeoEdit);
                  }}
                >
                  {openSeoEdit ? "Close" : "Edit"}
                </button>
              </div>

              <div className="seo-read">
                <p className="seo-read-title">
                  {product.meta_title
                    ? product.meta_title
                    : product.name &&
                      product.name.substring(0, metaTitleMaxLength)}
                </p>
                <p className="seo-read-url">
                  {BUYER_APP}/products/
                  {getSlugURL(product.name)}...
                </p>
                <p className="seo-read-description">
                  {product.meta_description
                    ? product.meta_description
                    : product.description &&
                      removeHtmlTags(product.description).substring(
                        0,
                        metaDescMaxLength
                      )}
                </p>
              </div>

              <div
                className={`product-seo-input ${openSeoEdit ? "" : "close"}`}
              >
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Title</span>
                    <span>
                      {metaTitleRemaining}/{metaTitleMaxLength}
                    </span>
                  </label>
                  <input
                    value={product.meta_title}
                    className="form-control"
                    type="text"
                    name="meta_title"
                    minLength={3}
                    maxLength={metaTitleMaxLength}
                  />
                </div>
                <div className="single-row">
                  <label className="meta-label">
                    <span>Meta Description</span>
                    <span>
                      {metaDescRemaining}/{metaDescMaxLength}
                    </span>
                  </label>
                  <textarea
                    value={product.meta_description}
                    name="meta_description"
                    className="form-control meta-description-input"
                    minLength={3}
                    maxLength={metaDescMaxLength}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="right-area">
            <div className="small-box">
              <label>
                Status <span className="red">*</span>
              </label>
              <select
                disabled={true}
                name="status"
                className="form-control"
                value={product.status}
              >
                <option value="">Select</option>
                <option value="Verified and Published">
                  Verified and Published
                </option>
                <option value="Unverified">Unverified</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div className="small-box">
              <label>Category </label>
              <select
                disabled={true}
                className="form-control"
                name="category_id"
                value={product.category_id}
              >
                <option value="">Select</option>
                {categoryData &&
                  categoryData.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
              <label className="mt-10">Brand</label>
              <select
                disabled={true}
                className="form-control"
                name="brand_id"
                value={product.brand_id}
              >
                <option value="">Select</option>
                {brandData &&
                  brandData.map((brands, index) => (
                    <option key={index} value={brands.id}>
                      {brands.name}
                    </option>
                  ))}
              </select>
              <label className="mt-10">Condition </label>
              <select
                disabled={true}
                name="condition"
                value={product.condition}
                className="form-control"
              >
                <option value="">Select</option>
                {conditionData &&
                  conditionData.map((conditions) => (
                    <option key={conditions} value={conditions}>
                      {conditions}
                    </option>
                  ))}
              </select>
              <div>
                <label className="mt-10">Applicable Age Group </label>
                <select
                  disabled={true}
                  className="form-control"
                  name="age_group_id"
                  value={product.age_group_id}
                >
                  <option value="">Select</option>
                  {applicableAgeGroupData.map((ageGroup) => (
                    <option key={ageGroup.id} value={ageGroup.id}>
                      {ageGroup.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerProductEdit;
