import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  faSort,
  faSortUp,
  faSortDown,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SellerTopNavigation from "./SellerNavigation";
import "./css/transaction.css";
import { getDataFromCookies } from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import GlobalFilter from "../../../Components/GlobalFilter";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Transaction = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { sellerId } = useParams();
  const { showToast } = Toast();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");

  const dataPerPage = 10;
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getSellerPayments(data);
  };

  const getSellerPayments = async (url) => {
    const response = await fetch(`${url}&per_page=${dataPerPage}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      setTransactions(res.data.data);
      setCurrentPage(res.data.current_page);
      setPagination(res.data);
    } else if (res.status === 404) {
      setTransactions([]);
      setPagination("");
    } else {
      showToast("info", "Something went wrong");
    }
    setLoading(false);
  };
  useEffect(() => {
    getSellerPayments(
      `${API_HOST}/api/admin/user/sellers/${sellerId}/payments?page_no=1`
    );
  }, []);

  const handleTransactiondetails = (transactionId) => {
    navigate(`/payment-details/${transactionId}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * dataPerPage, // Adding 1 to get a 1-based serial number
      },
      {
        Header: "Image",
        accessor: "product_details.thumb_image",
        Cell: ({ value }) => (
          <img
            id="sellers-productImage"
            src={`${imgHost}/${value}`}
            loading="lazy"
          />
        ),
      },
      {
        Header: "Product",
        accessor: "product_details.name",
        cell: ({ value }) => (
          <div id="sellers-productName-headingDiv" title={value}>
            {value}
          </div>
        ),
      },
      {
        Header: "Timestamp",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(value);
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          const formattedDate = date.toLocaleString(undefined, options);
          const formattedTime = date.toLocaleTimeString();

          return (
            <div id="sellers-orderTimestamp">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          );
        },
      },
      {
        Header: "Payable Amount",
        accessor: "payable_amount",
        Cell: ({ value }) => (
          <div id="sellers-productSellingPrice" className="cell-container">
            ₹ {value}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <>
            {value === "Processing" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#ffe6bc" }}
              >
                Processing
              </p>
            ) : value === "Completed" ? (
              <p
                className="order-status"
                style={{ backgroundColor: "#7CB342" }}
              >
                Completed
              </p>
            ) : value === "Initiated" ? (
              <>
                <p
                  className="order-status"
                  style={{ backgroundColor: "#FECDCD" }}
                >
                  Initiated
                </p>
              </>
            ) : (
              <p
                className="order-status"
                style={{ backgroundColor: "#FECDCD" }}
              >
                Cancelled
              </p>
            )}
          </>
        ),
      },

      {
        Header: "Action",
        accessor: "id",
        Cell: ({ value }) => (
          <span>
            <FontAwesomeIcon
              className="action-icon edit-action"
              icon={faEye}
              onClick={() => handleTransactiondetails(value)}
            />
          </span>
        ),
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: transactions,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <div className="page-data">
      <SellerTopNavigation />
      <div className="page-container">
        <div className="TransactionTableContainerBox">
          <div className="page-container">
            {/* Filter and Button */}
            <div className="table-serach-area">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
            <div className="InnerTableContainer">
              <table className="order-table" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      className="TableHeading"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <th>
                          <div className="header-cell">
                            <span>{column.render("Header")}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {/* table body logic where data appends */}
                <tbody {...getTableBodyProps()}>
                  {loading ? (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">Loading...</div>
                      </td>
                    </tr>
                  ) : page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="TableBodyData" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length}>
                        <div className="nomatchfound">No data</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination*/}
            <div className="pagination-container">
              {pagination && (
                <Paginate
                  pagination={pagination}
                  pageChange={handleURLChange}
                  pageName={"Transaction"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Transaction;
