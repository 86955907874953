import { React, useState, useEffect, useRef, useContext } from "react";
import "./seller-stories.css";
import { useNavigate, useLocation } from "react-router-dom";
import FaceRating from "./StarRating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../Components/Toast";
import imgHost from "../../../Components/ReUsed";
import Navigation from "../../../config/Navigation.json";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import LoaderButton from "../../../Components/LoaderButton";
import APIEndpoint from "../../../config/APIEndpoints.json";
import InputContext from "../../../Components/InputContext";
let API_HOST = process.env.REACT_APP_HOST_API_URL;

const SellerStoriesEdit = ({ handleCloseEditModel, editId }) => {
  const location = useLocation();
  const [isUpdateLoadingButton, setisUpdateLoadingButton] = useState(false);
  const [seller, setSeller] = useState({
    name: "",
    message: "",
    image: null,
    rating: 0,
    city: "",
    module: "",
    state: "",
    show_on_page: 0,
  });
  const { showToast } = Toast();
  const contextObj = useContext(InputContext);
  const [moduleError, setModuleError] = useState("");
  const getStoryDetails = async () => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    const response = await fetch(
      `${API_HOST}/${APIEndpoint.adminStoriesCrud}/${editId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const res = await response.json();

    if (res.status === 200) {
      setRating(res.data.rating);
      setSeller(res.data);
      setImagesToContainer(res.data);
    } else if (res.status === 404) {
      showToast("error", res.message);
      navigate(Navigation.testimonials);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
  };

  const updateStory = async () => {
    const formData = new FormData();
    formData.append("name", seller.name);
    formData.append("city", seller.city);
    formData.append("state", seller.state);
    formData.append("message", seller.message);
    formData.append("rating", seller.rating);
    formData.append("module", seller.module);
    formData.append("show_on_page", seller.show_on_page);
    formData.append("image", seller.image);
    formData.append("_method", "PUT");

    const token = getDataFromCookies("7b9KpQ3rW6");
    await fetch(`${API_HOST}/${APIEndpoint.adminStoriesCrud}/${editId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status == 200) {
          setDataToCookies("isEditTestomonial", true);
          setisUpdateLoadingButton(false);
          setSeller((prevData) => ({
            ...prevData,
            name: "",
            message: "",
            image: null,
            rating: 0,
            city: "",
            state: "",
            show_on_page: 0,
          }));
          //setSeller(res.data);

          handleCloseEditModel();
          showToast("success", res.message);
        }
        if (res.status == 400 && res.errors) {
          let errors = res.errors;

          if (errors.name) {
            setNameError(errors.name[0]);
          }
          if (errors.city) {
            setCityError(errors.city[0]);
          }
          if (errors.state) {
            setStateError(errors.state[0]);
          }
          if (errors.message) {
            setMessageError(errors.message[0]);
          }
          if (errors.rating) {
            setRatingError(errors.rating[0]);
          }
          if (errors.show_on_page) {
            setShowOnPageError(errors.show_on_page[0]);
          }
          if (errors.image) {
            setImageError(errors.image[0]);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getStoryDetails();
  }, []);

  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setSeller((prevData) => ({
      ...prevData,
      rating: newRating,
    }));
    setRating(newRating);
    setRatingError("");
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  //error handeling
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ratingError, setRatingError] = useState("");
  const [showOnPageError, setShowOnPageError] = useState("");

  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });
  const setImagesToContainer = (images) => {
    setContainer1({
      ...container1,
      image: `${imgHost + "/" + images.image}`,
    });
  };

  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      setImageError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setImageError(
          "Invalid file type. Only JPG, JPEG, PNG, and WEBP files are allowed."
        );
        return;
      }
      if (file.size > maxFileSize) {
        // const compressedFile = compressImage(file, maxFileSize);
        compressImage(file, maxFileSize);
      } else {
        readFile(file);
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800; // Maximum width for the compressed image
          const maxHeight = 800; // Maximum height for the compressed image
          let width = image.width;
          let height = image.height;

          // Calculate new dimensions if the original image is larger than the maximum dimensions
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                // "Compression failed to meet target size requirement."
                // Handle the error as needed
                return;
              }
              setSeller((prevData) => ({
                ...prevData,
                image: compressedFile,
              }));
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };
  // img remove after upload
  const inputFileThumbRef = useRef(null);
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setSeller((prevData) => ({
      ...prevData,
      image: null,
    }));
    // Clear the value of the input file element
    if (inputFileThumbRef.current) {
      inputFileThumbRef.current.value = null;
    }
  };
  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    document.getElementById("thumb-img-input").value = "";
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  const checkErrorExists = () => {
    let errorHas = false;
    setisUpdateLoadingButton(true);
    const nameRegex = /^[a-zA-Z0-9-_\s]+$/;
    const isName = nameRegex.test(seller.name);
    const validCity = /^[a-zA-Z\s\-()]+$/;
    const isCity = validCity.test(seller.city);

    // name and decription Feilds.
    if (seller.name === "" || seller.name === null) {
      setNameError("Seller Name is required!");
      errorHas = true;
    } else if (!isName) {
      setNameError("Please enter a valid Seller Name!");
      errorHas = true;
    } else if (seller.name.length < 2) {
      setNameError("Seller Name must be minimum 2 characters!");
      errorHas = true;
    } else if (seller.name.length > 50) {
      setNameError("Seller Name should not exceed 50 characters!");
      errorHas = true;
    } else {
      setNameError("");
    }
    // city
    if (seller.city === "" || seller.city === null) {
      setCityError("City Name is required!");
      errorHas = true;
    } else if (!isCity) {
      setCityError("Please enter a valid City Name!");
      errorHas = true;
    } else if (seller.city.length < 3) {
      setCityError("City Name must be minimum 3 characters!");
      errorHas = true;
    } else if (seller.city.length > 150) {
      setCityError("City Name should not exceed 150 characters!");
      errorHas = true;
    } else {
      setCityError("");
    }
    // state
    if (seller.state === "" || seller.state === null) {
      setStateError("State Name is required!");
      errorHas = true;
    } else {
      setStateError("");
    }

    // message
    if (seller.message === "" || seller.message === null) {
      setMessageError("Message is required!");
      errorHas = true;
    } else if (seller.message.length < 3 || seller.message.length > 500) {
      setMessageError("Message should be between 3 and 500 characters.");
      errorHas = true;
    } else {
      setMessageError("");
    }

    // image
    if (seller.image === null) {
      setImageError("Seller Image is required!");
      errorHas = true;
    } else {
      setImageError("");
    }

    // rating
    if (seller.rating === "" || seller.rating === null) {
      setRatingError("Rate your experience is required!");
      errorHas = true;
    } else if (seller.rating < 1) {
      setRatingError("Rating must be minimum 1.");
      errorHas = true;
    } else if (seller.rating > 5) {
      setRatingError("Rating should not exceed 5");
      errorHas = true;
    } else {
      setRatingError("");
    }
    // module
    if (seller.module === "" || seller.module === null) {
      setModuleError("Module Name is required!");
      errorHas = true;
    }
    if (errorHas === true) {
      setisUpdateLoadingButton(false);
      return true;
    }
    updateStory();
  };

  // This code is for getting images.
  const handleInputChange = (name, value) => {
    setSeller((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // }
  };

  return (
    <div className="modal fade">
      <div className="testimonial-container">
        <button
          type="button"
          className="order modal-close-icon"
          onClick={handleCloseEditModel}
        >
          &times;
        </button>
        <div className="modal-header">
          <p className="modal-heading">Update Testimonial</p>
        </div>

        <div className="testimonial-modal-body">
          <div>
            <div className="grid-itms">
              <label className="seller_stories_lable_tag">
                <input
                  value={seller.name}
                  type="text"
                  name="name"
                  className="seller_stories_input_tag"
                  placeholder="Name"
                  onChange={(e) => {
                    handleInputChange("name", e.target.value);
                    setNameError("");
                  }}
                />
                <span className="seller_stories_span_tag">Name</span>
              </label>
              <label className="red">{nameError}</label>
            </div>
            <div className="grid-itms">
              <label className="seller_stories_lable_tag">
                <input
                  value={seller.city}
                  type="text"
                  name="city"
                  className="seller_stories_input_tag"
                  placeholder="City"
                  onChange={(e) => {
                    handleInputChange("city", e.target.value);
                    setCityError("");
                  }}
                />
                <span className="seller_stories_span_tag">City</span>
              </label>
              <label className="red">{cityError}</label>
            </div>
            <div>
              <label className="seller_stories_lable_tag">
                <select
                  value={seller.state}
                  type="text"
                  name="state"
                  className="seller_stories_input_tag"
                  placeholder=" State"
                  onChange={(e) => {
                    handleInputChange("state", e.target.value);
                    setStateError("");
                  }}
                >
                  <option value="">Select</option>
                  <option value="Andaman and Nicobar">Andaman & Nicobar</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">
                    Dadra & Nagar Haveli
                  </option>
                  <option value="Daman and Diu">Daman & Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu & Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Ladakh">Ladakh</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
                <span className="seller_stories_span_tag">State</span>
              </label>
              <label className="red">{stateError}</label>
            </div>
            <label className="seller_stories_lable_tag">
              <textarea
                name="message"
                placeholder="Testimonial"
                className="seller_stories_input_tag seller_stories_Textarea"
                value={seller.message}
                onChange={(e) => {
                  handleInputChange("message", e.target.value);
                  setMessageError("");
                }}
                rows={6}
              ></textarea>
              <span className="seller_stories_span_tag">Testimonial</span>
            </label>
            <label className="red">{messageError}</label>
            <div className="CheckboxDiv">
              <label>
                <input
                  type="checkbox"
                  name="is_negotiable"
                  checked={seller.show_on_page == 0 ? false : true}
                  onChange={() => {
                    setSeller((prevData) => ({
                      ...prevData,
                      show_on_page: seller.show_on_page == 0 ? 1 : 0,
                    }));
                  }}
                />{" "}
                Show On Page
              </label>
            </div>
          </div>
          <div>
            <div>
              <label className="seller_stories_lable_tag">
                <select
                  value={seller.module}
                  type="text"
                  name="module"
                  className="seller_stories_input_tag"
                  placeholder="Module"
                  onChange={(e) => {
                    handleInputChange("module", e.target.value);
                    setModuleError("");
                  }}
                >
                  <option value="">Select</option>
                  <option value="Buyer">Buyer</option>
                  <option value="Seller">Seller</option>
                  <option value="General">General</option>
                </select>
                <span className="seller_stories_span_tag">Module</span>
              </label>
              <label className="red">{moduleError}</label>
            </div>
            <div>
              <label>Image </label>
              <span className="redStar">*</span>
              <div
                id="thumb-img-upload-SellerStory"
                onClick={thumbImgUpload}
                className="dropzone"
                onMouseEnter={handleMouseEnterThumbImg}
                onMouseLeave={handleMouseLeaveThumbImg}
              >
                {container1.image ? (
                  <div className="dropzone-image">
                    <img
                      src={container1.image}
                      loading="lazy"
                      width="100%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                    {container1.hovering && (
                      <button
                        className="delete-button"
                        onClick={handleDeleteThumbClick}
                      >
                        Remove Image
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="click-to-upload">Click to upload</p>
                )}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  name="image"
                  onChange={(e) => {
                    handleInputChange("image", e.target.files[0]);
                    setImageError("");
                  }}
                  id="thumb-img-input"
                  hidden
                />
              </div>
              <label className="red">{imageError}</label>
            </div>
            <div className="sellerRatingContainer">
              <div>
                <label>
                  Rate your experience <span className="redStar">*</span>
                </label>
                <FaceRating
                  totalStars={5}
                  onRatingChange={handleRatingChange}
                  rating={seller.rating}
                />
                <label className="red">{ratingError}</label>
              </div>
            </div>
          </div>
        </div>

        <div className="seller_stories_button_container">
          <button className="btn secondary-btn" onClick={handleCloseEditModel}>
            Cancel
          </button>

          <button
            className="btn primary-btn"
            disabled={isUpdateLoadingButton}
            onClick={() => {
              checkErrorExists();
            }}
          >
            {isUpdateLoadingButton ? (
              <>
                <LoaderButton />
              </>
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default SellerStoriesEdit;
