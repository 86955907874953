import ImagePath from "../Assets/loading.gif";
const LoaderButton = () => {
  const imgStyle = {
    width: "18px",
    height: "18px",
    color: "aliceblue",
  };
  return <img style={imgStyle} src={ImagePath} alt="Loading" />;
};

export default LoaderButton;
