import React from "react";
import styles from "./InputControl.module.css";

function InputControl(props) {
  return (
    <div className={styles.container}>
      {props.label && <label>{props.label}</label>}
      <input
        className={styles.mymainInputTag}
        maxLength={props.maxLength}
        minLength={props.minLength}
        type="text"
        {...props}
      />
      {props.error && (
        <input
          className={styles.inputboxforerrormessage}
          value={props.error}
          disabled
        />
      )}
    </div>
  );
}

export default InputControl;
