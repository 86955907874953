import { useState, useEffect, useMemo, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useLocation } from "react-router-dom";
import APIEndpoint from "../../../config/APIEndpoints.json";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Navigate from "../../../config/Navigation.json";
import Toast from "../../../Components/Toast";
import Paginate from "../../../Components/Pagination/Paginate";

const API_HOST = process.env.REACT_APP_HOST_API_URL;
const FeedbackSurvey = () => {
  const contextObj = useContext(InputContext);
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = new useNavigate();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState("");
  const [loading, setLoading] = useState(true);
  const token = getDataFromCookies("7b9KpQ3rW6");
  let [currentPage, setCurrentPage] = useState("");
  // Get List of ALL feedBacks
  const FeedbackPerPage = 10;
  const handleURLChange = (url) => {
    window.scrollTo(0, 0);
    getFeedbackSurveyList(url);
  };

  const getFeedbackSurveyList = async (url) => {
    try {
      const response = await fetch(`${url}&per_page=${FeedbackPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const res = await response.json();
      setLoading(false);
      if (res.status == 200) {
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        setData(res.data.data);
      } else if (res.status === 404) {
        setData([]);
        setPagination("");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        navigate(`${Navigate.login}`);
        showToast("info", "Sessions expired! Please login");
      } else if (res.status == 401 && res.message !== "") {
        showToast("error", res.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.message.includes("Failed to fetch")) {
        // navigate("/error");
      } else {
        // window.location.href = "/error";
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) =>
          index + 1 + (currentPage - 1) * FeedbackPerPage,
      },
      {
        Header: "Question",
        accessor: "question",
      },

      {
        Header: "Module",
        accessor: "module",
      },
      {
        Header: "Submission Count",
        accessor: "submission_count",
      },
      {
        Header: "Total Rating",
        accessor: "total_rating",
      },
      {
        Header: "Average",
        accessor: "average",
      },
    ],
    [currentPage]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    getFeedbackSurveyList(
      `${API_HOST}/${APIEndpoint.GetFeedBackData}?page_no=${currentPage}`
    );
  }, []);
  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active"> Feedback Survey</li>
          </div>
        </div>

        {/* Data table */}
        <div className="page-container">
          <div className="AdminBrandSetupListDiv">
            <table {...getTableProps()}>
              <thead className="hadingelementsbrandSetups">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th>
                        {" "}
                        <div className="header-cell">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {loading ? (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">Loading...</div>
                    </td>
                  </tr>
                ) : page.length > 0 ? (
                  // Check if there is data in the current page
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="TableBodyData" {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">No Feedback Available.</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination */}

          <div className="pagination-container">
            {pagination && (
              <Paginate
                pagination={pagination}
                pageChange={handleURLChange}
                pageName={"Feedback Survey"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackSurvey;
