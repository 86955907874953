import { useContext, useEffect, useMemo, useState } from "react";
import Navigation from "../../../config/Navigation.json";
import APIEndpoint from "../../../config/APIEndpoints.json";
import "./banner.css";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import AddBanner from "./AddBanner";
import EditBanner from "./EditBanner";
import IMG_HOST from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import { faPenToSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Paginate from "../../../Components/Pagination/Paginate";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Banners = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);

  const [loading, setLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  const [bannerId, setBannerId] = useState(null);
  const [bannerImage1, setBannerImage1] = useState("");
  const [bannerImage2, setBannerImage2] = useState("");
  const [bannerSellerId, setBannerSellerId] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [bannerStatus, SetBannerStatus] = useState("");
  const [bannerAddModal, setBannerAddModal] = useState(false);
  const [bannerEditModal, setBannerEditModal] = useState(false);
  const [bannerDeleteModal, setBannerDeleteModal] = useState(false);
  const [deleteBtnLoading, setDeleteBtnLoading] = useState(false);
  const [isSearchApplied, setIsSearchApplied] = useState(false);

  // handle pagination
  const BannerPerPage = 10;
  let [currentPage, setCurrentPage] = useState("");
  const [pagination, setPagination] = useState("");
  let [lastPage, setLastPage] = useState();
  const [totalCount, setTotalCount] = useState("");
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getBannersList(data);
  };

  // const Get Banner List
  const getBannersList = async (url) => {
    try {
      let token = getDataFromCookies("7b9KpQ3rW6");
      if (!token) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(`${Navigation.login}`);
        return;
      }

      if (searchQuery !== "") {
        url = url + "&search=" + searchQuery;
      }

      const response = await fetch(`${url}&per_page=${BannerPerPage}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const res = await response.json();
      if (res.status === 200) {
        setBannerList(res.data.data);
        setPagination(res.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalCount(res.data.total);
      } else if (res.status === 404) {
        setBannerList([]);
        setPagination("");
      } else if (
        res.status === 401 &&
        res.message === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        navigate(Navigation.login);
        removeDataFromCookie("7b9KpQ3rW6", "/");
        removeDataFromCookie("loggedIn", "/");
        contextObj.setInput("navigation", "true");
        contextObj.setInput("navigationFrom", "sessionExpired");
        showToast("info", "Sessions expired!");
      } else if (res.status == 401 && res.message !== "") {
        showToast("error", res.message);
      }
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getBannersList(`${API_HOST}/${APIEndpoint.banners}?page_no=1`);
  }, []);

  // handle search api calls
  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        getBannersList(
          `${API_HOST}/${APIEndpoint.banners}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  // Delete Modal Popup
  const handleDeleteClick = (id) => {
    setBannerId(id);
    setBannerDeleteModal(true);
  };

  const closeDeleteModal = () => {
    currentPage =
      totalCount % BannerPerPage == 1 ? currentPage - 1 : currentPage;
    getBannersList(`${API_HOST}/${APIEndpoint.banners}?page_no=${currentPage}`);
    setBannerId(null);
    setBannerDeleteModal(false);
  };

  // Delete API Calls
  const handleConfirmDelete = async (id) => {
    let token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    setDeleteBtnLoading(true);
    const response = await fetch(`${API_HOST}/${APIEndpoint.banners}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (res.status === 200) {
      showToast("success", res.message);
      closeDeleteModal();
    } else if (
      response.status === 401 &&
      response.status === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(Navigation.login);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      showToast("info", "Something went wrong");
    }
    closeDeleteModal();
    setDeleteBtnLoading(false);
  };

  // ADD Modal Popup
  const handleAddBanner = () => {
    setBannerAddModal(true);
  };

  const handleCloseModal = () => {
    setBannerAddModal(false);
    getBannersList(`${API_HOST}/${APIEndpoint.banners}?page_no=1`);
  };

  // Edit Modal Popup
  const handelBannerEdit = (id, image1, image2, cta_url, sellerId, status) => {
    setBannerId(id);
    setBannerImage1(image1);
    setBannerImage2(image2);
    setBannerUrl(cta_url);
    setBannerSellerId(sellerId);
    SetBannerStatus(status);
    setBannerEditModal(true);
  };

  const handleCloseModalEdit = () => {
    setBannerEditModal(false);
    getBannersList(`${API_HOST}/${APIEndpoint.banners}?page_no=${currentPage}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * BannerPerPage,
      },
      {
        Header: "Shop Name",
        accessor: (row) => (row.seller ? row.seller.shop_name : "-"),
      },
      {
        Header: "Shop URL",
        accessor: (row) => (row.seller ? row.seller.shop_url : "-"),
      },
      {
        Header: "Image 1",
        accessor: "image1",
        Cell: ({ value }) => (
          <img
            className="banner-table-img"
            src={IMG_HOST + "/" + value}
            alt="Image"
          />
        ),
      },
      {
        Header: "Image 2",
        accessor: "image2",
        Cell: ({ value }) => (
          <img
            className="banner-table-img"
            src={IMG_HOST + "/" + value}
            alt="Image"
          />
        ),
      },
      {
        Header: "CTA URL",
        accessor: "cta_url",
        Cell: ({ value }) => {
          return (
            <a className="banner-cta-url" href={value} target="_blank">
              {value}
            </a>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          let backgroundColor;
          let color;
          if (value === "Published") {
            color = "white";
            backgroundColor = "#4CAF50";
          } else if (value === "Unpublished") {
            backgroundColor = "#c5c5c5";
            color = "#000000";
          }
          return (
            <p
              title="Status"
              className="order-status"
              style={{ backgroundColor, color }}
            >
              {value}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const image1 = row.original.image1;
          const image2 = row.original.image2;
          const cta_url = row.original.cta_url;
          const status = row.original.status;
          const sellerId = row.original.seller_id;
          return (
            <div>
              <span>
                <button
                  onClick={() => {
                    handelBannerEdit(
                      id,
                      image1,
                      image2,
                      cta_url,
                      sellerId,
                      status
                    );
                  }}
                  className="action-icon edit-button"
                  title="Edit"
                >
                  <FontAwesomeIcon id="checkPageOpen" icon={faPenToSquare} />
                </button>
                <button
                  onClick={() => {
                    handleDeleteClick(id);
                  }}
                  className="action-icon trash-button"
                  title="Delete"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </span>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );

  // Apply the filter function in the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: bannerList,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Banners</li>
        </div>
      </div>

      <div className="page-container">
        {/* Filter and Button */}
        <div className="table-serach-area">
          <button
            type="button"
            className="primary-btn"
            onClick={() => {
              handleAddBanner();
            }}
          >
            New Banner
          </button>
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
        </div>

        <div className="AdminBrandSetupListDiv">
          <table {...getTableProps()}>
            <thead className="hadingelementsbrandSetups">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {" "}
                      <div className="header-cell">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">Loading...</div>
                  </td>
                </tr>
              ) : page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="TableBodyData" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={headerGroups[0].headers.length}>
                    <div className="nomatchfound">No data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Banners"}
            />
          )}
        </div>
      </div>

      {/* Banner Delete Modal */}
      {bannerDeleteModal && (
        <>
          <div
            id="deleteModal"
            className="modal fade"
            style={{ display: bannerDeleteModal ? "block" : "none" }}
          >
            <div className="modal-content">
              <span className="modal-close" onClick={closeDeleteModal}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <h3 className="modal-header">Delete Confirmation</h3>
              <p className="modal-line">
                Are you sure you want to delete Banner?
              </p>
              <div className="modal-buttons">
                {deleteBtnLoading ? (
                  <button type="button" className="btn disabled-primary-btn">
                    Yes, Delete It!
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn primary-btn"
                    onClick={() => handleConfirmDelete(bannerId)}
                  >
                    Yes, Delete It!
                  </button>
                )}
                <button
                  className="btn secondary-btn"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Banner Add Modal */}
      {bannerAddModal && <AddBanner handleCloseModal={handleCloseModal} />}

      {/* Banner Edit Modal */}
      {bannerEditModal && (
        <EditBanner
          bannerId={bannerId}
          bannerImage1={bannerImage1}
          bannerImage2={bannerImage2}
          bannerCtaUrl={bannerUrl}
          bannerStatus={bannerStatus}
          bannerSellerId={bannerSellerId}
          closeEditModal={handleCloseModalEdit}
        />
      )}
    </div>
  );
};
export default Banners;
