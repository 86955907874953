import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Login from "./Components/Auth/Login";
import CreatedContextsState from "./Components/Context/createContextState";
import MyProfilePage from "./Components/MyProfilePage/MyProfilePage";
import InputProvider from "./Components/InputProvider";
import AppVersion from "../src/Components/AppVersion";

// Dashboard
import Dashboard from "./Pages/Dashboard/Dashboard";

// Order Management
import AllOrders from "./Pages/OrderManagement/Orders/Orders";
import OrderDetails from "./Pages/OrderManagement/Orders/OrderDetails";
import SellerPayments from "./Pages/OrderManagement/SellerPayment/SellerPayments";
import PaymentDetails from "./Pages/OrderManagement/SellerPayment/PaymentDetails";
import RefundPayments from "./Pages/OrderManagement/RefundPayments/RefundPayments";
import Donations from "./Pages/OrderManagement/Donations/Donations";
import DonationDetails from "./Pages/OrderManagement/Donations/DonationDetails";
import Donate from "./Pages/OrderManagement/Donations/Donate";

// Product Management
import AgeCollection from "./Pages/ProductManagement/SquareCollection/AgeCollection";
import PriceCollection from "./Pages/ProductManagement/SquareCollection/Price";
import ConditionCollection from "./Pages/ProductManagement/SquareCollection/Condition";
import BrandSetup from "./Pages/ProductManagement/Brand/Brands";
import CategorySetup from "./Pages/ProductManagement/Category/Categories";

import InHouseProductList from "./Pages/ProductManagement/InHouseProduct/InHouseProductList";
import InHouseProduct from "./Pages/ProductManagement/InHouseProduct/InHouseProduct";
import InHouseProductDetails from "./Pages/ProductManagement/InHouseProduct/InHouseProductDetails";
import SellersProductList from "./Pages/ProductManagement/SellersProduct/SellersProductList";
import SellerProductEdit from "./Pages/ProductManagement/SellersProduct/SellerProductEdit";
import SellerProductDetails from "./Pages/ProductManagement/SellersProduct/SellerProductDetails";

// User Management

// Customer
import Customers from "./Pages/UserManagement/Customers/Customers";
import CustomersDetails from "./Pages/UserManagement/Customers/CustomersDetails";
// Seller
import Sellers from "./Pages/UserManagement/Sellers/SellerList";
import SellerProducts from "./Pages/UserManagement/Sellers/SellerProducts";
import SellerAccount from "./Pages/UserManagement/Sellers/SellerAccount";
import SellerTransaction from "./Pages/UserManagement/Sellers/Transaction";
import SellerOrders from "./Pages/UserManagement/Sellers/SellerOrders";
// Admin
import RoleSetup from "./Pages/UserManagement/AdminUsers/RoleSetup/Roles";
import AdminUsers from "./Pages/UserManagement/AdminUsers/Users/Users";
import EditAdminUser from "./Pages/UserManagement/AdminUsers/Users/EditUsers";

// CMS
import NGOs from "./Pages/CMS/NGOs/NGOs";
import AddNGOs from "./Pages/CMS/NGOs/AddNgo";
import EditNGOs from "./Pages/CMS/NGOs/EditNgo";
import Banners from "./Pages/CMS/Banners/Banners";
import SellerStories from "./Pages/CMS/SellerStoryFolder/Stories";
import NewSellerStory from "./Pages/CMS/SellerStoryFolder/NewStory";
import SellerStoriesEdit from "./Pages/CMS/SellerStoryFolder/EditStory";
import BlogCategory from "./Pages/CMS/BlogCategory/BlogCategory";
import BlogList from "./Pages/CMS/Blogs/Blogs";
import AddBlog from "./Pages/CMS/Blogs/AddBlog";
import EditBlog from "./Pages/CMS/Blogs/EditBlog";
import Policy from "./Pages/CMS/Policies/Policy";
import Policies from "./Pages/CMS/Policies/Policies";

// System Setting
import BusinessSetup from "./Pages/SystemSettings/BusinessSetup/BussinessSetup";
import AdminConfiguration from "./Pages/SystemSettings/AdminConfig/AdminConfig";
import FeatureFlags from "./Pages/SystemSettings/FeatureFlags/FeatureFlags";

// Help and Support
import FeedbackList from "./Pages/HelpAndSupport/Feedback/Feedbacks";
import AppMenu from "./Components/Navbar/SideNavbar/AppMenu";
import TopNavbar from "./Components/Navbar/TopNavbar/TopNavbar";
import Faq from "./Pages/CMS/Faq/Faq";
import FeedbackSurvey from "./Pages/HelpAndSupport/FeedbackSurvey/feedbackSurvey";

// product collection
import ProductsCollection from "./Pages/ProductManagement/ProductsCollection/ProductsCollection";
import EditProductsCollection from "./Pages/ProductManagement/ProductsCollection/editProductsCollection";

const defaultToastOptions = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "light",
};

const App = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Router>
      <CreatedContextsState>
        <ToastContainer {...defaultToastOptions} />
        <InputProvider>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/version" element={<AppVersion />} />
          </Routes>

          <div className="body-container">
            <AppMenu toggleClick={setExpanded} />

            <div
              className="container"
              style={{ paddingLeft: expanded ? "250px" : "55px" }}
              onClick={() => {
                setExpanded(false);
              }}
            >
              <TopNavbar />
              <Routes>
                {/* Dashboard Management */}
                <Route exact path="/dashboard" element={<Dashboard />} />

                {/* Order Management */}
                <Route exact path="/orders" element={<AllOrders />} />
                <Route
                  exact
                  path="/order-details/:orderId"
                  element={<OrderDetails />}
                />
                <Route exact path="/payment" element={<SellerPayments />} />
                <Route
                  exact
                  path="refund-payments"
                  element={<RefundPayments />}
                />
                <Route
                  exact
                  path="/payment-details/:paymentId"
                  element={<PaymentDetails />}
                />
                <Route exact path="donations" element={<Donations />} />
                <Route
                  exact
                  path="donations/:donationId"
                  element={<DonationDetails />}
                />
                <Route exact path="/donate" element={<Donate />} />

                {/* Product Management */}
                <Route exact path="/categories" element={<CategorySetup />} />
                <Route exact path="/brands" element={<BrandSetup />} />

                {/* InHouse Products */}
                <Route
                  exact
                  path="/admin/products"
                  element={<InHouseProductList />}
                />
                <Route
                  exact
                  path="/admin/products/new"
                  element={<InHouseProduct page={"new"} />}
                />
                <Route
                  exact
                  path="/admin/products/edit/:slug"
                  element={<InHouseProduct page={"edit"} />}
                />
                <Route
                  exact
                  path="/admin/products/:slug"
                  element={<InHouseProductDetails />}
                />
                {/* Seller Products */}
                <Route
                  exact
                  path="/seller/products"
                  element={<SellersProductList />}
                />
                <Route
                  exact
                  path="/seller/products/edit/:slug"
                  element={<SellerProductEdit page={"edit"} />}
                />
                <Route
                  exact
                  path="/seller/products/:slug"
                  element={<SellerProductDetails />}
                />

                {/* User Management */}
                <Route exact path="/user/customers" element={<Customers />} />
                <Route
                  exact
                  path="/user/customers/:customerId"
                  element={<CustomersDetails />}
                />
                {/* Seller List */}
                <Route exact path="/user/sellers" element={<Sellers />} />
                <Route
                  exact
                  path="/user/sellers/:sellerId/account"
                  element={<SellerAccount />}
                />
                <Route
                  exact
                  path="/user/sellers/:sellerId/orders"
                  element={<SellerOrders />}
                />
                <Route
                  exact
                  path="/user/sellers/:sellerId/products"
                  element={<SellerProducts />}
                />
                <Route
                  path="/user/sellers/:sellerId/transaction"
                  element={<SellerTransaction />}
                />
                {/* Admin user List */}
                <Route exact path="/roles" element={<RoleSetup />} />
                <Route exact path="/user/admins" element={<AdminUsers />} />
                <Route
                  exact
                  path="/user/admins/:adminId"
                  element={<EditAdminUser />}
                />

                {/* CMS Management */}
                <Route exact path="/ngos" element={<NGOs />} />
                <Route exact path="ngo/new" element={<AddNGOs />} />
                <Route exact path="ngo/edit/:ngoId" element={<EditNGOs />} />
                <Route exact path="/banners" element={<Banners />} />
                <Route
                  exact
                  path="/blog-categories"
                  element={<BlogCategory />}
                />
                <Route exact path="/blogs" element={<BlogList />} />
                <Route exact path="/blog/new" element={<AddBlog />} />
                <Route
                  exact
                  path="/blogs/edit/:blogId"
                  element={<EditBlog />}
                />
                <Route exact path="/testimonials" element={<SellerStories />} />
                <Route
                  exact
                  path="/testimonials/new"
                  element={<NewSellerStory />}
                />
                <Route
                  exact
                  path="/testimonials/:id"
                  element={<SellerStoriesEdit />}
                />
                {/* Collections */}
                <Route
                  exact
                  path="/collections/age"
                  element={<AgeCollection />}
                />
                <Route
                  exact
                  path="/collections/price"
                  element={<PriceCollection />}
                />
                <Route
                  exact
                  path="/collections/condition"
                  element={<ConditionCollection />}
                />

                {/* products collection */}
                <Route
                  exact
                  path="/products/collections"
                  element={<ProductsCollection />}
                />

                <Route
                  exact
                  path="/products/collections/:collectionId"
                  element={<EditProductsCollection />}
                />
                <Route
                  exact
                  path="/products/collections/create"
                  element={<EditProductsCollection />}
                />
                {/* Policies */}
                <Route exact path="/policies" element={<Policies />} />
                <Route
                  exact
                  path="/policies/new"
                  element={<Policy addNew={true} />}
                />
                <Route
                  exact
                  path="/policies/edit/:policyId"
                  element={<Policy addNew={false} />}
                />
                <Route exact path="/faq" element={<Faq />} />

                {/* Help and Support */}
                <Route exact path="/feedback" element={<FeedbackList />} />
                <Route
                  exact
                  path="/new-feedback-survey"
                  element={<FeedbackSurvey />}
                />
                {/* System Settings */}
                <Route
                  exact
                  path="/businesssetup"
                  element={<BusinessSetup />}
                />
                <Route
                  exact
                  path="/adminConfiguration"
                  element={<AdminConfiguration />}
                />
                <Route
                  exact
                  path="/set-feature-flags"
                  element={<FeatureFlags />}
                />

                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route exact path="/profile" element={<MyProfilePage />} />
              </Routes>
            </div>
          </div>
        </InputProvider>
      </CreatedContextsState>
    </Router>
  );
};

export default App;
