import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import loadingImgPath from "../../Assets/loading.gif";
import APIEndpoint from "../../config/APIEndpoints.json";
import Navigation from "../../config/Navigation.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { getDataFromCookies, removeDataFromCookie } from "../Cookie";
import Toast from "../Toast";
import "./profile.css";
import InputContext from "../InputContext";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const MyProfilePage = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sellerStatus, setSellerStatus] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isBankDataChanged, setIsBankDataChanged] = useState(false);
  const [isAddressDataChanged, setIsAddressDataChanged] = useState(false);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [isUnreachablePincode, setIsUnreachablePincode] = useState(false);
  // error usestates
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [upiIdError, setUpiIdError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [ifscCodeError, setIfscCodeError] = useState("");
  const [accountHolderNameError, setAccountHolderNameError] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [streetAddressError, setStreetAddressError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const { showToast } = Toast();
  const [showPhonemodal, setShowPhonemodal] = useState(false);
  const [verifyPhoneNumberModal, setVerifyPhoneNumberModal] = useState(false);
  const [phoneNewModalError, setPhoneNewModalError] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [isActive, setIsActive] = useState(false);
  const [newPhoneVerifyTokenInModal, setNewPhoneVerifyTokenInModal] =
    useState("");
  const [profileData, setProfileData] = useState({
    f_name: "",
    l_name: "",
    email: "",
    role_name: "",
    phone: "",
    image: null,
    upi_id: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    ifsc_code: "",
    account_holder_name: "",
    street_address: "",
    city: "",
    state: "",
    pincode: "",
    country: "India",
  });
  const [newPhone, setNewPhone] = useState({
    phone: "",
  });
  const [phoneVerifyOtpModalError, setPhoneVerifyOtpModalError] = useState("");
  const [updateNewPhone, setUpdateNewPhone] = useState({
    phone: "",
    otp: "",
    otp_verify_token: "",
  });

  // Get profile data
  const fetchProfileData = async () => {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    if (res.status === 200) {
      setProfileData(res.data);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      console.error(res);
    }
  };
  // get seller profile
  useEffect(() => {
    const url = `${API_HOST}/${APIEndpoint.crudAdminProfile}`;
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    fetchProfileData();
  }, []);

  // get city and state
  const getCityAndState = async (pincode) => {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`,
      {
        method: "GET",
      }
    );
    const res = await response.json();
    if (res[0].Status === "Error") {
      setPincodeLoading(false);
      setPincodeError("Apologies! We're unable to reach this address.");
      setIsUnreachablePincode(true);
    } else if (res[0].Status === "Success") {
      setPincodeLoading(false);
      setPincodeError("");
      setIsUnreachablePincode(false);
      const cityName = res[0].PostOffice[0].District;
      setProfileData((prevData) => ({
        ...prevData,
        city: cityName,
      }));
      const stateName = res[0].PostOffice[0].State;
      setProfileData((prevData) => ({
        ...prevData,
        state: stateName,
      }));
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setPaymentError("");

    // button disabled and enabled by input events
    if (name === "f_name" || name === "l_name" || name === "phone") {
      setIsDataChanged(true);
    }
    if (
      name === "upi_id" ||
      name === "bank_name" ||
      name === "branch_name" ||
      name === "ifsc_code" ||
      name === "account_holder_name" ||
      name === "account_number"
    ) {
      setIsBankDataChanged(true);
    }
    if (
      name === "street_address" ||
      name === "pincode" ||
      name === "city" ||
      name === "state"
    ) {
      setIsAddressDataChanged(true);
    }

    // update profiledata useState value while updating input fields
    if (name === "f_name") {
      setFirstNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "l_name") {
      setLastNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "phone") {
      const input = event.target;
      const numericValue = input.value.replace(/\D/g, "");
      input.value = numericValue;
      setProfileData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else if (name === "bank_name") {
      setBankNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "branch_name") {
      setBranchNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "ifsc_code") {
      setIfscCodeError("");
      const upperCaseValue = value.toUpperCase();
      setProfileData((prevData) => ({
        ...prevData,
        [name]: upperCaseValue,
      }));
    } else if (name === "account_number") {
      setAccountNumberError("");
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 16); // Limit to a maximum of 16 digits
      if (/^\d*$/.test(limitedValue)) {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: limitedValue,
        }));
      }
    } else if (name === "account_holder_name") {
      setAccountHolderNameError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "pincode") {
      setPincodeError("");
      const trimmedValue = value.replace(/\D/g, ""); // Remove non-digit characters
      const limitedValue = trimmedValue.slice(0, 6); // Limit to a maximum of 6 digits
      if (limitedValue.length === 6) {
        setPincodeLoading(true);
        getCityAndState(limitedValue); //fetch the city and state using pincode
      }

      setProfileData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
    } else if (name === "city") {
      setCityError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (name === "state") {
      setStateError("");
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "street_address") {
      setStreetAddressError("");
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      if (value.trim() !== "" || value == "") {
        setProfileData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  // Close Phone Modal
  const handleCloseNewPhoneModal = () => {
    setShowPhonemodal(false);
    setPhoneNewModalError("");
  };

  const url = `${API_HOST}/${APIEndpoint.crudAdminProfile}`;
  const token = getDataFromCookies("7b9KpQ3rW6");
  // update basic information
  const basicInformationSubmit = async (event) => {
    event.preventDefault();
    // setShowAlert(false);
    let errorHas = false;
    const fnameRegex = /^[a-zA-Z\s]+$/;
    const isFirstName = fnameRegex.test(profileData.f_name);

    if (profileData.f_name === "" || profileData.f_name === null) {
      setFirstNameError("First name is required");
      errorHas = true;
    } else if (!isFirstName) {
      setFirstNameError("Please enter a valid first name");
      errorHas = true;
    } else if (profileData.f_name.length > 50) {
      setFirstNameError("First name should not exceed 50 characters");
      errorHas = true;
    } else {
      setFirstNameError("");
    }

    const lnameRegex = /^[a-zA-Z\s]+$/;
    const isLastName = lnameRegex.test(profileData.l_name);
    if (profileData.l_name === "" || profileData.l_name === null) {
      setLastNameError("Last name is required");
      errorHas = true;
    } else if (!isLastName) {
      setLastNameError("Please enter a valid last name");
      errorHas = true;
    } else {
      setLastNameError("");
    }
    const phoneRegex =
      /^(?!\s*$)(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const isPhoneNumber = phoneRegex.test(profileData.phone);
    if (profileData.phone === "" || profileData.phone === null) {
      setPhoneError("Phone is required");
      errorHas = true;
    } else if (!isPhoneNumber) {
      setPhoneError("Please enter a valid phone number");
      errorHas = true;
    } else {
      setPhoneError("");
    }

    // if any errors are there don't call the api
    if (errorHas === true) return;

    const formData = new FormData();
    formData.append("basic_info", 1);
    formData.append("address_info", 0);
    formData.append("payment_info", 0);
    // formData.append("business_info", 0);

    if (profileData.f_name !== null) {
      formData.append("f_name", profileData.f_name);
    }
    if (profileData.l_name !== null) {
      formData.append("l_name", profileData.l_name);
    }
    if (profileData.phone !== null) {
      formData.append("phone", profileData.phone);
    }
    formData.append("email", profileData.email);

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      setSellerStatus(true);
      showToast("success", "Profile updated successfully.");
      setIsDataChanged(false);
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      console.error(res);
    }
  };

  // update Payment information
  const bankInformationSubmit = async (event) => {
    event.preventDefault();
    // setShowAlert(false);
    let errorHas = false;

    if (
      !profileData.upi_id &&
      !profileData.bank_name &&
      !profileData.branch_name &&
      !profileData.ifsc_code &&
      !profileData.account_holder_name &&
      !profileData.account_number
    ) {
      setPaymentError("UPI ID or Bank information is required");
    }

    let upi_flag = false;
    let bank_flag = false;

    if (
      profileData.bank_name ||
      profileData.branch_name ||
      profileData.ifsc_code ||
      profileData.account_holder_name ||
      profileData.account_number
    ) {
      bank_flag = true;
    }

    const upiRegex = /^[\w.-]+@[\w.-]+$/;
    const isValidUpi = upiRegex.test(profileData.upi_id);

    if (profileData.upi_id && !isValidUpi) {
      setUpiIdError("Please enter a valid UPI ID");
      errorHas = true;
    } else {
      setUpiIdError("");
    }

    const bankNameRegex = /^[A-Za-z ]+$/;
    const isBankName = bankNameRegex.test(profileData.bank_name);

    if (bank_flag && !profileData.bank_name) {
      setBankNameError("Bank name is required");
      errorHas = true;
    } else if (profileData.bank_name && !isBankName) {
      setBankNameError("Please enter a valid Bank name");
      errorHas = true;
    } else if (profileData.bank_name && profileData.bank_name.length > 60) {
      // Maximum length check
      setBankNameError("Bank name should not exceed 60 characters");
      errorHas = true;
    } else {
      setBankNameError("");
    }

    // Branch Name.
    const branhNameRegex = /^[a-zA-Z0-9-_\/ ]+$/;
    const isBranchName = branhNameRegex.test(profileData.branch_name);

    if (bank_flag && !profileData.branch_name) {
      setBranchNameError("Branch name is required");
      errorHas = true;
    } else if (profileData.branch_name && !isBranchName) {
      setBranchNameError("Please enter a valid Branch name");
      errorHas = true;
    } else if (profileData.branch_name && profileData.branch_name.length > 60) {
      // Maximum length check
      setBranchNameError("Branch name should not exceed 60 characters");
      errorHas = true;
    } else {
      setBranchNameError("");
    }

    //IFSC Code
    const ifscRegex = /^[0-9A-Z]{5}[0-9A-Z]{6}$/;
    const isIFSC_code = ifscRegex.test(profileData.ifsc_code);

    if (bank_flag && !profileData.ifsc_code) {
      setIfscCodeError("IFSC code is required");
      errorHas = true;
    } else if (profileData.ifsc_code && !isIFSC_code) {
      setIfscCodeError("Please enter a valid IFSC code");
      errorHas = true;
    } else if (profileData.ifsc_code && profileData.ifsc_code.length > 11) {
      // Maximum length check
      setIfscCodeError("IFSC code should not exceed 11 characters");
      errorHas = true;
    } else {
      setIfscCodeError("");
    }

    //Account Holder Name
    const isAccountHolderName = /^[a-zA-Z\s]+$/;
    const accountHolderName = isAccountHolderName.test(
      profileData.account_holder_name
    );

    if (bank_flag && !profileData.account_holder_name) {
      setAccountHolderNameError("Account Holder Name is required");
      errorHas = true;
    } else if (profileData.account_holder_name && !accountHolderName) {
      setAccountHolderNameError("Please enter a valid Account Holder Name");
      errorHas = true;
    } else if (
      profileData.account_holder_name &&
      (profileData.account_holder_name.length < 0 ||
        profileData.branch_name.length > 60)
    ) {
      // Maximum length check
      setAccountHolderNameError(
        "Account Holder Name should not exceed 60 characters!"
      );
      errorHas = true;
    } else {
      setAccountHolderNameError("");
    }

    // Account Number
    const accountNumberRegex = /^[0-9\s]+$/;
    const isAccountNumber = accountNumberRegex.test(profileData.account_number);

    if (bank_flag && !profileData.account_number) {
      setAccountNumberError("Account number is required");
      errorHas = true;
    } else if (profileData.account_number && !isAccountNumber) {
      setAccountNumberError("Please enter a valid Account number");
      errorHas = true;
    } else if (
      profileData.account_number &&
      (profileData.account_number.length < 6 ||
        profileData.account_number.length > 18)
    ) {
      setAccountNumberError(
        "Account number should contain 6 to 18 characters!"
      );
      errorHas = true;
    } else {
      setAccountNumberError("");
    }

    const formData = new FormData();
    formData.append("basic_info", 0);
    formData.append("address_info", 0);
    formData.append("payment_info", 1);
    // formData.append("business_info", 0);

    if (profileData.upi_id) {
      formData.append("upi_id", profileData.upi_id);
    }

    if (
      profileData.bank_name &&
      profileData.branch_name &&
      profileData.ifsc_code &&
      profileData.account_holder_name &&
      profileData.account_number
    ) {
      formData.append("bank_name", profileData.bank_name);
      formData.append("branch_name", profileData.branch_name);
      formData.append("ifsc_code", profileData.ifsc_code);
      formData.append("account_holder_name", profileData.account_holder_name);
      formData.append("account_number", profileData.account_number);
    }

    // if any errors are there don't call the api
    if (errorHas === true) return;

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      setIsBankDataChanged(false);
      setSellerStatus(true);
      showToast("success", "Profile updated successfully.");
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      console.error(errorMessage);
    } else if (res.status === 500) {
      window.location.href = "/error";
    } else {
      console.error(res.data);
    }
  };

  // update product pickup location
  const productPickUpAddressSubmit = async (event) => {
    event.preventDefault();
    // setShowAlert(false);
    let errorHas = false;
    if (isUnreachablePincode) return;

    //Sellers Address.
    const addressRegex =
      /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9,.-/&:'\s\/\\\\\-_]+$/;
    const isSellerAddress = addressRegex.test(profileData.street_address);

    if (
      profileData.street_address === "" ||
      profileData.street_address === null
    ) {
      setStreetAddressError("Address is required");
      errorHas = true;
    } else if (!isSellerAddress) {
      setStreetAddressError("Please enter a valid Address");
      errorHas = true;
    } else if (
      profileData.street_address.length < 0 ||
      profileData.street_address.length > 60
    ) {
      setStreetAddressError("Address should contain 60 characters");
      errorHas = true;
    } else {
      setStreetAddressError("");
    }

    //Pincode validation
    if (profileData.pincode === "") {
      setPincodeError("Pincode is required");
      errorHas = true;
    } else {
      setPincodeError("");
    }

    //City validation
    if (profileData.city === "" || profileData.city === null) {
      setCityError("City is required");
      errorHas = true;
    } else if (profileData.city.length < 3 || profileData.city.length > 50) {
      setCityError("City should contain 50 characters");
      errorHas = true;
    } else {
      setCityError("");
    }

    //State validation
    if (profileData.state === "") {
      setStateError("State is required");
      errorHas = true;
    } else {
      setStateError("");
    }

    if (errorHas === true) return;

    const formData = new FormData();
    formData.append("basic_info", 0);
    formData.append("address_info", 1);
    formData.append("payment_info", 0);
    // formData.append("business_info", 0);

    if (profileData.street_address !== null) {
      formData.append("street_address", profileData.street_address);
    }
    if (profileData.pincode !== null) {
      formData.append("pincode", profileData.pincode);
    }
    if (profileData.city !== null) {
      formData.append("city", profileData.city);
    }
    if (profileData.country !== null) {
      formData.append("country", profileData.country);
    }
    if (profileData.state !== null) {
      formData.append("state", profileData.state);
    }

    // as per PUT rules for  passing formData
    formData.append("_method", "PUT");

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const res = await response.json();

    if (res.status === 200) {
      setSellerStatus(true);
      setIsAddressDataChanged(false);
      showToast("success", "Profile updated successfully.");
    } else if (
      res.status === 401 &&
      res.message === "You are not authorized."
    ) {
      contextObj.setInput("prevPath", location.pathname);
      removeDataFromCookie("7b9KpQ3rW6", "/");
      removeDataFromCookie("loggedIn", "/");
      navigate(Navigation.login);
      showToast("info", "Sessions expired!");
    } else if (res.status == 401 && res.message !== "") {
      showToast("error", res.message);
    } else {
      console.error(res);
    }
  };

  // Allow only alphanumeric characters
  const onlyAlphanumeric = (e) => {
    const { key } = e;
    const regex = /^[a-zA-Z0-9]+$/;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  // character "e"  not allowed in input types numbers
  const handleKeyPress = (e) => {
    if (e.keyCode === 69 || e.key === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault();
    }
  };
  // handle phone input
  const handlePhoneInputChange = (event) => {
    let { name, value } = event.target;
    setPhoneVerifyOtpModalError("");
    setPhoneNewModalError("");
    if (name === "phone") {
      if (/^\d*$/.test(value)) {
        setNewPhone((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  // Logic For Update Phone Number
  const RequestPhoneOtp = async () => {
    let error = false;
    const isPhoneNumber = (phone) => {
      const regex = /^(\+?91[\-\s]?)?[6-9]\d{9}$/.test(phone);
      return regex;
    };

    if (newPhone.phone === "" || newPhone.phone === null) {
      setPhoneNewModalError("Phone Number is required.");
      error = true;
    } else if (!isPhoneNumber(newPhone.phone)) {
      setPhoneNewModalError("Enter Valid Phone Number.");
      error = true;
    }
    if (error === true) {
      return;
    }
    const payload = {
      phone: newPhone.phone,
    };
    setUpdateNewPhone((prevData) => ({
      ...prevData,
      phone: newPhone.phone,
    }));
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.updatePhoneStep1SendOTP}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const res = await response.json();

    if (res.status === 201) {
      setIsLoading(false);
      showToast("success", res.message);
      setNewPhoneVerifyTokenInModal(res.data);
      setShowPhonemodal(false);
      setVerifyPhoneNumberModal(true);
      setPhoneNewModalError("");
      startTimer();
      setUpdateNewPhone((prevData) => ({
        ...prevData,
        otp_verify_token: res.data,
      }));
    } else if (
      res.status === 401 ||
      res.status === 404 ||
      res.status === 409 ||
      res.status === 429
    ) {
      setIsLoading(false);
      setPhoneNewModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setPhoneNewModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  //
  const handleCloseVerifyPhoneModal = () => {
    setVerifyPhoneNumberModal(false);
    setPhoneVerifyOtpModalError("");
    setPhoneNewModalError("");
  };
  // handleOtpChange

  const handleOtpChange = (event) => {
    let { name, value } = event.target;
    setPhoneVerifyOtpModalError("");
    if (name === "otp") {
      if (/^\d*$/.test(value)) {
        setUpdateNewPhone((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  // Verify Phone Otp
  const VerifyPhoneOtp = async () => {
    let errorHas = false;
    if (updateNewPhone.otp === "" || updateNewPhone.otp === null) {
      setPhoneVerifyOtpModalError("OTP is required.");
      errorHas = true;
      setIsLoading(false);
    } else if (updateNewPhone.otp.length > 4 || updateNewPhone.otp.length < 4) {
      setPhoneVerifyOtpModalError("OTP length must be 4 characters.");
      errorHas = true;
      setIsLoading(false);
    } else {
      setPhoneVerifyOtpModalError("");
    }
    if (errorHas === true) return;
    const payload = {
      phone: updateNewPhone.phone,
      otp: updateNewPhone.otp,
      otp_verify_token: updateNewPhone.otp_verify_token,
    };
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.updatePhonestep2SendOTP}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 200) {
      setIsLoading(false);
      setVerifyPhoneNumberModal(false);
      setNewPhoneVerifyTokenInModal("");
      setPhoneVerifyOtpModalError("");
      setNewPhoneVerifyTokenInModal("");
      showToast("success", "Phone Number Updated successfully.");
      fetchProfileData();
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
      setPhoneVerifyOtpModalError(res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
      setPhoneVerifyOtpModalError(errorMessage);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(60);
  };
  // settimer
  useEffect(() => {
    let interval = null;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      resetTimer();
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const startTimer = () => {
    setIsActive(true);
  };

  // code for resend otp
  const resendVerifyOtp = async () => {
    const payload = {
      phone: updateNewPhone.phone,
      otp_verify_token: updateNewPhone.otp_verify_token,
    };
    const response = await fetch(
      `${API_HOST}/${APIEndpoint.UpdatePhoneResendOTP}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const res = await response.json();
    if (res.status === 201) {
      setIsLoading(false);
      showToast("success", `OTP sent successfully to ${updateNewPhone.phone}`);
      setNewPhoneVerifyTokenInModal(res.data);
      setPhoneNewModalError("");
      startTimer();
      setUpdateNewPhone((prevData) => ({
        ...prevData,
        otp_verify_token: res.data,
      }));
    } else if (res.status === 401 || res.status === 404) {
      setIsLoading(false);
    } else if (
      (res.status === 401 || res.status === 404) &&
      res.message !== ""
    ) {
      setIsLoading(false);
      showToast("error", res.message);
    } else if (res.status === 400) {
      const errors = res.errors;
      let errorMessage = "";
      if (errors) {
        Object.keys(errors).forEach((key) => {
          errors[key].forEach((message) => {
            errorMessage = errorMessage + " " + message;
          });
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      console.error(res);
    }
  };

  return (
    <>
      <div className="page-data">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active">Profile</li>
          </div>
        </div>

        <div className="page-container">
          <div className="profile_main_container_c1">
            <div className="profile-row">
              <div className="Admin-profile-basic-info">
                <h3 className="profile-section-heading">Basic Information</h3>
                <form onSubmit={basicInformationSubmit}>
                  <div className="profile-input">
                    <div className="same-line">
                      <div className="input-row">
                        <label className="Admin_Profile_Lable_tag">
                          <input
                            type="text"
                            name="f_name"
                            className="Admin_Profile_input_tag"
                            onChange={handleChange}
                            value={profileData.f_name}
                            placeholder="First Name"
                          />
                          <span className="Admin_Profile_span_tag">
                            First Name
                          </span>
                        </label>
                        {firstNameError && (
                          <span className="profile-error-red">
                            {firstNameError}
                          </span>
                        )}
                      </div>
                      <div className="input-row">
                        <label className="Admin_Profile_Lable_tag">
                          <input
                            type="text"
                            name="l_name"
                            className="Admin_Profile_input_tag"
                            onChange={handleChange}
                            value={profileData.l_name}
                            placeholder="Last Name"
                          />
                          <span className="Admin_Profile_span_tag">
                            Last Name
                          </span>
                        </label>
                        {lastNameError && (
                          <span className="profile-error-red">
                            {lastNameError}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="profile-button-row">
                    <div className="input-row">
                      <label className="Admin_Profile_Lable_tag">
                        <input
                          type="text"
                          name="phone"
                          maxLength={10}
                          onKeyPress={(e) => {
                            if (!/\d/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="Admin_Profile_input_tag"
                          value={profileData.phone}
                          readOnly={true}
                          // disabled={true}
                          place
                          holder="Phone"
                        />
                        <span className="Admin_Profile_span_tag"> Phone </span>
                        <span
                          className="profile-phone-edit"
                          onClick={() => {
                            // {
                            //   profileData.phone && setPhoneUpdateModal(true);
                            // }
                            setShowPhonemodal(true);
                          }}
                        >
                          <svg
                            width="12"
                            height="13"
                            viewBox="0 0 12 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.00627 11.0312C1.63437 11.0312 1.28686 10.9032 1.0369 10.6654C0.71987 10.3666 0.567452 9.91549 0.622322 9.42776L0.8479 7.45243C0.890577 7.08053 1.11615 6.5867 1.37831 6.31845L6.3837 1.02045C7.63352 -0.30253 8.93822 -0.339111 10.2612 0.910712C11.5842 2.16053 11.6208 3.46523 10.3709 4.78821L5.36555 10.0862C5.10949 10.3606 4.63395 10.6166 4.26205 10.6776L2.29891 11.0129C2.19527 11.019 2.10382 11.0312 2.00627 11.0312ZM8.34074 0.904615C7.87129 0.904615 7.46282 1.19726 7.04824 1.63622L2.04285 6.94037C1.92092 7.0684 1.78069 7.37324 1.75631 7.55004L1.53073 9.52531C1.50634 9.7265 1.55512 9.89111 1.66486 9.99475C1.7746 10.0984 1.93921 10.135 2.1404 10.1045L4.10353 9.76923C4.28034 9.73875 4.57298 9.58018 4.69491 9.45215L9.7003 4.15415C10.4563 3.34939 10.7306 2.60559 9.62714 1.56915C9.13941 1.09971 8.71873 0.904615 8.34074 0.904615Z"
                              fill="#888888"
                            />
                            <path
                              d="M9.20042 5.80701C9.18823 5.80701 9.16994 5.80701 9.15775 5.80701C7.25558 5.61801 5.72531 4.17313 5.43267 2.28315C5.39609 2.03319 5.56679 1.80152 5.81676 1.75884C6.06672 1.72226 6.2984 1.89297 6.34107 2.14293C6.57275 3.61833 7.7677 4.75232 9.25529 4.89864C9.50526 4.92302 9.68816 5.1486 9.66377 5.39853C9.63329 5.63021 9.4321 5.80701 9.20042 5.80701Z"
                              fill="#888888"
                            />
                            <path
                              d="M11.4313 12.9995H0.457252C0.207288 12.9995 0 12.7922 0 12.5422C0 12.2922 0.207288 12.085 0.457252 12.085H11.4313C11.6813 12.085 11.8885 12.2922 11.8885 12.5422C11.8885 12.7922 11.6813 12.9995 11.4313 12.9995Z"
                              fill="#888888"
                            />
                          </svg>
                        </span>
                      </label>

                      {phoneError && (
                        <span className="profile-error-red">{phoneError}</span>
                      )}
                    </div>
                    <div className="button_container_input">
                      <button
                        disabled={!isDataChanged}
                        className="btn primary-btn"
                        style={{
                          opacity: isDataChanged ? 1 : 0.5,
                          pointerEvents: isDataChanged ? "" : "none",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="profile-row-second">
                <div className="Admin-profile-bank-info">
                  <h3 className="profile-section-heading">
                    Payment Information
                  </h3>
                  <p
                    className="profile-sub-heading"
                    style={{ color: "#d78d00" }}
                  >
                    We will use this payment information to transfer successful
                    order amount.
                  </p>
                  <form onSubmit={bankInformationSubmit}>
                    <div className="bank-input">
                      <div className="input-row">
                        <label className="Admin_Profile_Lable_tag">
                          <input
                            type="text"
                            name="upi_id"
                            className="Admin_Profile_input_tag"
                            onChange={handleChange}
                            value={profileData.upi_id}
                            minLength={0}
                            maxLength={60}
                            placeholder="UPI ID"
                          />
                          <span className="Admin_Profile_span_tag">
                            {" "}
                            UPI ID{" "}
                          </span>
                        </label>
                        {upiIdError && (
                          <span className="profile-error-red">
                            {upiIdError}
                          </span>
                        )}
                      </div>
                      <p className="or-payment-info">OR</p>
                      <div className="input-row">
                        <label className="Admin_Profile_Lable_tag">
                          <input
                            type="text"
                            name="bank_name"
                            className="Admin_Profile_input_tag"
                            onChange={handleChange}
                            value={profileData.bank_name}
                            minLength={0}
                            maxLength={60}
                            placeholder="Bank Name"
                          />
                          <span className="Admin_Profile_span_tag">
                            {" "}
                            Bank Name{" "}
                          </span>
                        </label>
                        {bankNameError && (
                          <span className="profile-error-red">
                            {bankNameError}
                          </span>
                        )}
                      </div>
                      <div className="same-line">
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            {" "}
                            <input
                              type="text"
                              name="ifsc_code"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.ifsc_code}
                              onKeyPress={onlyAlphanumeric}
                              minLength={11}
                              maxLength={11}
                              placeholder=" IFSC Code"
                            />
                            <span className="Admin_Profile_span_tag">
                              {" "}
                              IFSC Code{" "}
                            </span>
                          </label>
                          {ifscCodeError && (
                            <span className="profile-error-red">
                              {ifscCodeError}
                            </span>
                          )}
                        </div>
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="branch_name"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.branch_name}
                              minLength={0}
                              maxLength={60}
                              placeholder="Branch Name"
                            />
                            <span className="Admin_Profile_span_tag">
                              {" "}
                              Branch Name{" "}
                            </span>
                          </label>
                          {branchNameError && (
                            <span className="profile-error-red">
                              {branchNameError}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="same-line">
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="account_number"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.account_number}
                              minLength={6}
                              maxLength={18}
                              onKeyPress={handleKeyPress}
                              placeholder="Account Number"
                            />
                            <span className="Admin_Profile_span_tag">
                              {" "}
                              Account Number{" "}
                            </span>
                          </label>
                          {accountNumberError && (
                            <span className="profile-error-red">
                              {accountNumberError}
                            </span>
                          )}
                        </div>
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="account_holder_name"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.account_holder_name}
                              minLength={0}
                              maxLength={60}
                              placeholder="Account Holder Name"
                            />
                            <span className="Admin_Profile_span_tag">
                              {" "}
                              Account Holder Name{" "}
                            </span>
                          </label>
                          {accountHolderNameError && (
                            <span className="profile-error-red">
                              {accountHolderNameError}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {paymentError && (
                      <p className="profile-error-red payment-error-line">
                        {paymentError}
                      </p>
                    )}
                    <div className="profile-button-row profile-check-button">
                      <button
                        disabled={!isBankDataChanged}
                        className="btn primary-btn"
                        style={{
                          opacity: isBankDataChanged ? 1 : 0.5,
                          pointerEvents: isBankDataChanged ? "" : "none",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
                <div className="Admin-profile-pickup-info">
                  <h3 className="profile-section-heading">Pickup Address</h3>
                  <p
                    className="profile-sub-heading"
                    style={{ color: "#d78d00" }}
                  >
                    We will use this address to manage pickup items from your
                    location.
                  </p>
                  <form onSubmit={productPickUpAddressSubmit}>
                    <div className="pickup-input">
                      <div className="same-line">
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="pincode"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.pincode}
                              onKeyPress={handleKeyPress}
                              minLength={6}
                              maxLength={6}
                              placeholder="Pincode"
                            />
                            <span className="Admin_Profile_span_tag">
                              Pincode
                            </span>
                            <span className="profile-pincode-loader-edit">
                              {pincodeLoading && (
                                <img
                                  src={loadingImgPath}
                                  className="profile-pincode-loading-img"
                                />
                              )}
                            </span>
                          </label>

                          {pincodeError && (
                            <span className="profile-error-red">
                              {pincodeError}
                            </span>
                          )}
                        </div>
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="country"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value="India"
                              readOnly
                              placeholder="Country"
                            />
                            <span className="Admin_Profile_span_tag">
                              Country
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="same-line">
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <input
                              type="text"
                              name="city"
                              className="Admin_Profile_input_tag"
                              onChange={handleChange}
                              value={profileData.city}
                              placeholder="City"
                            />
                            <span className="Admin_Profile_span_tag">City</span>
                          </label>
                          {cityError && (
                            <span className="profile-error-red">
                              {cityError}
                            </span>
                          )}
                        </div>
                        <div className="input-row">
                          <label className="Admin_Profile_Lable_tag">
                            <select
                              name="state"
                              className="Admin_Profile_input_tag"
                              value={profileData.state}
                              onChange={handleChange}
                              placeholder="State"
                            >
                              <option value="">Select</option>
                              <option value="Andaman and Nicobar">
                                Andaman & Nicobar
                              </option>
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra & Nagar Haveli
                              </option>
                              <option value="Daman and Diu">Daman & Diu</option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu & Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                            {stateError && (
                              <span className="profile-error-red">
                                {stateError}
                              </span>
                            )}
                            <span className="Admin_Profile_span_tag">
                              State{" "}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="input-row">
                        <label className="Admin_Profile_Lable_tag">
                          <input
                            type="text"
                            name="street_address"
                            className="Admin_Profile_input_tag"
                            onChange={handleChange}
                            value={profileData.street_address}
                            placeholder="Address (e.g. House number, building name, road name,
                        area, colony)"
                          />
                          <span className="Admin_Profile_span_tag">
                            {" "}
                            Address{" "}
                            <span className="Admin_ProfileAddress_WebViewOnly">
                              (e.g. House number, building name, road name,
                              area, colony)
                            </span>
                          </span>
                        </label>
                        {streetAddressError && (
                          <span className="profile-error-red">
                            {streetAddressError}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="profile-button-row profile-check-button">
                      <button
                        disabled={!isAddressDataChanged}
                        className="btn primary-btn"
                        style={{
                          opacity: isAddressDataChanged ? 1 : 0.5,
                          pointerEvents: isAddressDataChanged ? "" : "none",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="hidden-class_to_dis">
              <div className="same-line">
                <div className="input-row">
                  <label className="Admin_Profile_Lable_tag">
                    <input
                      type="email"
                      name="email"
                      style={{ border: "none" }}
                      className="Admin_Profile_input_tag"
                      value={profileData.email}
                      readOnly={true}
                      placeholder="Email"
                    />
                    <span className="Admin_Profile_span_tag">Email</span>
                  </label>
                </div>
              </div>
              <div className="input-row">
                <label className="Admin_Profile_Lable_tag">
                  <input
                    type="text"
                    name="role_name"
                    style={{ border: "none" }}
                    className="Admin_Profile_input_tag"
                    value={profileData.role_name}
                    readOnly={true}
                    placeholder="Role Name"
                  />
                  <span className="Admin_Profile_span_tag">Role Name</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* newPhoneNumberModal */}
      {showPhonemodal && (
        <div className="Phone_modal">
          <div className="Phone_modal_contain">
            <span
              className="float-right"
              onClick={() => {
                handleCloseNewPhoneModal();
              }}
            >
              <FontAwesomeIcon icon={faX} className="phone-modal-close-icon" />
            </span>
            <h3 className="phone_modal_header">Update Mobile Number</h3>
            <div className="modal-body">
              <div className="" style={{ textAlign: "left" }}>
                <label className="phone_Number_Update_label">
                  <input
                    type="text"
                    className="phone_Number_Update_input"
                    placeholder="Mobile Number"
                    name="phone"
                    maxLength={10}
                    value={newPhone.phone}
                    onChange={handlePhoneInputChange}
                  />
                  <span className="phone_Number_Update_span">
                    {" "}
                    Mobile Number
                  </span>
                </label>
                {phoneNewModalError && (
                  <span className="phone-modal-error-red">
                    {phoneNewModalError}
                  </span>
                )}
              </div>
            </div>
            <div className="button_Container">
              {isActive ? (
                <button className="send_Otp_Button_disable">
                  Request OTP in {formatTime(seconds)}
                </button>
              ) : (
                <button className="send_Otp_Button" onClick={RequestPhoneOtp}>
                  Request OTP
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* verifyPhoneNumberModal */}
      {verifyPhoneNumberModal && (
        <div className="Phone_modal">
          <div className="Phone_modal_contain">
            <span
              className="float-right"
              onClick={() => {
                handleCloseVerifyPhoneModal();
              }}
            >
              <FontAwesomeIcon icon={faX} className="phone-modal-close-icon" />
            </span>
            <h3 className="phone_modal_header">Update Mobile</h3>
            <div className="modal-body">
              <div className="" style={{ textAlign: "left" }}>
                <label className="phone_Number_Update_label">
                  <input
                    type="text"
                    className="phone_Number_Update_input"
                    placeholder="Phone"
                    name="phone"
                    readOnly
                    value={updateNewPhone.phone}
                  />
                  <span className="phone_Number_Update_span"> Phone</span>
                  <span
                    className="profile-phone-edit"
                    onClick={() => {
                      setVerifyPhoneNumberModal(false);
                      setShowPhonemodal(true);
                    }}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00627 11.0312C1.63437 11.0312 1.28686 10.9032 1.0369 10.6654C0.71987 10.3666 0.567452 9.91549 0.622322 9.42776L0.8479 7.45243C0.890577 7.08053 1.11615 6.5867 1.37831 6.31845L6.3837 1.02045C7.63352 -0.30253 8.93822 -0.339111 10.2612 0.910712C11.5842 2.16053 11.6208 3.46523 10.3709 4.78821L5.36555 10.0862C5.10949 10.3606 4.63395 10.6166 4.26205 10.6776L2.29891 11.0129C2.19527 11.019 2.10382 11.0312 2.00627 11.0312ZM8.34074 0.904615C7.87129 0.904615 7.46282 1.19726 7.04824 1.63622L2.04285 6.94037C1.92092 7.0684 1.78069 7.37324 1.75631 7.55004L1.53073 9.52531C1.50634 9.7265 1.55512 9.89111 1.66486 9.99475C1.7746 10.0984 1.93921 10.135 2.1404 10.1045L4.10353 9.76923C4.28034 9.73875 4.57298 9.58018 4.69491 9.45215L9.7003 4.15415C10.4563 3.34939 10.7306 2.60559 9.62714 1.56915C9.13941 1.09971 8.71873 0.904615 8.34074 0.904615Z"
                        fill="#888888"
                      />
                      <path
                        d="M9.20042 5.80701C9.18823 5.80701 9.16994 5.80701 9.15775 5.80701C7.25558 5.61801 5.72531 4.17313 5.43267 2.28315C5.39609 2.03319 5.56679 1.80152 5.81676 1.75884C6.06672 1.72226 6.2984 1.89297 6.34107 2.14293C6.57275 3.61833 7.7677 4.75232 9.25529 4.89864C9.50526 4.92302 9.68816 5.1486 9.66377 5.39853C9.63329 5.63021 9.4321 5.80701 9.20042 5.80701Z"
                        fill="#888888"
                      />
                      <path
                        d="M11.4313 12.9995H0.457252C0.207288 12.9995 0 12.7922 0 12.5422C0 12.2922 0.207288 12.085 0.457252 12.085H11.4313C11.6813 12.085 11.8885 12.2922 11.8885 12.5422C11.8885 12.7922 11.6813 12.9995 11.4313 12.9995Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                </label>
                {phoneNewModalError && (
                  <span className="phone-modal-error-red">
                    {phoneNewModalError}
                  </span>
                )}
              </div>
              <li className="ResendOtpButton">
                <p
                  id="sellers-Profile_Deactivate_editresendVerifyOtp"
                  style={{
                    fontSize: "0.75rem",
                    margin: "0px",
                    color: "#3366cc",
                    cursor: isActive ? "default" : "pointer",
                  }}
                  onClick={() => {
                    if (!isActive) {
                      resendVerifyOtp();
                    }
                  }}
                >
                  {isActive
                    ? `Resend OTP after ${" "}
                    ${formatTime(seconds)}`
                    : "Resend OTP"}
                </p>
              </li>
              <div>
                <label className="phone_Number_Update_label">
                  <input
                    type="text"
                    className="phone_Number_Update_input"
                    name="otp"
                    maxLength={4}
                    value={updateNewPhone.otp}
                    placeholder="OTP"
                    onChange={handleOtpChange}
                  />
                  <span className="phone_Number_Update_span"> OTP</span>
                </label>
              </div>
              <span className="phone-modal-error-red">
                {" "}
                {phoneVerifyOtpModalError}{" "}
              </span>
            </div>
            <div className="button_Container">
              <button className="send_Otp_Button" onClick={VerifyPhoneOtp}>
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyProfilePage;
