import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import "./policies.css";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Toast from "../../../Components/Toast";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Paginate from "../../../Components/Pagination/Paginate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../Components/LoaderButton";
import InputContext from "../../../Components/InputContext";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const Policies = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [policyData, setPolicyData] = useState([]);
  const navigate = useNavigate();
  const { showToast } = Toast();
  const token = getDataFromCookies("7b9KpQ3rW6");
  const PolicyPerPage = 10;
  let [currentPage, setCurrentPage] = useState(1);
  let [totalCount, setTotalCount] = useState(null);
  let [lastPage, setLastPage] = useState(null);
  let [searchQuery, setSearchQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [pagination, setPagination] = useState("");
  const [isSearchApplied, setIsSearchApplied] = useState(false);

  const contextObj = useContext(InputContext);
  const handleURLChange = (data) => {
    window.scrollTo(0, 0);
    getPolicies(data);
  };

  const Url = `${API_HOST}/${APIEndpoint.crudForPolicies}?page_no=${currentPage}`;

  const getPolicies = async (Url) => {
    try {
      if (searchQuery !== "") {
        Url = Url + "&search=" + searchQuery;
      }
      const response = await fetch(`${Url}&per_page=${PolicyPerPage}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const responseData = await response.json();
        setPolicyData(responseData.data.data);
        setCurrentPage(responseData.data.current_page);
        setTotalCount(responseData.data.total);
        setLastPage(responseData.data.last_page);
        setPagination(responseData.data);
      } else if (response.status === 404) {
        setPolicyData([]);
        setPagination("");
      } else if (
        response.status === 401 &&
        response.status === "You are not authorized."
      ) {
        contextObj.setInput("prevPath", location.pathname);
        handleUnauthorized();
      } else if (response.status == 401 && response.message !== "") {
        showToast("error", response.message);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    } finally {
      setLoading(false);
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    const isOnAddEditPolicy = contextObj.inputValue.isOnAddEditPolicy;
    const isPolicyAdded = getDataFromCookies("isPolicyAdded");
    let currentPoliciesPageis = contextObj.inputValue.currentPoliciesPageis;
    const NoOfTotalProductsCountIs = getDataFromCookies(
      "NoOfTotalProductsCountIs"
    );
    let LastPageis = getDataFromCookies("LastPageis");
    if (isOnAddEditPolicy) {
      if (isPolicyAdded) {
        LastPageis =
          NoOfTotalProductsCountIs % PolicyPerPage == 0
            ? LastPageis + 1
            : LastPageis;
        getPolicies(
          `${API_HOST}/${APIEndpoint.crudForPolicies}?page_no=${LastPageis}`
        );
        removeDataFromCookie("isPolicyAdded");
        return;
      } else {
        getPolicies(
          `${API_HOST}/${APIEndpoint.crudForPolicies}?page_no=${currentPoliciesPageis}`
        );
        return;
      }
    }
    getPolicies(Url);
  }, []);

  useEffect(() => {
    if (isSearchApplied) {
      setSearchLoading(true);
      const debounceTimeout = setTimeout(() => {
        setLoading(true);
        currentPage = 1;
        getPolicies(
          `${API_HOST}/${APIEndpoint.crudForPolicies}?page_no=${currentPage}`
        );
      }, 1000);
      return () => {
        clearTimeout(debounceTimeout);
      };
    }
  }, [searchQuery]);

  const handleUnauthorized = () => {
    removeDataFromCookie("7b9KpQ3rW6", "/");
    removeDataFromCookie("loggedIn", "/");
    navigate("/");
    showToast("info", "Sessions expired! Please login again");
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1 + (currentPage - 1) * PolicyPerPage,
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Module",
        accessor: "module",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <div>
              <button
                onClick={() => {
                  navigate(`/policies/edit/${id}`);
                  contextObj.setInput("currentPoliciesPageis", currentPage);
                  setDataToCookies("NoOfTotalProductsCountIs", totalCount);
                }}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            </div>
          );
        },
      },
    ],
    [currentPage]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: policyData,
      initialState: { pageIndex: 0, pageSize: PolicyPerPage, globalFilter: "" },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="page-data">
      <div className="page-heading">
        <div className="breadcrumb">
          <li className="active">Policies</li>
        </div>
      </div>
      <div className="page-container">
        <div className="table-serach-area">
          <div className="search-bar">
            <div className="fontAwsomeDiv">
              {searchLoading ? (
                <LoaderButton />
              ) : (
                <FontAwesomeIcon className="SearchIconDiv" icon={faSearch} />
              )}
            </div>
            <input
              id="seller-searchForProducts"
              placeholder="Search..."
              className="search-input-order"
              name="search-query"
              value={searchQuery}
              onChange={(e) => {
                e.preventDefault();
                const val = e.target.value;
                setSearchQuery(val);
                setIsSearchApplied(true);
              }}
            />
          </div>
          <button
            onClick={() => {
              navigate("/policies/new");
              contextObj.setInput("currentPoliciesPageis", currentPage);
              setDataToCookies("NoOfTotalProductsCountIs", totalCount);
              setDataToCookies("LastPageis", lastPage);
            }}
            className="btn primary-btn"
          >
            Add Policy
          </button>
        </div>

        <div className="policylistContainer">
          <div className="policylistContainerHeader InnerTableContainer">
            <table
              id="categoryTable"
              className="order-table"
              {...getTableProps()}
            >
              <thead className="Category-grid-container-item2-tableHead">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className="Category-grid-container-item2-tableHead">
                        {" "}
                        <div className="header-cell">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {loading ? (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">Loading...</div>
                    </td>
                  </tr>
                ) : page.length > 0 ? (
                  // Check if there is data in the current page
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr className="TableBodyData" {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={headerGroups[0].headers.length}>
                      <div className="nomatchfound">No Policy available.</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="pagination-container">
          {pagination && (
            <Paginate
              pagination={pagination}
              pageChange={handleURLChange}
              pageName={"Policies"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Policies;
