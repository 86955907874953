import React from "react";
import "./pagination.css";
import Navigation from "../../config/Navigation.json";
import { useNavigate } from "react-router-dom";

const Paginate = (props) => {
  const navigate = useNavigate();
  const { pagination, pageChange, pageName, filterTotal } = props;

  return (
    <>
      {!filterTotal && (
        <div className="paginationCount">
          Showing <span>{pagination.from}</span> to <span>{pagination.to}</span>{" "}
          of <span>{pagination.total}</span> {pageName}
        </div>
      )}
      <ul className="pagination">
        {/* Previous Button */}
        <li
          className={`page-item ${
            pagination.prev_page_url === null ? "disabled-pagination" : ""
          }`}
          onClick={() => {
            if (pageName == "Users" && pagination.prev_page_url !== null) {
              let prevPage = pagination.current_page - 1;
              navigate(`${Navigation.adminListEdit}?pageNo=${prevPage}`);
            } else if (pagination.prev_page_url !== null) {
              pageChange(pagination.prev_page_url);
            }
          }}
        >
          <a
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Previous
          </a>
        </li>

        {/* Pages */}
        {pagination &&
          pagination.links &&
          pagination.links.map((link, index) => {
            if (index !== 0 && index !== pagination.links.length - 1) {
              return (
                <>
                  {/* Current Page */}
                  {link.active ? (
                    <li key={index} className="page-item active-pagination">
                      <span className="page-link">{link.label}</span>
                    </li>
                  ) : (
                    <>
                      {/* Others Page */}
                      {link.url ? (
                        <li
                          key={index}
                          className="page-item"
                          onClick={() => {
                            if (link.url !== null) {
                              if (pageName == "Users") {
                                let nextPage = index;
                                navigate(
                                  `${Navigation.adminListEdit}?pageNo=${nextPage}`
                                );
                              } else {
                                pageChange(link.url);
                              }
                            }
                          }}
                        >
                          <a
                            className="page-link"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {link.label}
                          </a>
                        </li>
                      ) : (
                        // Dots
                        <li key={index} className="page-item">
                          {link.label}
                        </li>
                      )}
                    </>
                  )}
                </>
              );
            }
          })}

        {/* Next Button */}
        <li
          className={`page-item ${
            pagination.next_page_url === null ? "disabled-pagination" : ""
          }`}
          onClick={() => {
            if (pageName == "Users" && pagination.next_page_url !== null) {
              let nextPage = pagination.current_page + 1;
              navigate(`${Navigation.adminListEdit}?pageNo=${nextPage}`);
            } else if (pagination.next_page_url !== null) {
              pageChange(pagination.next_page_url);
            }
          }}
        >
          <a
            className="page-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            Next
          </a>
        </li>
      </ul>
    </>
  );
};

export default Paginate;
