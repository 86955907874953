import { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./collection.css";
import CollectionNavigation from "./CollectionNavigationPage";
import {
  getDataFromCookies,
  removeDataFromCookie,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
import InputContext from "../../../Components/InputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import imgHost from "../../../Components/ReUsed";
import stringConstant from "../../../config/ProjectConstants.json";
import Navigation from "../../../config/Navigation.json";
import imgPlaceholder from "../../../Assets/image-placeholder.png";
import LoaderButton from "../../../Components/LoaderButton";

const API_HOST = process.env.REACT_APP_HOST_API_URL;

const AgeCollection = () => {
  const location = useLocation();
  const { showToast } = Toast();
  const navigate = useNavigate();
  const contextObj = useContext(InputContext);
  const token = getDataFromCookies("7b9KpQ3rW6");
  const [updating, setUpdating] = useState(false);
  const [ageDataList, setAgeDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAgeEditCollection, setShowAgeEditCollection] = useState(false);
  const [thumbnailError, setThumbnailError] = useState("");
  const [imageError, setImageError] = useState("");
  const [ageTitleError, setAgeTitleError] = useState("");
  const [priceMinimumError, setPriceMinimumError] = useState("");
  const [priceMaximumError, setPriceMaximumError] = useState("");
  const [agesEditParams, setAgesEditParams] = useState({
    id: "",
    title: "",
    image: null,
    min: "",
    max: "",
  });

  //image state
  let [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });

  // Get List Of Age Data
  const GetAgeData = async () => {
    await fetch(`${API_HOST}/api/admin/age-groups`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dataArray = data.data;
        if (Array.isArray(dataArray)) {
          setAgeDataList(dataArray);
        } else if (
          data.status === 401 &&
          data.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (data.status == 401 && data.message !== "") {
          showToast("error", data.message);
        } else {
          console.error("Data is not an array");
        }
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    GetAgeData();
  }, []);

  const thumbImgUpload = (event) => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["png", "jpg", "jpeg", "webp", "gif", "svg"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setThumbnailError(
          "Please upload an image with .png, .jpg, .jpeg, .webp, .gif or .svg extension."
        );
        return;
      }
      if (file.size > maxFileSize) {
        compressImage(file, maxFileSize);
      } else {
        readFile(file);
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = image.width;
          let height = image.height;

          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;
            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                // "Compression failed to meet target size requirement."
                // Handle the error as needed
                return;
              }
              setAgesEditParams((prevData) => ({
                ...prevData,
                image: compressedFile,
              }));
              // setImageUpdated(true);
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // img remove after upload
  const inputFileThumbRef = useRef(null);
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setAgesEditParams((prev) => ({
      ...prev,
      image: null,
    }));
    if (inputFileThumbRef.current) {
      inputFileThumbRef.current.value = null;
    }
  };
  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  // const open Edit Modal
  const openEditModal = (id, image, title, min, max) => {
    setAgesEditParams((prevData) => ({
      ...prevData,
      id: id,
      title: title,
      image: image,
      min: min,
      max: max,
    }));
    setContainer1({
      image: `${imgHost}/${image}`,
    });
    setShowAgeEditCollection(true);
    setUpdating(false);
  };

  // Const handleInputChange
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "image") {
      setThumbnailError("");
      setImageError("");
      const file = event.target.files[0];
      setAgesEditParams((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else if (name === "title") {
      setAgeTitleError("");
      setAgesEditParams((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      if (value.trim() !== "" || value == "") {
        setPriceMaximumError("");
        setPriceMinimumError("");
        if (/^\d*$/.test(value)) {
          setAgesEditParams((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "S No.",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => (
          <img
            src={imgHost + "/" + value}
            alt="image"
            onError={(e) => {
              e.target.src = imgPlaceholder;
            }}
          />
        ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Minimum Age",
        accessor: "min",
      },
      {
        Header: "Maximum Age",
        accessor: "max",
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }) => {
          const id = row.original.id;
          const image = row.original.image;
          const title = row.original.title;
          const min = row.original.min;
          const max = row.original.max;
          return (
            <div>
              <button
                onClick={() => openEditModal(id, image, title, min, max)}
                className="action-icon edit-button"
                title="Edit"
                style={{
                  opacity: 1,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: ageDataList,
      initialState: { pageIndex: 0, pageSize: 10, globalFilter: "" },
      state: (state) => ({
        ...state,
        noMatchFound: rows.length === 0 && state.globalFilter !== "",
      }),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // handle
  const handleCloseEditModal = () => {
    setShowAgeEditCollection(false);
    setAgeTitleError("");
    setImageError("");
    setUpdating(false);
  };

  // update Age Collection Details

  const updateAgeCollectionDetails = async (ageid) => {
    let errorHas = false;
    setUpdating(true);
    if (agesEditParams.title === "" || agesEditParams.title === null) {
      setAgeTitleError(stringConstant.errorMessages.alertForAgeTitle);
      errorHas = true;
    } else if (
      agesEditParams.title.length < 5 ||
      agesEditParams.title.length > 50
    ) {
      setAgeTitleError(stringConstant.errorMessages.alertForAgeLength);
      errorHas = true;
    } else {
      setAgeTitleError("");
    }

    if (agesEditParams.image === "" || agesEditParams.image === null) {
      setImageError(stringConstant.errorMessages.errorForBlankImage);
      errorHas = true;
    }
    if (agesEditParams.min === "" || agesEditParams.min === null) {
      setPriceMinimumError(stringConstant.errorMessages.alertForMinimumAge);
      errorHas = true;
    } else if (Number(agesEditParams.min) >= Number(agesEditParams.max)) {
      setPriceMinimumError(stringConstant.errorMessages.alertforMinAgeGreter);
      errorHas = true;
    }

    if (agesEditParams.max === "" || agesEditParams.max === null) {
      setPriceMaximumError(stringConstant.errorMessages.alertForMaximumAgee);
      errorHas = true;
    } else if (Number(agesEditParams.max) <= Number(agesEditParams.min)) {
      setPriceMaximumError(stringConstant.errorMessages.alertforMaxAgeLess);
      errorHas = true;
    }

    if (errorHas === true) {
      setUpdating(false);
      return;
    }

    const formData = new FormData();
    // formData.append("collection_name", "age");
    formData.append("title", agesEditParams.title);
    formData.append("image", agesEditParams.image);
    formData.append("min", agesEditParams.min);
    formData.append("max", agesEditParams.max);
    formData.append("_method", "PUT");
    const url = `${API_HOST}/api/admin/age-groups/${ageid}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 200) {
          showToast("success", "Age Collection Updated Successfully");
          handleCloseEditModal();
          GetAgeData();
        } else if (res.status === 400) {
          if (res.errors.title) {
            setAgeTitleError(res.errors.title[0]);
          }
          if (res.errors.image) {
            setThumbnailError(res.errors.image[0]);
          }
          if (res.errors.min) {
            setPriceMinimumError(res.errors.min[0]);
          }
          if (res.errors.max) {
            setPriceMaximumError(res.errors.max[0]);
          }
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "sessionExpired");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        }
        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        console.log("err :", err);
      });
  };

  return (
    <div className="page-data">
      <CollectionNavigation />
      <div className="price_table_container InnerTableContainer">
        <table className="order-table" {...getTableProps()}>
          <thead className="Category-grid-container-item2-tableHead">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className="Category-grid-container-item2-tableHead">
                    <div className="header-cell">
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  <div className="nomatchfound">Loading...</div>
                </td>
              </tr>
            ) : page.length > 0 ? (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="TableBodyData" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  <div className="nomatchfound">No price available.</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {showAgeEditCollection && (
          <div className="Price_Edit_Blogs">
            {" "}
            <div className="price_Edit_Modal_C1">
              {" "}
              <div className="cross_b_c1">
                <button onClick={handleCloseEditModal} className="Cross_Button">
                  <FontAwesomeIcon className="icons-cross" icon={faX} />
                </button>
              </div>
              <div>
                <h1 className="heading_Price"> Edit Age Collection</h1>
                <label className="custom-input-label-field">
                  <input
                    type="text"
                    placeholder="Age Title"
                    className="custom-input-field"
                    name="title"
                    autoComplete="off"
                    value={agesEditParams.title}
                    onChange={handleInputChange}
                  />
                  <span className="custom-label-field">Title</span>
                </label>
                <span className="Add_Blog_error">{ageTitleError}</span>
                <div>
                  <label className="AgeCollection_label_tag">
                    Image (600 x 600) px
                  </label>
                  <div
                    id="sellers-uploadThumbImage-forAGE"
                    onClick={(e) => {
                      thumbImgUpload(e);
                    }}
                    className="dropzoneForThumbnail"
                    onMouseEnter={handleMouseEnterThumbImg}
                    onMouseLeave={handleMouseLeaveThumbImg}
                    style={{
                      position: "relative",
                    }}
                  >
                    {container1.image ? (
                      <div
                        className="image-placeholderForThumbnail-age"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "grid",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <img
                          className="banner-image-storing-containerForThumbnail"
                          src={container1.image}
                          loading="lazy"
                        />
                        {container1.hovering && (
                          <button
                            id="sellers-removeThumbImgae-ofProduct"
                            className="delete-button"
                            onClick={handleDeleteThumbClick}
                          >
                            Remove Image
                          </button>
                        )}
                      </div>
                    ) : (
                      <p id="sellers-clickToUpload-Thumbimage-ofProduct">
                        Click to upload
                      </p>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp, .gif, .svg"
                      name="image"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      id="thumb-img-input"
                      hidden
                      style={{
                        border: "1px solid red",
                        width: "100%",
                        opacity: "0",
                        margin: "0px",
                        height: "100%",
                        position: "absolute",
                      }}
                    />
                  </div>
                  <label className="ErrorFor_Banner_url">
                    {thumbnailError}
                    {imageError}
                  </label>
                </div>
                <label className="custom-input-label-field">
                  <input
                    type="text"
                    placeholder="Minimum Age"
                    className="custom-input-field"
                    name="min"
                    autoComplete="off"
                    value={agesEditParams.min}
                    onChange={handleInputChange}
                  />
                  <span className="custom-label-field">Minimum Age</span>
                </label>
                <span className="Add_Blog_error">{priceMinimumError}</span>
                <label className="custom-input-label-field">
                  <input
                    type="max"
                    placeholder="Maximum Age"
                    className="custom-input-field"
                    name="max"
                    autoComplete="off"
                    value={agesEditParams.max}
                    onChange={handleInputChange}
                  />
                  <span className="custom-label-field">Maximum Age</span>
                </label>
                <span className="Add_Blog_error">{priceMaximumError}</span>
              </div>
              <div>
                <button
                  className="Price_Collection_Save_Button"
                  onClick={() => {
                    updateAgeCollectionDetails(agesEditParams.id);
                  }}
                >
                  {updating ? <LoaderButton /> : "SAVE"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgeCollection;
