import React, { useEffect, useState, useRef, useContext } from "react";
import InputContext from "../../../Components/InputContext";
import { json, useNavigate, useParams, useLocation } from "react-router-dom";
import APIEndpoint from "../../../config/APIEndpoints.json";
import Navigation from "../../../config/Navigation.json";
import LoaderButton from "../../../Components/LoaderButton";
import imgHost from "../../../Components/ReUsed";
import {
  getDataFromCookies,
  removeDataFromCookie,
  setDataToCookies,
} from "../../../Components/Cookie";
import Toast from "../../../Components/Toast";
const API_HOST = process.env.REACT_APP_HOST_API_URL;

const EditNGOs = () => {
  const location = useLocation();
  const contextObj = useContext(InputContext);
  const [imageUpdated, setImageUpdated] = useState(true);
  const { ngoId } = useParams();
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [product, setProduct] = useState({
    image: null,
    org_name: "",
    org_phone: "",
    org_email: "",
    certificate_name: "",
    certificate_no: "",
    address1: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_phone: "",
    contact_person_address: "",
    status: "",
    about: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "",
  });
  //Error handeling.
  const [imageError, setImageError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [certficateNameError, setCertficateNameError] = useState("");
  const [certificateNoError, setCertificateNoError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [personNameError, setPersonNameError] = useState("");
  const [personEmailError, setPersonEmailError] = useState("");
  const [personPhoneError, setPersonPhoneError] = useState("");
  const [personAddressError, setPersonAddressError] = useState("");
  const [aboutNgoError, setAboutNgoError] = useState("");
  const [blockUnblockError, setBlockUnblockError] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const [container1, setContainer1] = useState({
    dragging: false,
    image: null,
  });
  const { showToast } = Toast();
  const setImagesToContainer = (images) => {
    setContainer1({
      ...container1,
      image: `${imgHost + "/" + images.image}`,
    });
  };

  const thumbImgUpload = () => {
    const inputElement = document.getElementById("thumb-img-input");
    inputElement.click();
    inputElement.addEventListener("change", handleInputChange);

    function handleInputChange(event) {
      // setThumbnailError(null);
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "jpeg", "webp"];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        return;
      }
      if (file.size > maxFileSize) {
        compressImage(file, maxFileSize);
      } else {
        readFile(file);
      }
    }

    function compressImage(file, maxSize) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
        image.onload = function () {
          const maxWidth = 800; // Maximum width for the compressed image
          const maxHeight = 800; // Maximum height for the compressed image
          let width = image.width;
          let height = image.height;

          // Calculate new dimensions if the original image is larger than the maximum dimensions
          if (width > maxWidth || height > maxHeight) {
            const aspectRatio = width / height;

            if (width > height) {
              width = maxWidth;
              height = width / aspectRatio;
            } else {
              height = maxHeight;
              width = height * aspectRatio;
            }
          }
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            function (blob) {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              if (compressedFile.size > maxSize) {
                // "Compression failed to meet target size requirement."
                // Handle the error as needed
                return;
              }
              setProduct((prevData) => ({
                ...prevData,
                image: compressedFile,
              }));
              readFile(compressedFile);
            },
            file.type,
            1 // Adjust the compression quality (0.7 means 70% quality)
          );
        };
      };
      reader.readAsDataURL(file);
    }

    function readFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        setContainer1((prevContainer) => ({
          ...prevContainer,
          image: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };
  // img remove after upload
  const inputFileThumbRef = useRef(null);
  const handleRemoveThumbImage = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setContainer1((prevContainer) => ({
      ...prevContainer,
      image: null,
    }));
    setProduct((prevData) => ({
      ...prevData,
      image: null,
    }));
    // Clear the value of the input file element
    if (inputFileThumbRef.current) {
      inputFileThumbRef.current.value = null;
    }
  };
  const handleMouseEnterThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: true,
    }));
  };
  const handleMouseLeaveThumbImg = () => {
    setContainer1((prevContainer) => ({
      ...prevContainer,
      hovering: false,
    }));
  };
  const handleDeleteThumbClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
    handleRemoveThumbImage();
  };

  const fetchNgoDetails = async (ngoId) => {
    const token = getDataFromCookies("7b9KpQ3rW6");
    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }
    await fetch(`${API_HOST}/${APIEndpoint.crudNgo}/${ngoId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          setProduct(res.data);
          setImagesToContainer(res.data);
        } else if (res.status === 404) {
          console.error("bad Request", res.status);
        } else if (
          res.status === 401 &&
          res.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (res.status == 401 && res.message !== "") {
          showToast("error", res.message);
        }
      });
  };
  useEffect(() => {
    fetchNgoDetails(ngoId);
  }, []);

  const handleClick = () => {
    const url = `${API_HOST}/${APIEndpoint.crudNgo}/${ngoId}`;
    const token = getDataFromCookies("7b9KpQ3rW6");

    if (!token) {
      contextObj.setInput("prevPath", location.pathname);
      navigate(`${Navigation.login}`);
      return;
    }

    let errorHas = false;

    if (product.org_name === "") {
      setNameError("Organization Name is Required");
      errorHas = true;
    } else if (product.org_name.length > 60) {
      setNameError("Organization Name should not exceed 60 characters!");
      errorHas = true;
    } else {
      setNameError("");
    }

    if (product.org_phone === "") {
      setPhoneError("Organization Phone is Required");
      errorHas = true;
    } else {
      setPhoneError("");
    }

    if (product.org_email === "") {
      setEmailError("Organization Email is Required");
      errorHas = true;
    } else {
      setEmailError("");
    }

    if (product.certificate_name === "" || product.certificate_name === null) {
      setCertficateNameError("Certificate Name is Required");
      errorHas = true;
    } else {
      setCertficateNameError("");
    }

    if (product.certificate_no === "") {
      setCertificateNoError("Certificate Number is Required");
      errorHas = true;
    } else {
      setCertificateNoError("");
    }

    if (product.image === "" || product.image === null) {
      setImageError("Image is Required");
      errorHas = true;
    } else {
      setImageError("");
    }

    if (product.address1 === "") {
      setAddressError("Organization Address is Required");
      errorHas = true;
    } else setAddressError("");

    if (product.contact_person_name === "") {
      setPersonNameError("Contact Person Name is Required");
      errorHas = true;
    } else {
      setPersonNameError("");
    }

    if (product.contact_person_email === "") {
      setPersonEmailError("Contact Person Email is Required");
      errorHas = true;
    } else {
      setPersonEmailError("");
    }

    if (product.contact_person_phone === "") {
      setPersonPhoneError("Contact Person Phone is Required");
      errorHas = true;
    } else {
      setPersonPhoneError("");
    }

    //const contactPersonAddressRegex = /^[a-zA-Z0-9,.\s\/\\\-_]+$/;

    if (product.contact_person_address === "") {
      setPersonAddressError("Contact Person Address is Required");
      errorHas = true;
    } else {
      setPersonAddressError("");
    }

    if (product.about === "") {
      setAboutNgoError("Ngo Details Will Required");
      errorHas = true;
    } else if (product.about.length > 150) {
      setAboutNgoError("Ngo Details Will not be greater then 150 words.");
      errorHas = true;
    } else {
      setAboutNgoError("");
    }
    if (product.status === "" || product.status === null) {
      setBlockUnblockError("Please Select Status");
      errorHas = true;
    } else {
      setBlockUnblockError("");
    }

    // if any errors are there don't call the api
    if (errorHas === true) {
      setLoadingButton(false);
      return;
    }

    const formData = new FormData();
    // mandatory fields
    formData.append("org_name", product.org_name);
    formData.append("org_phone", product.org_phone);
    formData.append("org_email", product.org_email);
    formData.append("certificate_name", product.certificate_name);
    formData.append("certificate_no", product.certificate_no);
    formData.append("address1", product.address1);
    formData.append("contact_person_name", product.contact_person_name);
    formData.append("contact_person_email", product.contact_person_email);
    formData.append("contact_person_phone", product.contact_person_phone);
    formData.append("contact_person_address", product.contact_person_address);
    formData.append("status", product.status);
    formData.append("about", product.about);

    if (product.image instanceof File) {
      formData.append("image", product.image);
    }

    formData.append("_method", "PUT");
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response) {
          setLoadingButton(false);
        }
        if (response.errors) {
          let error = response.errors;
          if (error.org_name) {
            return setNameError(error.org_name[0]);
          }
        }
        if (response.status === 200) {
          contextObj.setInput("navigation", "true");
          contextObj.setInput("navigationFrom", "EditNgo");
          setDataToCookies("isEditedNgo", true);
          navigate("/ngos");
        } else if (response.status === 400) {
          setShowAlert(true);
          setSnackbar((prev) => ({
            ...prev,
            message: "Something went wrong!!",
            severity: "error",
          }));
        } else if (response.status === 409) {
          setShowAlert(true);
          setSnackbar((prev) => ({
            ...prev,
            message: "Unable to update block NGO",
            severity: "error",
          }));
        } else if (
          response.status === 401 &&
          response.message === "You are not authorized."
        ) {
          contextObj.setInput("prevPath", location.pathname);
          removeDataFromCookie("7b9KpQ3rW6", "/");
          removeDataFromCookie("loggedIn", "/");
          navigate(Navigation.login);
          showToast("info", "Sessions expired!");
        } else if (response.status == 401 && response.message !== "") {
          showToast("error", response.message);
        } else {
          const error = response.json();
          setShowAlert(true);
          setSnackbar((prev) => ({
            ...prev,
            message: error.message,
            severity: "error",
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInputChange = (name, value) => {
    setProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const ngoBlockButton =
    product.status === "Unblock"
      ? "ngoBlockButton green-border"
      : product.status === "Block"
      ? "ngoBlockButton red-border"
      : "ngoBlockButton";
  return (
    <div className="page-data">
      <div className="headingOfNgo">
        <div className="page-heading">
          <div className="breadcrumb">
            <li className="active"> Update NGO</li>
          </div>
        </div>
        <button className="primary-btn" onClick={() => navigate("/ngos")}>
          Back to list
        </button>
      </div>

      {/* <form onSubmit={handleSubmit}> */}
      <div className="ngoMainContainer">
        <div className="ngoparentmainContainer">
          <div>
            <div className="ngoImageContainerInnerDiv">
              <div
                onClick={thumbImgUpload}
                className="dropzone"
                onMouseEnter={handleMouseEnterThumbImg}
                onMouseLeave={handleMouseLeaveThumbImg}
              >
                {container1.image ? (
                  <div className="image-placeholder">
                    <img
                      className="image-storing-container"
                      src={container1.image}
                      loading="lazy"
                    />
                    {container1.hovering && (
                      <button
                        className="delete-button"
                        onClick={handleDeleteThumbClick}
                      >
                        Remove Image
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="click-to-upload">Click to upload</p>
                )}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, webp."
                  name="image"
                  onChange={(e) => {
                    handleInputChange("image", e.target.files[0]);
                  }}
                  id="thumb-img-input"
                  hidden
                />
              </div>
            </div>
            <label className="red">{imageError}</label>
          </div>

          <div className="blockButtonDiv">
            <select
              style={{
                color: product.status === "Block" || "" ? "red" : "green",
                border:
                  product.status === "Block"
                    ? "1px solid red"
                    : "1px solid green",
              }}
              className="ngoBlockButton"
              value={product.status}
              name="status"
              onChange={(e) => {
                handleInputChange("status", e.target.value);
                setBlockUnblockError("");
              }}
            >
              <option value="">Select</option>
              <option value="Block">Block</option>
              <option value="Unblock">Unblock</option>
            </select>
          </div>
          <label className="red">{blockUnblockError}</label>
        </div>

        <div className="ngoFormDetailContainer">
          <div className="ngoNameDiv">
            <p className="ngoParagraph">Organization Name</p>
            <input
              value={product.org_name}
              onChange={(e) => {
                handleInputChange("org_name", e.target.value);
                setNameError("");
              }}
              type="text"
              name="org_name"
              className="ngoNameInputTag"
            />
            <label className="red">{nameError}</label>
          </div>

          <div className="ngoEmail_phone_div">
            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Phone Number</p>
              <input
                className="ngoNameInputTag"
                maxLength={10}
                onChange={(e) => {
                  handleInputChange("org_phone", e.target.value);
                  setPhoneError("");
                }}
                value={product.org_phone}
                name="org_phone"
              />
              <label className="red">{phoneError}</label>
            </div>

            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Email</p>
              <input
                value={product.org_email}
                onChange={(e) => {
                  handleInputChange("org_email", e.target.value);
                  setEmailError("");
                }}
                name="org_email"
                className="ngoNameInputTag"
              />
              <label className="red">{emailError}</label>
            </div>
          </div>

          <div className="ngoEmail_phone_div">
            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Certificate Name</p>
              <input
                onChange={(e) => {
                  handleInputChange("certificate_name", e.target.value);
                  setCertficateNameError("");
                }}
                name="certificate_name"
                value={product.certificate_name}
                className="ngoNameInputTag"
              />
              <label className="red">{certficateNameError}</label>
            </div>

            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Certificate Number</p>
              <input
                value={product.certificate_no}
                onChange={(e) => {
                  handleInputChange("certificate_no", e.target.value);
                  setCertificateNoError("");
                }}
                name="certificate_no"
                className="ngoNameInputTag"
              />
              <label className="red">{certificateNoError}</label>
            </div>
          </div>

          <div className="ngoAddressDiv">
            <p className="ngoParagraph">Organization Address</p>
            <input
              value={product.address1}
              onChange={(e) => {
                handleInputChange("address1", e.target.value);
                setAddressError("");
              }}
              name="address1"
              className="ngoNameInputTag"
            />
            <label className="red">{addressError}</label>
          </div>

          <div className="ngoAddressDiv">
            <p className="ngoParagraph"> About NGO</p>
            <textarea
              value={product.about}
              onChange={(e) => {
                handleInputChange("about", e.target.value);
                setAboutNgoError("");
              }}
              name="about"
              className="aboutNgoTextFeild"
            ></textarea>
            <label className="red">{aboutNgoError}</label>
          </div>

          <div className="ngoAddressDiv">
            <p className="ngoParagraph">Contact Person Name</p>
            <input
              value={product.contact_person_name}
              onChange={(e) => {
                handleInputChange("contact_person_name", e.target.value);
                setPersonNameError("");
              }}
              name="contact_person_name"
              className="ngoNameInputTag"
            />
            <label className="red">{personNameError}</label>
          </div>

          <div className="ngoEmail_phone_div">
            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Contact Person Email</p>
              <input
                onChange={(e) => {
                  handleInputChange("contact_person_email", e.target.value);
                  setPersonEmailError("");
                }}
                name="contact_person_email"
                value={product.contact_person_email}
                className="ngoNameInputTag"
              />
              <label className="red">{personEmailError}</label>
            </div>
            <div className="ngoEmail_PhoneInnerDiv">
              <p className="ngoParagraph">Contact Person Phone</p>
              <input
                onChange={(e) => {
                  handleInputChange("contact_person_phone", e.target.value);
                  setPersonPhoneError("");
                }}
                name="contact_person_phone"
                value={product.contact_person_phone}
                className="ngoNameInputTag"
                maxLength={10}
              />
              <label className="red">{personPhoneError}</label>
            </div>
          </div>

          <div className="ngoAddressDiv">
            <p className="ngoParagraph">Contact Person Address</p>
            <input
              onChange={(e) => {
                handleInputChange("contact_person_address", e.target.value);
                setPersonAddressError("");
              }}
              name="contact_person_address"
              value={product.contact_person_address}
              className="ngoNameInputTag"
            />
            <label className="red">{personAddressError}</label>
          </div>

          <div className="modal-buttons">
            <button
              onClick={() => navigate("/ngos")}
              className="btn secondary-btn"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setLoadingButton(true);
                handleClick();
              }}
              className="btn primary-btn"
            >
              {loadingButton ? <LoaderButton /> : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNGOs;
